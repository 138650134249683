import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Button } from "reactstrap";
import { PubnubChat } from "../../components/chat/chat";
import InitializePubnub from "../../components/initializePubnub/initializePubnub";
import { OrderDetail } from "../../components/orderDetail/orderDetail";
import { ProductDetailModal } from "../../components/productDetailModal/productDetailModal";
import { DataTable } from "../../components/ui/tables/dataTable";
import { SubDataTable } from "../../components/ui/tables/subDataTable";
import * as ordersActionsCreator from "../../redux/actionsCreator/ordersActionsCreator";
import dataService from "../../services/dataService";
import storageService from "../../services/storageService";

export const SubOrdersContainer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const subOrders = useSelector((state) => state.ordersReducer.subOrders);
  const subOrdersTrasnferResponse = useSelector(
    (state) => state.ordersReducer.subOrdersTrasnferResponse
  );
  const subOrdersTrasnferError = useSelector(
    (state) => state.ordersReducer.subOrdersTrasnferError
  );
  const subOrdersTrasnferLoading = useSelector(
    (state) => state.ordersReducer.subOrdersTrasnferLoading
  );

  const [selectedProduct, setSelectedProduct] = useState(null);
  const loading = useSelector((state) => state.ordersReducer.loading);
  const [orderDetails, setOrderesDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [openChat, setOpenChat] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(``);
  const [parsedSubOrders, setParsedSubOrders] = useState([]);
  useEffect(() => {
    if (storageService.getUserRole() === "ADMIN") {
      let query = createQueryString(window.location.pathname.split("/")[2]);
      dispatch(ordersActionsCreator.getSubOrders(query));
    } else {
      dispatch(ordersActionsCreator.getSubOrders());
    }
  }, []);

  useEffect(() => {
    if (subOrdersTrasnferResponse) {
      toast.success("Transfer completed!");
    }
    if (subOrdersTrasnferError) {
      toast.error("Please check your balance and try again!");
    }
  }, [subOrdersTrasnferResponse, subOrdersTrasnferError]);

  useEffect(() => {
    return () => {
      dispatch(ordersActionsCreator.resetTransferData());
    };
  }, []);

  useEffect(() => {
    if (subOrders) {
      let result = dataService.parseSubOrdersTable(
        subOrders,
        getSubOrdersItems,
        getSubOrdersActions
      );
      setParsedSubOrders(result);
    }
  }, [subOrders]);

  const createQueryString = (orderId) => {
    var query = new URLSearchParams();
    query.append("order__id", orderId);
    return query.toString();
  };

  const getSubOrderDetail = (order) => {
    subOrders.map((subOrder) => {
      if (subOrder.id === order["#"]) {
        setSelectedOrder(subOrder);
        setOrderesDetails(true);
      }
    });
  };

  const toggle = () => setOrderesDetails(!orderDetails);

  const handleChat = (id) => {
    setCurrentChannel(`subOrder-${id}`);
    setOpenChat(true);
  };

  const getSubOrdersItems = (subOrder) => {
    return subOrder.sub_quote.quote_items.map((orderItem, index) => {
      return (
        <Badge className="bg-dark text-light mr-2" key={index}>
          {orderItem.product.data.name}
        </Badge>
      );
    });
  };

  const getSubOrdersActions = (quote) => {
    return (
      <div className="d-inline">
        <div
          className="quotes-action"
          onClick={(e) => {
            e.stopPropagation();
            handleChat(quote.id);
          }}
        >
          <button>Chat</button>
        </div>
        {quote.transfer_id === "-1" && quote.transfer_status == "FAILED" && (
          <Button
            className="d-inline mx-2 my-2"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                ordersActionsCreator.amountTransferHandler({
                  suborder_id: quote.id,
                })
              );
            }}
          >
            Retry Transfer
          </Button>
        )}
      </div>
    );
  };

  const getItemsDetail = (subOrderData) => {
    let selectedSubOrder = _.cloneDeep(
      _.find(subOrders, { id: subOrderData["#"] })
    );

    let data = dataService.parseSubOrderItems(selectedSubOrder.sub_quote);

    return (
      <SubDataTable
        isEditProduct={false}
        // isActions={true}
        data={data}
        subQuoteId={selectedSubOrder["id"]}
        isProductDetail={true}
        clicked={handleProductModalOpening}
        isEditActionHidden={true}
      />
    );
  };

  const handleProductModalOpening = (product, subQuoteId) => {
    let subQuote = _.cloneDeep(_.find(subOrders, { id: subQuoteId }));
    let previewedItem = _.find(subQuote.sub_quote?.quote_items, {
      id: product["#"],
    });
    let result = dataService.parseQuoteModalData(previewedItem);
    setSelectedProduct(result);
  };

  return (
    <div>
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1>SubOrders</h1>
        </div>
      </div>
      {Boolean(selectedProduct) && (
        <ProductDetailModal
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          // productEditHandler={productEditHandler}
          // deleteProductHandler={deleteProductHandler}
        />
      )}
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          <DataTable
            data={parsedSubOrders}
            loading={loading}
            clicked={getSubOrderDetail}
            isActions={false}
            isEditProduct={false}
            isDetailPanel={true}
            getDetailPanel={getItemsDetail}
          />
          {orderDetails && selectedOrder && (
            <OrderDetail
              quoteDetails={orderDetails}
              toggle={toggle}
              selectedOrder={selectedOrder?.sub_quote?.quote_items}
            />
          )}
          {localStorage.getItem("userEmail") && openChat && currentChannel && (
            <InitializePubnub>
              <PubnubChat
                openChat={openChat}
                setOpenChat={setOpenChat}
                currentChannel={currentChannel}
              />
            </InitializePubnub>
          )}
        </div>
      </div>
    </div>
  );
};
