import axios from "axios";

export const fileUpload = async (file, sku) => {
  let url = `https://api.cloudinary.com/v1_1/botics-ai/upload/`;

  try {
    const formData = new FormData();

    formData.append("file", file);

    const response = await axios.post(
      url,
      formData,
      {
        params: {
          folder: `files/products/${sku}`,
          upload_preset: "vfzo8nil",
          cloud_name: "Botics.ai",
        },
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
