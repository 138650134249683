import React, { useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import storageService from "../../services/storageService";

export const CheckBox = (props) => {
  const { name, label, type, required, placeHolder, min, disabled } = props;
  const {
    register,
    formState: { errors, touchedFields },
    unregister,
    setValue,
  } = useFormContext();

  const originalValue = useWatch({ name: name });

  const handleCheckBox = (e) => {
    if (e) {
      setValue(name, Boolean(storageService.getTenantId()));
    } else {
      setValue(name, e);
    }
  };
  return (
    <div className="form-group mt-3" style={{ float: "left", clear: "both" }}>
      <div className="form-check form-switch d-inline ps-2">
        <input
          type="checkbox"
          className="form-check-input d-inline mx-0 px-0"
          role="switch"
          id={name}
          name={name}
          value={originalValue}
          {...register(name)}
          onChange={(event) => handleCheckBox(event.target.checked)}
          disabled={disabled}
        />
      </div>
      <label className=" d-inline mx-0 px-0  " htmlFor={name}>
        {label}
      </label>
    </div>
  );
};
