import React, { useEffect, useState } from "react";
import { ResetEmailPage } from "../../components/resetEmailPage/resetEmailPage";
import { ResetPasswordPage } from "../../components/resetPasswordPage/resetPasswordPage";
import * as actionsCreator from "../../redux/actionsCreator/authActionsCreator";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";

export const ResetPasswordContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const resetEmailResponse = useSelector(
    (state) => state.authReducer.resetEmailResponse
  );
  const resetEmailError = useSelector(
    (state) => state.authReducer.resetEmailError
  );

  const resetPasswordResponse = useSelector(
    (state) => state.authReducer.resetPasswordResponse
  );

  const resetPasswordError = useSelector(
    (state) => state.authReducer.resetPasswordError
  );

  const loading = useSelector((state) => state.authReducer.loading);

  const [incomingToken, setIncomingToken] = useState("");

  useEffect(() => {
    setIncomingToken(new URLSearchParams(window.location.search).get("token"));
  }, []);

  useEffect(() => {
    if (resetEmailError) {
      toast.error("Something went wrong!");
    } else if (resetEmailResponse) {
      toast.success("Check email for password reset link!");
    }
  }, [resetEmailResponse, resetEmailError]);

  const submitForgotEmail = (email) => {
    dispatch(actionsCreator.resetEmail({ email: email }));
  };

  const submitForgotPassword = (password) => {
    dispatch(
      actionsCreator.resetPassword({
        password: password,
        token: incomingToken,
      })
    );
  };

  useEffect(() => {
    if (resetPasswordResponse) {
      toast.success("Password Updated Successfully!");
      history.push(routesPath.login);
    }
    if (resetPasswordError) {
      if (resetPasswordError?.response?.data?.password[0]) {
        toast.error(resetPasswordError?.response?.data?.password[0]);
      } else {
        toast.error("Something Went Wrong!");
      }
    }
  }, [resetPasswordResponse, resetPasswordError]);

  return (
    <>
      <main>
        <section className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {!incomingToken ? (
                  <ResetEmailPage
                    submitForgotEmail={submitForgotEmail}
                    loading={loading}
                  />
                ) : (
                  <ResetPasswordPage
                    submitForgotPassword={submitForgotPassword}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
