export default {
  NEW: {
    name: "NEW",
    VENOR_canEdit: false,
    ADMIN_canEdit: true,
    VENDOR_hide: false,
    ADMIN_hide: false,
    label: "PENDING",
  },
  ADMIN_APPROVED: {
    name: "ADMIN APPROVED",
    VENOR_canEdit: false,
    ADMIN_canEdit: false,
    VENDOR_hide: true,
    ADMIN_hide: false,
    label: "ADMIN APPROVED",
  },
  SENT_TO_CUSTOMER: {
    name: "SENT TO CUSTOMER",
    VENOR_canEdit: true,
    ADMIN_canEdit: true,
    VENDOR_hide: false,
    ADMIN_hide: false,
    label: "SENT TO CUSTOMER",
  },
  SENT_TO_VENDOR: {
    name: "SENT TO VENDOR",
    VENDOR_canEdit: true,
    ADMIN_canEdit: false,
    VENDOR_hide: false,
    ADMIN_hide: false,
    label: "SENT TO VENDOR",
  },
  VENDOR_TO_CUSTOMER: {
    name: "VENDOR_TO_CUSTOMER",
    VENDOR_canEdit: false,
    ADMIN_canEdit: false,
    VENDOR_hide: false,
    ADMIN_hide: false,
    label: "VENDOR TO CUSTOMER",
  },
  VENDOR_APPROVED: {
    name: "VENDOR_APPROVED",
    VENDOR_canEdit: true,
    ADMIN_canEdit: true,
    VENDOR_hide: false,
    ADMIN_hide: false,
    label: "VENDOR APPROVED",
  },
  SENT_TO_ADMIN: {
    name: "SENT TO ADMIN",
    VENDOR_canEdit: false,
    ADMIN_canEdit: true,
    VENDOR_hide: false,
    ADMIN_hide: false,
    label: "SENT TO ADMIN",
  },
  PURCHASED: {
    name: "PURCHASED",
    VENDOR_hide: true,
    ADMIN_hide: true,
    label: "PURCHASED",
  },
  CLOSED: { name: "CLOSED", VENDOR_hide: true, ADMIN_hide: false },
};
