import React, { useState, useRef, useEffect, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { imageUpload } from "../../utils/imageUpload";
import { ImageModal } from "../imageModal/imageModal";
import { Spinner } from "../ui/loader";

const ImageUploader = ({ name, required }) => {
  const {
    register,
    formState: { errors },
    unregister,
    setValue,
    trigger,
  } = useFormContext();
  const originalValue = useWatch({ name: name }) || [];
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const input = useRef();

  const sku = useWatch({ name: "sku" });

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, [unregister, name]);

  const handleChange = async (event) => {
    const result = await trigger();
    if (!result) {
      return;
    }
    let files = event.target.files;

    if (!files || !files.length) {
      return;
    }

    try {
      setLoading(true);
      const promises = [...files].map((file) => imageUpload(file, sku));
      const response = await Promise.all(promises);
      const images = [...originalValue];
      const newImages = images.concat(
        response.map((image) => ({
          is_primary: false,
          image: image?.secure_url,
        }))
      );

      setValue(name, newImages, { shouldValidate: true });
      input.current.value = "";
      setLoading(false);
    } catch (error) {
      input.current.value = "";
      setLoading(false);
      alert(error);
    }
  };

  const doImagePreview = useCallback(
    (image) =>
      setImage({
        src: image.seucre_url || image.image,
        alt: image.name,
      }),
    []
  );

  const doCloseImageModal = useCallback(() => setImage(null), []);

  const renderedImageModal = image && (
    <ImageModal
      src={image.src}
      alt={image.alt}
      onClose={() => doCloseImageModal()}
    />
  );

  const handleRemove = (index) => {
    let images = [...originalValue];
    images.splice(index, 1);

    setValue(name, images, { shouldValidate: true });
  };

  return (
    <>
      <div className="my-3" style={{ clear: "both", textAlign: "left" }}>
        <label className="col-form-label  is-invalid d-block" htmlFor={name}>
          {required && <small className="text-danger">* </small>}
          Images
        </label>
        <label
          className={
            loading
              ? "btn btn-outline-secondary px-4 disable-uploader"
              : "btn btn-outline-secondary px-4 "
          }
        >
          {loading ? (
            <Spinner height={20} width={20} />
          ) : (
            <i className="fas fa-plus" />
          )}
          &nbsp;Upload
          <input
            className="d-none"
            disabled={loading}
            accept="image/*"
            type="file"
            onChange={handleChange}
            ref={input}
            multiple
          />
        </label>
        {originalValue && originalValue.length ? (
          <div className="d-flex flex-row flex-wrap">
            {originalValue.map((item, key) => {
              return (
                <div
                  className="me-2 mb-2 mt-3 img-card"
                  style={{ height: "100px", position: "relative" }}
                  key={item.image}
                >
                  <img
                    src={item.image}
                    alt={item.image}
                    className="img-thumbnail"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />

                  <div className="img-buttons rounded-bottom">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => doImagePreview(item)}
                    >
                      <i className="fas fa-search me-3 text-light" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-link ml-2"
                      onClick={() => handleRemove(key)}
                    >
                      <i className="fas fa-times text-light" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {renderedImageModal}
      </div>
    </>
  );
};

export default ImageUploader;
