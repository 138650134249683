import { AddProductIcon } from "../components/ui/addProductIcon";
import { InventoryIcon } from "../components/ui/inventoryIcon";
import { OrdersIcon } from "../components/ui/OrdersIcon";
import { QuotesIcon } from "../components/ui/quotesIcon";
import { SubscriptionIcon } from "../components/ui/subscriptionIcon";
import { PaymentIcon } from "../components/ui/paymentIcon";
import PersonIcon from "@mui/icons-material/Person";
import { routesPath } from "./routesPath";
import storageService from "../services/storageService";

const getDrawerRoutes = () => {
  let drawerRouter = [];

  let routes = [];

  let adminRoutes = [
    { label: "Quotes", link: routesPath.quotes, icon: <QuotesIcon /> },
    { label: "Orders", link: routesPath.orders, icon: <OrdersIcon /> },
  ];
  let vendorRoutes = [
    { label: "Sub Quotes", link: routesPath.subQuotes, icon: <QuotesIcon /> },
    { label: "Sub Orders", link: routesPath.subOrders, icon: <OrdersIcon /> },

    {
      label: "Subscription",
      link: routesPath.subscription,
      icon: <SubscriptionIcon />,
    },
    {
      label: "Payment Methods",
      link: routesPath.payments,
      icon: <PaymentIcon />,
    },
  ];

  let tenantRoutes = [
    {
      label: "Tenant Products",
      link: routesPath.tenantProduct,
      icon: <AddProductIcon />,
    },
    {
      label: "Tenant Quotes",
      link: routesPath.tenantQuotes,
      icon: <QuotesIcon />,
    },
    {
      label: "Tenant Orders",
      link: routesPath.tenantOrders,
      icon: <OrdersIcon />,
    },
  ];

  drawerRouter = [
    { label: "Profile", link: routesPath.profile, icon: <PersonIcon /> },
    { label: "Inventory", link: routesPath.inventory, icon: <InventoryIcon /> },
    { label: "Products", link: routesPath.products, icon: <AddProductIcon /> },
  ];

  if (storageService.getUserRole() === "ADMIN") {
    adminRoutes.map((route) => drawerRouter.push(route));
  }
  if (storageService.getUserRole() === "VENDOR") {
    vendorRoutes.map((route) => drawerRouter.push(route));
  }
  if (storageService.getTenantId()) {
    tenantRoutes.map((route) => drawerRouter.push(route));
  }

  return drawerRouter;
};

export default {
  getDrawerRoutes,
};
