import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import * as productActionsCreator from "../actionsCreator/productActionsCreator";

const productSlice = createSlice({
  name: "products",
  initialState: {
    application: null,
    subCategories: null,
    equipments: null,
    models: null,
    loading: false,
    addedImages: null,
    url: null,
    addProductResponse: null,
    searchProduct: null,
    addProductSuccess: false,
    addProductError: null,
    drawerOpening: window.innerWidth >= 768 ? true : false,
    toEditProduct: null,
    toEditProductLoading: false,
    toEditProductError: false,
    deleteProductError: null,
  },
  reducers: {},
  extraReducers: {
    //get Applications

    [productActionsCreator.getApplication.pending]: (state) => {
      state.loading = true;
      state.appilcation = null;
    },
    [productActionsCreator.getApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.application = action.payload;
    },
    [productActionsCreator.getApplication.rejected]: (state) => {
      state.loading = false;
      state.application = null;
    },

    //get Models

    [productActionsCreator.getModels.pending]: (state) => {
      state.loading = true;
      state.models = null;
    },
    [productActionsCreator.getModels.fulfilled]: (state, action) => {
      state.loading = false;
      state.models = action.payload;
    },
    [productActionsCreator.getModels.rejected]: (state) => {
      state.loading = false;
      state.models = null;
    },

    // get subcategories

    [productActionsCreator.getSubCategories.pending]: (state) => {
      state.loading = true;
      state.subCategories = null;
    },
    [productActionsCreator.getSubCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.subCategories = action.payload;
    },
    [productActionsCreator.getSubCategories.rejected]: (state) => {
      state.loading = false;
      state.subCategories = null;
    },

    //add components

    [productActionsCreator.addComponents.pending]: (state) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
    },
    [productActionsCreator.addComponents.fulfilled]: (state, action) => {
      state.loading = false;
      state.addProductResponse = action.payload;
      state.addProductSuccess = true;
      state.addProductError = null;
    },
    [productActionsCreator.addComponents.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = action.payload;
    },

    //add Model

    [productActionsCreator.addModels.pending]: (state, action) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
    },
    [productActionsCreator.addModels.fulfilled]: (state, action) => {
      state.loading = false;
      state.addProductResponse = action.payload;
      state.addProductSuccess = true;
      state.addProductError = null;
    },
    [productActionsCreator.addModels.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = action.payload;
    },

    //get Equipments

    [productActionsCreator.getEquipment.pending]: (state) => {
      state.loading = true;
      state.equipments = null;
    },
    [productActionsCreator.getEquipment.fulfilled]: (state, action) => {
      state.loading = false;
      state.equipments = action.payload;
    },
    [productActionsCreator.getEquipment.rejected]: (state) => {
      state.loading = false;
      state.equipments = null;
    },

    //get Models for vendors

    [productActionsCreator.getProducts.pending]: (state) => {
      state.loading = true;
      state.searchProduct = null;
    },
    [productActionsCreator.getProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchProduct = action.payload;
    },
    [productActionsCreator.getProducts.rejected]: (state) => {
      state.loading = false;
      state.searchProduct = null;
    },

    //delete components

    [productActionsCreator.deleteComponent.pending]: (state) => {
      state.loading = true;
      state.searchProduct = null;
      state.deleteProductError = null;
    },
    [productActionsCreator.deleteComponent.fulfilled]: (state, action) => {
      state.loading = false;
      action.payload.products.splice(action.payload.productIndex, 1);
      state.searchProduct = action.payload.products;
      state.deleteProductError = null;
    },
    [productActionsCreator.deleteComponent.rejected]: (state, action) => {
      state.loading = false;
      state.searchProduct = null;
      state.deleteProductError = action.payload;
    },

    //delete models

    [productActionsCreator.deleteModel.pending]: (state) => {
      state.loading = true;
      state.searchProduct = null;
      state.deleteProductError = null;
    },
    [productActionsCreator.deleteModel.fulfilled]: (state, action) => {
      state.loading = false;
      action.payload.products.splice(action.payload.productIndex, 1);
      state.searchProduct = action.payload.products;
      state.deleteProductError = null;
    },
    [productActionsCreator.deleteModel.rejected]: (state, action) => {
      state.loading = false;
      state.searchProduct = null;
      state.deleteProductError = action.payload;
    },

    //patch Component

    [productActionsCreator.patchComponent.pending]: (state, action) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductError = null;
    },
    [productActionsCreator.patchComponent.fulfilled]: (state, action) => {
      let tempProducts = [];
      if (action.payload.productList) {
        tempProducts = _.cloneDeep(action.payload.productList);
        let index = _.findIndex(tempProducts, {
          id: action.payload.component.id,
        });
        tempProducts[index].data = action.payload.response.data;
        tempProducts[index].quantity = action.payload.component.quantity;
      }

      state.loading = false;
      state.addProductResponse = action.payload.response;
      state.addProductError = null;
      state.searchProduct = tempProducts;
    },
    [productActionsCreator.patchComponent.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductError = action.payload;
    },

    //patch models

    [productActionsCreator.patchModel.pending]: (state, action) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductError = null;
    },
    [productActionsCreator.patchModel.fulfilled]: (state, action) => {
      let tempProducts = [];
      if (action.payload.productList) {
        tempProducts = _.cloneDeep(action.payload.productList);
        let index = _.findIndex(tempProducts, { id: action.payload.model.id });
        tempProducts[index].data = action.payload.response.data;
        tempProducts[index].quantity = action.payload.model.quantity;
      }

      state.loading = false;
      state.addProductResponse = action.payload.response;
      state.addProductError = null;
      state.searchProduct = tempProducts;
    },
    [productActionsCreator.patchModel.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductError = action.payload;
    },

    //get a single product

    [productActionsCreator.getProduct.pending]: (state) => {
      state.toEditProduct = null;
      state.toEditProductError = null;
      state.toEditProductLoading = true;
    },
    [productActionsCreator.getProduct.fulfilled]: (state, action) => {
      state.toEditProduct = action.payload.data;
      state.toEditProductError = null;
      state.toEditProductLoading = false;
    },
    [productActionsCreator.getProduct.rejected]: (state, action) => {
      state.toEditProduct = null;
      state.toEditProductError = action.error;
      state.toEditProductLoading = false;
    },
    [productActionsCreator.removeProduct]: (state, action) => {
      state.toEditProduct = null;
      state.toEditProductError = null;
      state.toEditProductLoading = false;
    },

    // drawer handler

    [productActionsCreator.drawerHandler]: (state, action) => {
      state.drawerOpening = !state.drawerOpening;
    },
    [productActionsCreator.resetInventoryProductPatch]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
      state.searchProduct = null;
      state.toEditProductError = null;
      state.toEditProduct = null;
    },
    [productActionsCreator.resetProductData]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
      state.searchProduct = null;
      state.toEditProductError = null;
      state.toEditProduct = null;
      state.deleteProductError = null;
    },
  },
});

export default productSlice.reducer;
