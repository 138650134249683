import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "./orderDetail.css";
import { asset } from "../../constants/baseUrl";
import noImg from "../../assets/img/noImg.jpg";
import { decryptedPrice } from "../../utils/decode";

export const OrderDetail = (props) => {
  return (
    <div>
      <Modal
        isOpen={props.quoteDetails}
        toggle={props.toggle}
        centered
        scrollable
        className="cart-modal"
      >
        <div>
          <div>
            <div>
              <ModalHeader>
                <div className="border-bottom-0">
                  <h5>Order details</h5>
                </div>
              </ModalHeader>

              <div>
                <ModalBody>
                  <div style={{ height: "350px", overflowY: "scroll" }}>
                    <table className="table table-image">
                      <thead>
                        <tr>
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Qty</th>
                          <th scope="col">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.selectedOrder?.length &&
                          props.selectedOrder.map((item, key) => (
                            // <div>
                            <tr key={key}>
                              <td style={{ width: "25%" }}>
                                <img
                                  src={
                                    (item.product.data?.images.length &&
                                      asset(
                                        item.product.data.images[0].image
                                      )) ||
                                    noImg
                                  }
                                  className="img-fluid img-thumbnail"
                                  alt="Product"
                                />
                              </td>
                              <td>{item.product.data.name}</td>
                              <td>{item.product.data.type}</td>
                              <td>{item.quantity}</td>
                              <td>{decryptedPrice(item.price)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </ModalBody>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
