import React, { useEffect } from "react";

export const ProductCanvas = (props) => {
  useEffect(() => {
    const message = {
      message_type: "Robot_Data",
      model_id: "52",
      component_ids: props.components,
      addedd_component_ids: props.components,
      removed_component_ids: [props.removedComponent],
    };
    iFrame.current.contentWindow.postMessage(message, "*");
  });
  const iFrame = React.useRef();

  const iframeLoaded = () => {
    window.addEventListener("message", eventListner);
  };

  const eventListner = (event) => {
    if (
      event.data.message_type &&
      event.data.message_type === "Playcanvas_Ready"
    ) {
      const message = {
        message_type: "Robot_Data",
        model_id: "52",
        component_ids: props.components,
        addedd_component_ids: props.components,
        removed_component_ids: [props.removedComponent],
      };
      if (iFrame.current)
        iFrame.current.contentWindow.postMessage(message, "*");
    }
  };

  return (
    <iframe
      src="/configurator/index.html"
      style={{ width: "100%", height: "600px", borderRadius: "10px" }}
      frameBorder="0"
      id="playcanvasIframe"
      onLoad={iframeLoaded}
      ref={iFrame}
      scrolling="no"
      allow="camera;microphone;gyroscope;accelerometer;"
      title="Botics"
    ></iframe>
  );
};
