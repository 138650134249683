import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrls } from "../../constants/apiUrls";
import APIs from "../../services/httpService";
import { authHeader } from "../../constants/authenticationHeader";

//Read

export const read = createAsyncThunk("account/read", async (data = {}) => {
  let userData = { ...data };
  userData.csrf = authHeader();
  userData.path = apiUrls.checkLogin();
  const response = await APIs.fetch(userData);
  return response.data;
});

export const stripeLink = createAsyncThunk(
  "account/stripeLink",
  async (data = {}) => {
    let stripeData = { ...data };
    stripeData.csrf = authHeader();
    stripeData.path = apiUrls.stripeAccountLink();
    const response = await APIs.post(stripeData);
    return response.data;
  }
);

export const clearProfile = createAction("quotes/clearProfile");
