import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import _ from "lodash";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    textAlign: "left",
    marginLeft: 0,
    padding: 0,
  }),
};

const SelectInput = (props) => {
  const { name, label, options, mode, required } = props;

  const {
    register,
    formState: { errors, touchedFields },
    unregister,
    setValue,
  } = useFormContext();
  const originalValue = useWatch({ name: name });

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  const value = () => {
    if (mode === "multiple") {
      return valueMultiple();
    } else {
      return valueOne();
    }
  };

  const valueMultiple = () => {
    if (originalValue) {
      return originalValue.map((value) =>
        props.options.find((option) => option.value === value)
      );
    }

    return [];
  };

  const valueOne = () => {
    if (originalValue != null) {
      return options.find((option) => option.value === originalValue);
    }

    return null;
  };

  const handleSelect = (data) => {
    const { mode } = props;
    if (mode === "multiple") {
      return handleSelectMultiple(data);
    } else {
      return handleSelectOne(data);
    }
  };

  const handleSelectMultiple = (values) => {
    if (!values) {
      setValue(name, [], { shouldValidate: true });

      return;
    }

    const newValue = values
      .map((data) => (data ? data.value : data))
      .filter((value) => value != null);

    setValue(name, newValue, { shouldValidate: true });
  };

  const handleSelectOne = (data) => {
    if (!data) {
      setValue(name, null, { shouldValidate: true });
      return;
    }

    setValue(name, data.value, { shouldValidate: true });
  };

  return (
    <div className="form-group d-block" style={{ clear: "both" }}>
      <label className="col-form-label float-start d-block" htmlFor={name}>
        {required ? (
          <>
            <small className="text-danger">* </small>
            {label}
          </>
        ) : (
          label
        )}
      </label>

      <div style={{ clear: "both" }}>
        <Select
          onChange={handleSelect}
          options={options}
          value={value()}
          isMulti={mode === "multiple"}
          styles={customStyles}
        />
        <i
          className={
            _.get(errors, name)?.message && _.get(touchedFields, name)
              ? "fa fa-times invalid-add-product-check"
              : _.get(touchedFields, name) &&
                "fa fa-check valid-add-product-check"
          }
        ></i>
      </div>
      <small className="text-danger float-start">
        {_.get(errors, name)?.message}
      </small>
    </div>
  );
};

export default SelectInput;
