import React from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { SignupContainer } from "./containers/signupContainer/SignUpContainer";
import { SignInContainer } from "./containers/signInContainer/SignInContainer";
import { EmailVerificationFormContainer } from "./containers/emailVerificationFormContainer/EmailVerificationFormContainer";
import { VerifyEmailContainer } from "./containers/verifyEmailContainer/VerifyEmailContainer";
import { ResetPasswordContainer } from "./containers/resetPasswordContainer/ResetPasswordContainer";
import { PrivateRoute } from "./routes/privateRoutes";
import { PublicLayout } from "./layouts/publicLayout";
import { routesPath } from "./constants/routesPath";
import { CoreLayout } from "./layouts/coreLayout";
import { AdminNavbar } from "./components/header/adminNavbar";
import allowedRoutes from "./utils/allowedRoutes";
import { useSelector } from "react-redux";

export const Router = () => {
  const user = useSelector((state) => state.accountReducer.userResponse);
  return (
    <>
      <ToastContainer
        position="bottom-left"
        hideProgressBar={true}
        style={{ opacity: "0.8" }}
      ></ToastContainer>
      <AdminNavbar />

      <PublicLayout>
        <Switch>
          <Route
            exact
            path={routesPath.login}
            render={(props) => <SignInContainer {...props} />}
          />
          <Route exact path={routesPath.register} component={SignupContainer} />
          <Route
            exact
            path={routesPath.emailVerification}
            component={EmailVerificationFormContainer}
          />
          <Route
            exact
            path={routesPath.verifyEmail}
            component={VerifyEmailContainer}
          />
          <Route
            exact
            path={routesPath.reset}
            component={ResetPasswordContainer}
          />
        </Switch>
      </PublicLayout>
      <CoreLayout>
        <Switch>
          {allowedRoutes.getAllowedRoutes().map((route) => (
            <PrivateRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </CoreLayout>
    </>
  );
};
