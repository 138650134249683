import React from "react";
import { ImageModalWrapper } from "../ui/imageModalWrapper/imageModalWrapper";

export const ImageModal = ({ src, alt, onClose, isNext, isPrev }) => {
  return (
    <ImageModalWrapper>
      <div className="modal">
        <span className="close" onClick={onClose}>
          &times;
        </span>

        <img className="modal-content" src={src} alt={alt} />
        <span className={isPrev ? " prev" : "d-none"}>&#8249;</span>
        <span className={isNext ? "next" : "d-none"}>&#8250;</span>
      </div>
    </ImageModalWrapper>
  );
};
