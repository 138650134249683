import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
// import { QuotationDetails } from "../../components/quoteDetail/quoteDetail";
import { DataTable } from "../../components/ui/tables/dataTable";
import _ from "lodash";
import { PubnubChat } from "../../components/chat/chat";
import * as quoteAcionsCreator from "../../redux/actionsCreator/quotesActionsCreator";
import InitializePubnub from "../../components/initializePubnub/initializePubnub";
import storage from "../../services/storageService";
import { toast } from "react-toastify";
import dataService from "../../services/dataService";
import { Spinner } from "../../components/ui/loader";
import { useRouteMatch } from "react-router";
import quoteStatus from "../../constants/quoteStatus";
import { SubDataTable } from "../../components/ui/tables/subDataTable";
import { ProductDetailModal } from "../../components/productDetailModal/productDetailModal";
import storageService from "../../services/storageService";
import { decryptedPrice } from "../../utils/decode";
import { clearTransitions } from "../../redux/reducers/quotesReducer";

export const SubQuotesContainer = (props) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const subQuotes = useSelector((state) => state.quotesReducer.subQuotes);
  const loading = useSelector((state) => state.quotesReducer.loading);
  const patchQuoteItemsSuccess = useSelector(
    (state) => state.quotesReducer.patchQuoteItemsSuccess
  );
  const patchQuoteItemsError = useSelector(
    (state) => state.quotesReducer.patchQuoteItemsError
  );

  const [selectedProduct, setSelectedProduct] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(``);

  const [subQuoteDetails, setSubQuoteDetails] = useState(false);
  const [selectedSubQuote, setSelectedSubQuote] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [parsedSubQuotes, setParsedSubQuotes] = useState([]);
  const [image, setImage] = useState(null);

  const possibleTransitionsLoader = useSelector(
    (state) => state.quotesReducer.possibleTransitionsLoader
  );
  const possibleTransitions = useSelector(
    (state) => state.quotesReducer.possibleTransitions
  );
  const possibleTransitionsError = useSelector(
    (state) => state.quotesReducer.possibleTransitionsError
  );

  useEffect(() => {
    if (storage.getUserRole() === "ADMIN") {
      let queryString = createQueryString(match.params.id);
      dispatch(quoteAcionsCreator.getQuotes(queryString));
    } else {
      dispatch(quoteAcionsCreator.getQuotes());
    }
  }, [patchQuoteItemsSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearTransitions());
    };
  }, [dispatch]);

  useEffect(() => {
    if (subQuotes) {
      let result = dataService.parseSubQuoteTable(
        subQuotes,
        getSubQuoteItems,
        getChat,
        getStatus,
        getTransitionsDropdown
      );
      setParsedSubQuotes(result);
    }
  }, [subQuotes, possibleTransitions]);

  useEffect(() => {
    if (possibleTransitions) {
      if (possibleTransitions?.status) {
        toast.success(possibleTransitions.status.replaceAll("_", " "));
      } else if (
        !possibleTransitions?.transitions?.length &&
        !possibleTransitions?.status
      ) {
        toast.error("No Available Transitions!");
      }
    }
  }, [possibleTransitions]);

  useEffect(() => {
    if (possibleTransitionsError) {
      toast.error(possibleTransitionsError);
    }
  }, [possibleTransitionsError]);

  useEffect(() => {
    if (patchQuoteItemsError) {
      toast.error(patchQuoteItemsError);
    }
  }, [patchQuoteItemsError]);

  const quoteStatusHandler = (transition, quote, subQuoteIndex) => {
    let errorQuotes = [];
    subQuotes.map((quote, index) => {
      quote.quote_items.map((item) => {
        if (decryptedPrice(item.price) <= 0 && index === subQuoteIndex) {
          errorQuotes.push(index);
        }
      });
    });

    if (errorQuotes.includes(subQuoteIndex)) {
      toast.error("Please enter price for quote items!");
      return;
    }

    if (!errorQuotes.includes(subQuoteIndex)) {
      const subQuote = {
        quote: quote.quote,
        vendor: quote.vendor,
        subQuoteId: quote.id,
        transition: transition,
        type: "USER",
        productType: "subQuotes",
      };

      dispatch(quoteAcionsCreator.postpossibleTransitions(subQuote));
    }
  };

  const getQuoteDetail = (quote) => {
    subQuotes.map((subQuote) => {
      if (subQuote.id === quote["#"]) {
        setSelectedSubQuote({ index: quote.tableData.id, quote: subQuote });
        setSubQuoteDetails(true);
      }
    });
  };

  const toggle = () => setSubQuoteDetails(!subQuoteDetails);

  const handleChat = (id) => {
    setCurrentChannel(`subQuote-${id}`);
    setOpenChat(true);
  };

  const createQueryString = (vendorId) => {
    var query = new URLSearchParams();
    query.append("quote__id", vendorId);
    return query.toString();
  };

  const quotePriceHandler = (product, subQuoteId) => {
    let tempSubQuote = _.find(subQuotes, { id: subQuoteId });

    if (
      tempSubQuote.status === "SENT_TO_VENDOR" &&
      storageService.getUserRole() === "ADMIN"
    ) {
      toast.error("You are not allowed for this action!");
      return;
    }
    const patchQuoteItemsBody = {
      price: product["UNIT_PRICE"],
      itemId: product["#"],
    };

    dispatch(quoteAcionsCreator.patchQuoteItems(patchQuoteItemsBody));
  };

  const getSubQuoteItems = (subQuote) => {
    return subQuote.quote_items.map((subQuoteItem, index) => {
      return (
        <Badge color="badge-dot bg-dark m-1" key={index}>
          {subQuoteItem.product.data.name}
        </Badge>
      );
    });
  };

  const getStatus = (quote) => {
    return (
      <Badge className="badge-dot mr-4 bg-dark">
        <span className="status">{quoteStatus[quote.status].label}</span>
      </Badge>
    );
  };

  const getChat = (subQuote) => {
    return (
      <div
        className="quotes-action"
        onClick={(e) => {
          e.stopPropagation();
          handleChat(subQuote.id);
        }}
      >
        <button>Chat</button>
      </div>
    );
  };

  const getTransitionsDropdown = (subQuote, index) => {
    return (
      <UncontrolledDropdown
        onClick={(e) => {
          e.stopPropagation();
          getpossibleTransitions(subQuote.id);
        }}
      >
        <DropdownToggle
          caret
          style={{
            background: "transparent",
            border: "none",
            boxShadow: "none",
            fontSize: "10px",
          }}
          className="text-dark"
        >
          Show
        </DropdownToggle>
        <DropdownMenu
          onClick={(e) => {
            e.stopPropagation();
            quoteStatusHandler(e.target.value, subQuote, index);
          }}
        >
          {possibleTransitionsLoader && (
            <DropdownItem value="Loading" className="text-dark">
              <Spinner height={40} width={40} />
            </DropdownItem>
          )}
          {possibleTransitions?.transitions?.length > 0 &&
            possibleTransitions.transitions.map((transition, index) => (
              <DropdownItem
                value={transition}
                key={index}
                className="text-dark"
              >
                {transition.replaceAll("-", " ")}
              </DropdownItem>
            ))}

          {possibleTransitionsError && (
            <DropdownItem disabled value="error" className="text-dark">
              No Transition Available
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const getpossibleTransitions = (id) => {
    dispatch(
      quoteAcionsCreator.getpossibleTransitions({ type: "VENDOR", id: id })
    );
  };

  const getItemsDetail = (subQuoteData) => {
    let selectedSubQuote = _.cloneDeep(
      _.find(subQuotes, { id: subQuoteData["#"] })
    );

    let data = dataService.parseSubQuoteItems(selectedSubQuote);

    return (
      <SubDataTable
        isEditProduct={true}
        setFields={quotePriceHandler}
        // isActions={true}
        data={data}
        subQuoteId={selectedSubQuote["id"]}
        isProductDetail={true}
        clicked={handleProductModalOpening}
        isEditActionHidden={false}
      />
    );
  };

  const handleProductModalOpening = (product, subQuoteId) => {
    let subQuote = _.cloneDeep(_.find(subQuotes, { id: subQuoteId }));
    let previewedItem = _.find(subQuote.quote_items, { id: product["#"] });
    let result = dataService.parseQuoteModalData(previewedItem);

    setSelectedProduct(result);
  };

  return (
    <>
      <div>
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>SubQuotes</h1>
          </div>
        </div>
        {Boolean(selectedProduct) && (
          <ProductDetailModal
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            // productEditHandler={productEditHandler}
            // deleteProductHandler={deleteProductHandler}
          />
        )}
        <div className="dashboard-content-wrap">
          <div className="main-content-inner">
            <Row>
              <Col className="ml-auto" md="12">
                <DataTable
                  data={parsedSubQuotes}
                  loading={loading}
                  clicked={getQuoteDetail}
                  isActions={false}
                  isEditProduct={false}
                  isProductDetail={false}
                  getDetailPanel={getItemsDetail}
                  isDetailPanel={true}
                  tableType={"subQuotes"}

                  // quoteStatusHandler={quoteStatusHandler}
                  // statusError={statusError}
                />
              </Col>
            </Row>
            {localStorage.getItem("userEmail") && openChat && currentChannel && (
              <InitializePubnub>
                <PubnubChat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  currentChannel={currentChannel}
                />
              </InitializePubnub>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
