import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import _ from "lodash";

const TextInput = (props) => {
  const { name, label, type, required, placeHolder, min } = props;
  const {
    register,
    formState: { errors, touchedFields },
    unregister,
  } = useFormContext();

  const originalValue = useWatch({ name: name });

  return (
    <div className="form-group" style={{ clear: "both" }}>
      <label className="col-form-label float-start is-invalid" htmlFor={name}>
        {required && <small className="text-danger">* </small>}
        {label}
      </label>
      <div className="input-group">
        <input
          type={type}
          value={originalValue}
          {...register(name)}
          className={
            _.get(errors, name)?.message
              ? " form-control border-danger"
              : "form-control"
          }
          placeholder={placeHolder}
          min={min}
          style={{ borderRadius: 4 }}
        />
        <i
          className={
            _.get(errors, name)?.message
              ? "fa fa-times invalid-add-product-check"
              : originalValue && "fa fa-check valid-add-product-check"
          }
        ></i>
      </div>
      <small className="text-danger float-start" style={{ clear: "both" }}>
        {_.get(errors, name)?.message}
      </small>
    </div>
  );
};

export default TextInput;
