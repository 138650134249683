import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as productsActionsCreator from "../../redux/actionsCreator/productActionsCreator";
import dataService from "../../services/dataService";
import { DataTable } from "../../components/ui/tables/dataTable";
import { toast } from "react-toastify";
import storageService from "../../services/storageService";

export const InventoryContainer = () => {
  const dispatch = useDispatch();
  const inventoryProducts = useSelector(
    (state) => state.productsReducer.searchProduct
  );
  const loading = useSelector((state) => state.productsReducer.loading);
  const editProductResponse = useSelector(
    (state) => state.productsReducer.editProductResponse
  );
  const editProductError = useSelector(
    (state) => state.productsReducer.editProductError
  );
  const [parsedInventoryProducts, setParsedInventoryProducts] = useState([]);

  useEffect(() => {
    const queryString = createQueryString();
    dispatch(productsActionsCreator.getProducts(queryString));
  }, [dispatch]);

  const createQueryString = () => {
    var query = new URLSearchParams();
    query.append("vendor__user_ptr__in", storageService.getUserId());
    return query.toString();
  };

  useEffect(() => {
    return () => {
      dispatch(productsActionsCreator.resetInventoryProductPatch());
    };
  }, []);

  useEffect(() => {
    if (editProductResponse) {
      toast.success("Product Quantity Changed");
    }
    if (editProductError) {
      toast.error("Failed To Edit Quantity!");
    }
  }, [editProductResponse, editProductError]);

  useEffect(() => {
    if (inventoryProducts) {
      let result = dataService.parseInventoryTable(inventoryProducts);
      setParsedInventoryProducts(result);
    }
  }, [inventoryProducts]);

  const editProduct = (product) => {
    let changedProduct = null;
    changedProduct = _.cloneDeep(
      _.find(inventoryProducts, { id: product["#"] })
    );
    changedProduct.quantity = product.QUANTITY;

    product.TYPE === "MODEL"
      ? dispatch(
          productsActionsCreator.patchModel({
            model: changedProduct,
            id: product["#"],
            productList: inventoryProducts,
          })
        )
      : dispatch(
          productsActionsCreator.patchComponent({
            component: changedProduct,
            id: product["#"],
            productList: inventoryProducts,
          })
        );
  };

  return (
    <>
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1>Inventory</h1>
        </div>
      </div>

      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          {/* <ProductTable
            quotes={models}
            loading={loading}
            setSelectedEdit={setSelectedEdit}
            selectedEdit={selectedEdit}
            selectedEditValue={selectedEditValue}
            setSelectedEditValue={setSelectedEditValue}
            editProduct={editProduct}
            editProductResponse={editProductResponse}
          /> */}
          <DataTable
            data={parsedInventoryProducts}
            loading={loading}
            setFields={editProduct}
            isActions={true}
            isEditProduct={false}
          />
        </div>
      </div>
    </>
  );
};
