import * as Yup from "yup";

const validationSchema = [
  Yup.object({
    username: Yup.string().required("Required!"),
    last_name: Yup.string().required("Required!"),
    first_name: Yup.string().required("Required!"),
    email: Yup.string().required("Required!"),
    phone_number: Yup.number()
      .typeError("Invalid Phone Number!")
      .min(1111111111, "Too short!")
      .max(99999999999, "Too long!")
      .required("Required!"),
    password: Yup.string().min(8).required("Required!"),
    confirm_password: Yup.string()
      .required("Required!")
      .oneOf([Yup.ref("password"), null], "Passwords Must Match!"),
  }),

  Yup.object({
    business_name: Yup.string()
      .min(1, "Too Short!")
      .required("Required!")
      .max(50, "Too Long!"),
    location: Yup.string()
      .min(1, "Too Short!")
      .required("Required!")
      .max(50, "Too Long!"),
    industry: Yup.string()
      .min(5, "Too Short!")
      .required("Required!")
      .max(50, "Too Long!"),
    address: Yup.string()
      .min(8, "Too Short!")
      .required("Required!")
      .max(100, "Too Long!"),
    city: Yup.string()
      .min(3, "Too Short!")
      .required("Required!")
      .max(50, "Too Long!"),
    state: Yup.string().required("Required!"),
    country: Yup.string().required("Required!"),
    postal_code: Yup.string().required("Required!"),
  }),
];

export default validationSchema;
