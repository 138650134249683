import React, { useEffect, useState } from "react";
import { quoteValidationSchema } from "../../validationSchemas/addQuotesValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import dataService from "../../services/dataService";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Spinner } from "../../components/ui/loader";
import { AddQuoteForm } from "../../components/form/addQuoteForm";
import * as productsActionsCreator from "../../redux/actionsCreator/productActionsCreator";
import * as quotesActionsCreator from "../../redux/actionsCreator/quotesActionsCreator";

export const AddQuotesContainer = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState("");

  const [parsedProducts, setParsedProducts] = useState(null);
  const allUserLoading = useSelector((state) => state.quotesReducer.loading);

  const allUserResponse = useSelector(
    (state) => state.quotesReducer.allUserResponse
  );

  const getProductloading = useSelector(
    (state) => state.productsReducer.loading
  );
  const products = useSelector((state) => state.productsReducer.searchProduct);

  useEffect(() => {
    dispatch(quotesActionsCreator.allUser());
  }, [dispatch]);

  useEffect(() => {
    if (allUserResponse) {
      setUser(dataService?.parseAllUserData(allUserResponse));
    }
  }, [allUserResponse]);

  useEffect(() => {
    dispatch(productsActionsCreator.getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (products) {
      setParsedProducts(
        dataService.parseaddProductsDataForReactSelect(products)
      );
    }
  }, [products]);

  if (getProductloading || allUserLoading || !parsedProducts || !user) {
    return (
      <div className="text-center" style={{ padding: "30%" }}>
        <Spinner height={100} width={100} />
      </div>
    );
  }

  return (
    <>
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1>Add Quote</h1>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          <div className="add-product-form-container-parent">
            <AddQuoteForm
              yupSchema={quoteValidationSchema}
              parsedProducts={parsedProducts}
              user={user}
            />
          </div>
        </div>
      </div>
    </>
  );
};
