import { createSlice, isRejected, isRejectedWithValue } from "@reduxjs/toolkit";
import _ from "lodash";
import * as quotesActionsCreator from "../actionsCreator/quotesActionsCreator";

const quotesSlice = createSlice({
  name: "quotes",
  initialState: {
    quotes: null,
    subQuotes: null,
    response: null,
    loading: false,
    possibleTransitions: null,
    possibleTransitionsLoader: false,
    possibleTransitionsError: false,
    patchQuoteItemsError: null,
    patchQuoteItemsSuccess: false,
    patchDownPaymentResponse: null,
    patchDownPaymentError: null,
    patchDownPaymentLoading: null,
    deleteQuoteResponse: null,
    deleteQuoteError: null,
    addQuoteResponse: null,
    addQuoteError: null,
    allUserResponse: null,
    allUserError: null,
    getQuoteByIdResponse: null,
    getQuoteByIdError: null,
    getUserByIdResponse: null,
    getUserByIdError: null,
    quoteUpdateResponse: null,
    quoteUpdateError: null,
  },
  reducers: {
    clearTransitions: (state) => {
      state.possibleTransitions = null;
      state.possibleTransitionsLoader = null;
      state.possibleTransitionsError = null;
    },

    clearQuoteUpdateData: (state) => {
      state.loading = false;
      state.quoteUpdateResponse = null;
      state.quoteUpdateError = null;
    },
  },
  extraReducers: {
    //get allUsers

    [quotesActionsCreator.allUser.pending]: (state) => {
      state.loading = true;
      state.allUserResponse = null;
      state.allUserError = false;
    },
    [quotesActionsCreator.allUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.allUserResponse = action.payload;
      state.allUserError = false;
    },
    [quotesActionsCreator.allUser.rejected]: (state, action) => {
      state.loading = false;
      state.allUserResponse = null;
      state.allUserError = action.error?.message;
    },
    //get Quotes

    [quotesActionsCreator.getQuotes.pending]: (state) => {
      state.loading = true;
      state.subQuotes = null;
    },
    [quotesActionsCreator.getQuotes.fulfilled]: (state, action) => {
      state.loading = false;
      state.subQuotes = action.payload;
    },
    [quotesActionsCreator.getQuotes.rejected]: (state) => {
      state.loading = false;
      state.subQuotes = null;
    },

    //get all Quotes

    [quotesActionsCreator.getAllQuotes.pending]: (state) => {
      state.loading = true;
      state.quotes = null;
    },
    [quotesActionsCreator.getAllQuotes.fulfilled]: (state, action) => {
      state.loading = false;
      state.quotes = action.payload;
    },
    [quotesActionsCreator.getAllQuotes.rejected]: (state) => {
      state.loading = false;
      state.quotes = null;
    },

    //patch quote Items

    [quotesActionsCreator.patchQuoteItems.pending]: (state) => {
      // state.loading = true;
      state.response = null;
      state.patchQuoteItemsSuccess = false;
      state.patchQuoteItemsError = null;
    },
    [quotesActionsCreator.patchQuoteItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.patchQuoteItemsSuccess = true;
      state.patchQuoteItemsError = null;
    },
    [quotesActionsCreator.patchQuoteItems.rejected]: (state, action) => {
      state.loading = false;
      state.patchQuoteItemsError = action.payload;
    },

    //getPossible transactions

    [quotesActionsCreator.getpossibleTransitions.pending]: (state) => {
      state.possibleTransitionsLoader = true;
      state.possibleTransitions = null;
      state.possibleTransitionsError = false;
    },
    [quotesActionsCreator.getpossibleTransitions.fulfilled]: (
      state,
      action
    ) => {
      state.possibleTransitionsLoader = false;
      state.possibleTransitions = action.payload;
      state.possibleTransitionsError = false;
    },
    [quotesActionsCreator.getpossibleTransitions.rejected]: (state, action) => {
      state.possibleTransitionsLoader = false;
      state.possibleTransitions = null;
      state.possibleTransitionsError = action.error?.message;
    },

    //getQuoteById

    [quotesActionsCreator.getQuoteById.pending]: (state) => {
      state.loading = true;
      state.getQuoteByIdResponse = null;
      state.getQuoteByIdError = false;
    },
    [quotesActionsCreator.getQuoteById.fulfilled]: (state, action) => {
      state.loading = false;
      state.getQuoteByIdResponse = action.payload;
      state.getQuoteByIdError = false;
    },
    [quotesActionsCreator.getQuoteById.rejected]: (state, action) => {
      state.loading = false;
      state.getQuoteByIdResponse = null;
      state.getQuoteByIdError = action.error?.message;
    },

    //getQuoteById

    [quotesActionsCreator.getUserById.pending]: (state) => {
      state.loading = true;
      state.getUserByIdResponse = null;
      state.getUserByIdError = false;
    },
    [quotesActionsCreator.getUserById.fulfilled]: (state, action) => {
      state.loading = false;
      state.getUserByIdResponse = action.payload;
      state.getUserByIdError = false;
    },
    [quotesActionsCreator.getUserById.rejected]: (state, action) => {
      state.loading = false;
      state.getUserByIdResponse = null;
      state.getUserByIdError = action.error?.message;
    },

    //postPossible transactions

    [quotesActionsCreator.postpossibleTransitions.pending]: (state) => {
      state.possibleTransitionsLoader = true;
      state.possibleTransitions = null;
      state.possibleTransitionsError = false;
    },
    [quotesActionsCreator.postpossibleTransitions.fulfilled]: (
      state,
      action
    ) => {
      state.possibleTransitionsLoader = false;

      state[action.payload.productType].map((item, index) => {
        if (item.id === action.payload.data.id) {
          state[action.payload.productType][index] = action.payload.data;
        }
      });
      state.possibleTransitions = null;
      state.possibleTransitionsError = false;
    },
    [quotesActionsCreator.postpossibleTransitions.rejected]: (state) => {
      state.possibleTransitionsLoader = false;
      state.possibleTransitions = null;
      state.possibleTransitionsError = true;
    },

    // quote price handler

    [quotesActionsCreator.priceHandler]: (state, action) => {
      state.subQuotes = action.payload;
    },

    //resetting transitions
    [quotesActionsCreator.clearTransitions]: (state) => {
      state.possibleTransitions = null;
      state.possibleTransitionsLoader = null;
      state.possibleTransitionsError = null;
    },

    //resetting downpayments
    [quotesActionsCreator.clearDownPayment]: (state) => {
      state.patchDownPaymentError = null;
      state.patchDownPaymentResponse = null;
    },

    //Set downpayment for quotes

    [quotesActionsCreator.patchDownPaymentForQuote.pending]: (state) => {
      state.patchDownPaymentLoading = true;
      state.patchDownPaymentError = null;
      state.patchDownPaymentResponse = null;
    },
    [quotesActionsCreator.patchDownPaymentForQuote.fulfilled]: (
      state,
      action
    ) => {
      let tempQuotes = _.cloneDeep(action.payload.quotes);
      tempQuotes.map((quote, index) => {
        let response = JSON.parse(action.payload.response.config.data);
        if (quote.id === response.id) {
          tempQuotes[index].down_payment = action.payload.downPayment;
        }
      });
      state.patchDownPaymentLoading = true;
      state.patchDownPaymentError = null;
      state.patchDownPaymentResponse = action.payload.response.data;

      state.quotes = tempQuotes;
    },
    [quotesActionsCreator.patchDownPaymentForQuote.rejected]: (
      state,
      action
    ) => {
      state.patchDownPaymentLoading = true;
      state.patchDownPaymentError = action.payload;
      state.patchDownPaymentResponse = null;
    },

    //delete quote

    [quotesActionsCreator.deleteQuote.pending]: (state) => {
      state.loading = true;
      state.deleteQuoteResponse = null;
      state.deleteQuoteError = null;
    },
    [quotesActionsCreator.deleteQuote.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteQuoteResponse = true;
      state.deleteQuoteError = null;
    },
    [quotesActionsCreator.deleteQuote.rejected]: (state, action) => {
      state.loading = false;
      state.deleteQuoteResponse = false;
      state.deleteQuoteError = action.payload;
    },

    // addQuote

    [quotesActionsCreator.addQuote.pending]: (state) => {
      state.loading = true;
      state.addQuoteResponse = null;
      state.addQuoteError = null;
    },
    [quotesActionsCreator.addQuote.fulfilled]: (state, action) => {
      state.loading = false;
      state.addQuoteResponse = action.payload;
      state.addQuoteError = null;
    },
    [quotesActionsCreator.addQuote.rejected]: (state, action) => {
      state.loading = false;
      state.addQuoteResponse = null;
      state.addQuoteError = action.payload;
    },

    // quoteUpdate

    [quotesActionsCreator.updateQuote.pending]: (state) => {
      state.loading = true;
      state.quoteUpdateResponse = null;
      state.quoteUpdateError = null;
    },
    [quotesActionsCreator.updateQuote.fulfilled]: (state, action) => {
      state.loading = false;
      state.quoteUpdateResponse = action.payload;
      state.quoteUpdateError = null;
    },
    [quotesActionsCreator.updateQuote.rejected]: (state, action) => {
      state.loading = false;
      state.quoteUpdateResponse = null;
      state.quoteUpdateError = action.payload;
    },
  },
});
export const { clearTransitions } = quotesSlice.actions;
export default quotesSlice.reducer;
