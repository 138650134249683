import React from "react";

export const SignUpUserInfo = (props) => {
  return (
    <div>
      <div
        className={
          props.errors.username && props.touched.username
            ? "form-outline-danger form-outline "
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          placeholder="User Name"
          type="text"
          onChange={props.handleChange}
          value={props.values.username}
          name="username"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.username && props.touched.username && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.username}
        </small>
      )}

      <div
        className={
          props.errors.email && props.touched.email
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          className="form-control"
          id="validationCustom04"
          placeholder="Email Address"
          type="email"
          onChange={props.handleChange}
          value={props.values.email}
          name="email"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.email && props.touched.email && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.email}
        </small>
      )}
      <div
        className={
          props.errors.first_name && props.touched.first_name
            ? "form-outline form-outline-danger border-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          placeholder="First Name"
          onChange={props.handleChange}
          value={props.values.first_name}
          name="first_name"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.first_name && props.touched.first_name && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.first_name}
        </small>
      )}

      <div
        className={
          props.errors.last_name && props.touched.last_name
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          placeholder="Last Name"
          onChange={props.handleChange}
          value={props.values.last_name}
          name="last_name"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.last_name && props.touched.last_name && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.last_name}
        </small>
      )}
      <div
        className={
          props.errors.phone_number && props.touched.phone_number
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          placeholder="Phone #"
          onChange={props.handleChange}
          value={props.values.phone_number}
          name="phone_number"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.phone_number && props.touched.phone_number && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.phone_number}
        </small>
      )}

      <div
        className={
          props.errors.password && props.touched.password
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          onChange={props.handleChange}
          value={props.values.password}
          name="password"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.password && props.touched.password && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.password}
        </small>
      )}

      <div
        className={
          props.errors.confirm_password && props.touched.confirm_password
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="password"
          className="form-control"
          placeholder="Confirm password"
          onChange={props.handleChange}
          value={props.values.confirm_password}
          name="confirm_password"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.confirm_password && props.touched.confirm_password && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.confirm_password}
        </small>
      )}
    </div>
  );
};
