import * as Yup from "yup";

export const componentValidationSchema = Yup.object({
  name: Yup.string()
    .nullable(true)
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .required("Required"),
  sku: Yup.string().required("Required"),
  quantity: Yup.number().typeError("Required").min(1, "Min value 1"),
  application: Yup.array().min(1).required("Required"),
  model: Yup.array().min(1).required("Required"),
  component_subcategory: Yup.number().required("Required"),
  description: Yup.string(),
  height: Yup.number().required("Required").min(1, "required"),
  width: Yup.number().typeError("Required").min(1, "Min value 1"),
  weight: Yup.number().typeError("Required").min(1, "Min value 1"),
  length: Yup.number().typeError("Required").min(1, "Min value 1"),
});
export const modelValidationSchema = Yup.object({
  name: Yup.string()
    .nullable(true)
    .transform((cv, ov) => {
      return ov === "" ? null : cv;
    })
    .required("Required"),
  sku: Yup.string().required("Required"),
  price: Yup.number().typeError("You must specify price").min(0, "Min value 0"),
  height: Yup.number().typeError("Required").min(1, "Min value 1"),
  width: Yup.number().typeError("Required").min(1, "Min value 1"),
  weight: Yup.number().typeError("Required").min(1, "Min value 1"),
  length: Yup.number().typeError("Required").min(1, "Min value 1"),
  quantity: Yup.number().typeError("Required").min(1, "Min value 1"),
  application: Yup.array().min(1).required("Required"),
  description: Yup.string().notRequired(),
  equipment: Yup.string().required("Required"),
});
