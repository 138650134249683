import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ordersActionsCreator from "../../redux/actionsCreator/ordersActionsCreator";
import { Container, Row, Col, Badge, Button } from "reactstrap";
import { PubnubChat } from "../../components/chat/chat";
import InitializePubnub from "../../components/initializePubnub/initializePubnub";
import { DataTable } from "../../components/ui/tables/dataTable";
import dataService from "../../services/dataService";
import { routesPath } from "../../constants/routesPath";
import { useHistory } from "react-router-dom";

export const OrdersContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.ordersReducer.orders);
  const loading = useSelector((state) => state.ordersReducer.loading);
  const [openChat, setOpenChat] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(``);
  const [parsedOrders, setParsedOrders] = useState([]);

  useEffect(() => {
    dispatch(ordersActionsCreator.getOrders());
  }, []);

  useEffect(() => {
    if (orders) {
      let result = dataService.parseOrdersTable(orders, getOrderItems, getChat);
      setParsedOrders(result);
    }
  }, [orders]);

  const getOrderItems = (order) => {
    return order.suborders.map((subOrder) => {
      return subOrder.sub_quote.quote_items.map((quoteItem, index) => {
        return (
          <Badge className="dark bg-dark me-2" key={index}>
            {quoteItem.product.data.name}
          </Badge>
        );
      });
    });
  };

  const getChat = (quote) => {
    return (
      <div
        className="quotes-action"
        onClick={(e) => {
          e.stopPropagation();
          handleChat(quote.id);
        }}
      >
        <button>Chat</button>
      </div>
    );
  };

  const handleChat = (id) => {
    setCurrentChannel(`order-${id}`);
    setOpenChat(true);
  };

  const navigateToSubOrders = (order) => {
    history.push(routesPath.adminSubOrders(order["#"]));
  };

  return (
    <>
      <div>
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Orders</h1>
          </div>
        </div>
        <div className="dashboard-content-wrap">
          <div className="main-content-inner">
            <Row>
              <Col className="ml-auto" md="12">
                {/* {(!orders || !orders.length) && !loading && (
                  <div className="text-center pt-5 mt-5">
                    <h3>No Order Found</h3>
                  </div>
                )} */}
                <DataTable
                  data={parsedOrders}
                  loading={loading}
                  clicked={navigateToSubOrders}
                  isActions={false}
                  isEditProduct={false}
                  isProductDetail={true}
                />
              </Col>
            </Row>

            {localStorage.getItem("userEmail") && openChat && (
              <InitializePubnub>
                <PubnubChat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  currentChannel={currentChannel}
                />
              </InitializePubnub>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
