import React from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

export const SignUpCompanyInfo = (props) => {
  const selectCountry = (val) => {
    props.setValues(
      {
        ...props.values,
        country: val,
        state: "",
      },
      true
    );
  };
  const selectRegion = (val) => {
    props.setValues({ ...props.values, state: val }, true);
  };
  return (
    <div>
      <div
        className={
          props.errors.business_name && props.touched.business_name
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          id="validationCustom01"
          placeholder="Bussines Name"
          onChange={props.handleChange}
          value={props.values.business_name}
          name="business_name"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.business_name && props.touched.business_name && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.business_name}
        </small>
      )}

      <div
        className={
          props.errors.location && props.touched.location
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          id="validationCustom01"
          placeholder="Location"
          onChange={props.handleChange}
          value={props.values.location}
          name="location"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.location && props.touched.location && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.location}
        </small>
      )}

      <div
        className={
          props.errors.industry && props.touched.industry
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          id="validationCustom01"
          placeholder="Industry"
          onChange={props.handleChange}
          value={props.values.industry}
          name="industry"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.industry && props.touched.industry && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.industry}
        </small>
      )}

      <div
        className={
          props.errors.address && props.touched.address
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          id="validationCustom01"
          placeholder="Address"
          onChange={props.handleChange}
          value={props.values.address}
          name="address"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.address && props.touched.address && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.address}
        </small>
      )}

      <div
        className={
          props.errors.country && props.touched.country
            ? "form-outline form-outline-danger"
            : "form-outline "
        }
      >
        <CountryDropdown
          style={{
            width: "100%",
            padding: "13px",
            border: "1px solid #eeebeb",
            color: "#212529",
          }}
          classes={
            props.errors.country && props.touched.country
              ? "form-control border-danger"
              : "form-control"
          }
          name="country"
          value={props.values.country}
          onBlur={(_, e) => props.handleBlur(e)}
          onChange={(val, e) => {
            selectCountry(val);
            props.handleChange(e);
          }}
        />
      </div>
      {props.errors.country && props.touched.country && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.country}
        </small>
      )}

      <div
        className={
          props.errors.state && props.touched.state
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <RegionDropdown
          style={{
            width: "100%",
            padding: "13px",
            border: "1px solid #eeebeb",
            color: "#212529",
          }}
          classes={
            props.errors.state && props.touched.state
              ? "form-control border-danger"
              : "form-control"
          }
          country={props.values.country}
          name="state"
          value={props.values.state}
          onChange={(val, e) => {
            selectRegion(val);
            props.handleChange(e);
          }}
          onBlur={(_, e) => props.handleBlur(e)}
        />
      </div>
      {props.errors.state && props.touched.state && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.state}
        </small>
      )}

      <div
        className={
          props.errors.city && props.touched.city
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control"
          id="validationCustom01"
          placeholder="City"
          onChange={props.handleChange}
          value={props.values.city}
          name="city"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.city && props.touched.city && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.city}
        </small>
      )}

      <div
        className={
          props.errors.postal_code && props.touched.postal_code
            ? "form-outline form-outline-danger"
            : "form-outline"
        }
      >
        <input
          type="text"
          className="form-control form-outline"
          id="validationCustom01"
          placeholder="Postal code"
          onChange={props.handleChange}
          value={props.values.postal_code}
          name="postal_code"
          onBlur={props.handleBlur}
        />
      </div>
      {props.errors.postal_code && props.touched.postal_code && (
        <small style={{ float: "left" }} className="text-danger ms-4 ps-3 pb-3">
          {props.errors.postal_code}
        </small>
      )}
    </div>
  );
};
