import "./App.css";
import { Router } from "./router";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </Elements>
      </Provider>
    </div>
  );
};

export default App;
