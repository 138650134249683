import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import dataService from "../../services/dataService";
import { Spinner } from "../ui/loader";
import * as quotesActionsCreator from "../../redux/actionsCreator/quotesActionsCreator";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";
import * as productsActionsCreator from "../../redux/actionsCreator/productActionsCreator";
import { Divider } from "antd";

export const EditQuoteForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [parsedProducts, setParsedProducts] = useState(null);
  const [user, setUser] = useState("");

  const { yupSchema, getQuoteByIdResponse } = props;

  const quoteId = getQuoteByIdResponse?.id;

  const allUserResponse = useSelector(
    (state) => state.quotesReducer.allUserResponse
  );

  const addQuoteResponse = useSelector(
    (state) => state.quotesReducer.addQuoteResponse
  );

  const addQuoteError = useSelector(
    (state) => state.quotesReducer.addQuoteError
  );

  const quoteUpdateResponse = useSelector(
    (state) => state.quotesReducer.quoteUpdateResponse
  );

  const quoteUpdateError = useSelector(
    (state) => state.quotesReducer.quoteUpdateError
  );

  const productsToBeEdit = useSelector(
    (state) => state.productsReducer.searchProduct
  );

  useEffect(() => {
    dispatch(quotesActionsCreator.allUser());
  }, [dispatch]);

  useEffect(() => {
    if (allUserResponse) {
      setUser(dataService?.parseAllUserData(allUserResponse));
    }
  }, [allUserResponse]);

  useEffect(() => {
    dispatch(productsActionsCreator.getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (productsToBeEdit) {
      setParsedProducts(
        dataService.parseaddProductsDataForReactSelect(productsToBeEdit)
      );
    }
  }, [productsToBeEdit]);

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: "all",
    defaultValues: {},
  });

  const onSubmit = (values) => {
    dispatch(
      quotesActionsCreator.updateQuote({
        quote_id: quoteId,
        remove_products: [],
        add_product: [{ id: values?.id, quantity: values?.quantity }],
      })
    );
    setTimeout(() => {
      history.push(routesPath.quotes);
    }, 2000);
  };

  useEffect(() => {
    if (quoteUpdateResponse) {
      toast.success(`Successfully updated quote ${quoteId}`);
    }
  }, [quoteUpdateResponse]);

  useEffect(() => {
    if (addQuoteError) {
      toast.error("Failed to add Quote");
    }
  }, [addQuoteError]);

  return (
    <>
      <FormProvider {...form}>
        <form
          className="add-product-form-container"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <SelectInput
            name="id"
            label="Product Id"
            options={parsedProducts || []}
            required={true}
          />
          <TextInput
            name="quantity"
            label="Quantity"
            type="number"
            required={true}
            min={1}
          />
          <div className="add-form-button m-auto mb-3 pt-4">
            <div className="cart-btn">
              <div className="links-btn">
                <div className="add-to-cart">
                  <button type="submit">
                    {/* {loading && <Spinner height={20} width={20} />} */}
                    Add Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
