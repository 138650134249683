import React, { useEffect, useRef } from "react";
import validationSchema from "./validationSchemaForResendEmail";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as actionsCreator from "../../redux/actionsCreator/authActionsCreator";
import { toast } from "react-toastify";
import { Spinner } from "../../components/ui/loader";

// Core Components

export const EmailVerificationFormContainer = () => {
  let dispatch = useDispatch();
  let emailRef = useRef(null);

  const resendEmailVerificationResponse = useSelector(
    (state) => state.authReducer.resendEmailVerificationResponse
  );
  const resendEmailVerificationError = useSelector(
    (state) => state.authReducer.resendEmailVerificationError
  );
  const loading = useSelector((state) => state.authReducer.loading);
  const initialValues = { email: "" };

  const emailFunction = (data) => {
    if (data.email) {
      dispatch(actionsCreator.resendEmailVerification(data));
    }
  };

  useEffect(() => {
    if (resendEmailVerificationResponse) {
      toast.success("Email Sent Successfully!");
    }
    if (resendEmailVerificationError) {
      toast.error(resendEmailVerificationError?.response?.data);
    }
  }, [resendEmailVerificationResponse, resendEmailVerificationError]);

  return (
    <>
      <main>
        <section className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Formik
                  validateOnChange={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    emailFunction(values);
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="needs-validation">
                        <div className="heading_txt_login">
                          <h1>Email Verification</h1>
                        </div>
                        <div
                          className={
                            errors.email
                              ? "form-outline form-outline-danger"
                              : "form-outline"
                          }
                        >
                          <input
                            className="form-control"
                            id="validationCustom01"
                            placeholder="User Email"
                            type="text"
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            ref={emailRef}
                            required
                          />
                        </div>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={!isValid || loading}
                        >
                          {loading && (
                            <div className="d-inline px-3">
                              <Spinner height={20} width={20} />
                            </div>
                          )}
                          <span className="d-inline">Send</span>
                        </button>
                        <hr />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
