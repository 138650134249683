import React, { forwardRef, useState } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import "./table.css";
import { useEffect } from "react";
import { toast } from "react-toastify";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  visibilityIcon: forwardRef((props, ref) => (
    <VisibilityIcon {...props} ref={ref} />
  )),
};

export const SubDataTable = (props) => {
  const { tableHeader, tableData } = props?.data;
  const {
    isActions,
    isEditProduct,
    isProductDetail,
    loading,
    isEditActionHidden,
  } = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (tableData.length) {
      setShow(!show);
    }
  }, [tableData]);

  return (
    <>
      <div className="sub-materialTable-wrap my-0">
        {tableData && (
          <MaterialTable
            style={{ border: "none" }}
            editable={{
              onRowUpdate: async (newData) => {
                if (newData["UNIT_PRICE"] < 1) {
                  toast.error("Please Enter A Valid Price!");
                  return;
                }
                await props.setFields(newData, props.subQuoteId);
              },
              isEditHidden: (rowData) => isEditActionHidden,
            }}
            actions={[
              (rowData) => ({
                icon: tableIcons.Delete,
                tooltip: "Delete product",
                onClick: () => props.onDeleteProduct(rowData, props.subQuoteId),
                // (event, rowData) =>
                //   props.deleteProduct(event, rowData),
                hidden: !props.deleteAble,
              }),
            ]}
            localization={{
              header: "",
              toolbar: "",
              pagination: {},
              header: !isActions
                ? {
                    actions: "",
                  }
                : "",
            }}
            options={{
              showTitle: false,
              actionsColumnIndex: -1,
              toolbarButtonAlignment: "left",
              toolbar: false,
              paging: false,

              detailPanelColumnStyle: {
                border: "none",
              },
            }}
            onRowClick={(e, rowData) =>
              isProductDetail ? props.clicked(rowData, props.subQuoteId) : null
            }
            icons={tableIcons}
            columns={tableHeader.map((headData) => {
              return {
                title: headData,
                field: headData,
                type: headData === "UNIT_PRICE" ? "numeric" : "",
                validate: (rowData) =>
                  +rowData[headData] < 1
                    ? {
                        isValid: false,
                        helperText: "Please enter a valid price",
                      }
                    : "",
                render: (rowData) => <>{rowData[headData]}</>,

                editable: headData === "UNIT_PRICE" ? "onUpdate" : "never",
              };
            })}
            data={tableData}
          />
        )}
      </div>
    </>
  );
};
