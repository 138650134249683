import { createSlice } from "@reduxjs/toolkit";
import * as subscriptionActionsCreator from "../actionsCreator/subscriptionActionsCreator";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    response: null,
    loading: false,
    error: null,
    userSubscriptionsResponse: null,
    loading: null,
    userSubscriptionsError: null,
    chooseSubscriptionPlanResponse: null,
    chooseSubscriptionPlanLoading: false,
    chooseSubscriptionPlanError: null,
    cancelSubscriptionPlanResponse: null,
    cancelSubscriptionPlanLoading: false,
    cancelSubscriptionPlanError: null,
    updateSubscriptionResponse: null,
    updateSubscriptionLoading: false,
    updateSubscriptionError: null,
  },
  reducers: {},
  extraReducers: {
    // get all subscriptions

    [subscriptionActionsCreator.getSubscription.pending]: (state) => {
      state.loading = true;
      state.response = null;
      state.error = null;
    },
    [subscriptionActionsCreator.getSubscription.fulfilled]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.error = null;
    },
    [subscriptionActionsCreator.getSubscription.rejected]: (state, action) => {
      state.loading = false;
      state.response = null;
      state.error = action.payload;
    },

    // get user subscriptions

    [subscriptionActionsCreator.getSubscribedPlans.pending]: (state) => {
      state.loading = true;
      state.userSubscriptionsResponse = null;
      state.userSubscriptionsError = null;
    },
    [subscriptionActionsCreator.getSubscribedPlans.fulfilled]: (
      state,
      action
    ) => {
      state.loading = false;
      state.userSubscriptionsResponse = action.payload;
      state.userSubscriptionsError = null;
    },
    [subscriptionActionsCreator.getSubscribedPlans.rejected]: (
      state,
      action
    ) => {
      state.loading = false;
      state.userSubscriptionsResponse = null;
      state.userSubscriptionsError = action.payload;
    },

    // subscribe a plan

    [subscriptionActionsCreator.chooseSubscriptionPlan.pending]: (state) => {
      state.chooseSubscriptionPlanLoading = true;
      state.chooseSubscriptionPlanResponse = null;
      state.chooseSubscriptionPlanError = null;
    },
    [subscriptionActionsCreator.chooseSubscriptionPlan.fulfilled]: (
      state,
      action
    ) => {
      state.chooseSubscriptionPlanLoading = false;
      state.chooseSubscriptionPlanResponse = action.payload;
      state.chooseSubscriptionPlanError = null;
    },
    [subscriptionActionsCreator.chooseSubscriptionPlan.rejected]: (
      state,
      action
    ) => {
      state.chooseSubscriptionPlanLoading = false;
      state.chooseSubscriptionPlanResponse = null;
      state.chooseSubscriptionPlanError = action.payload;
    },

    // cancel a subscription

    [subscriptionActionsCreator.cancelSubscriptionPlan.pending]: (state) => {
      state.cancelSubscriptionPlanResponse = null;
      state.cancelSubscriptionPlanLoading = true;
      state.cancelSubscriptionPlanError = null;
    },
    [subscriptionActionsCreator.cancelSubscriptionPlan.fulfilled]: (
      state,
      action
    ) => {
      state.cancelSubscriptionPlanResponse = action.payload.response;
      state.cancelSubscriptionPlanLoading = true;
      state.cancelSubscriptionPlanError = null;
      state.userSubscriptionsResponse = null;
    },
    [subscriptionActionsCreator.cancelSubscriptionPlan.rejected]: (
      state,
      action
    ) => {
      state.cancelSubscriptionPlanResponse = null;
      state.cancelSubscriptionPlanLoading = true;
      state.cancelSubscriptionPlanError = action.payload;
    },

    // update subscription

    [subscriptionActionsCreator.updateSubscriptionPlan.pending]: (state) => {
      state.updateSubscriptionResponse = null;
      state.updateSubscriptionLoading = true;
      state.updateSubscriptionError = null;
    },
    [subscriptionActionsCreator.updateSubscriptionPlan.fulfilled]: (
      state,
      action
    ) => {
      state.updateSubscriptionResponse = action.payload;
      state.updateSubscriptionLoading = false;
      state.updateSubscriptionError = null;
    },
    [subscriptionActionsCreator.updateSubscriptionPlan.rejected]: (
      state,
      action
    ) => {
      state.updateSubscriptionResponse = null;
      state.updateSubscriptionLoading = false;
      state.updateSubscriptionError = action.payload;
    },

    //reset subscriptions
    [subscriptionActionsCreator.resetSubscriptions]: (state) => {
      state.response = null;
      state.loading = false;
      state.error = null;
      state.userSubscriptionsResponse = null;
      state.loading = null;
      state.userSubscriptionsError = null;
      state.chooseSubscriptionPlanResponse = null;
      state.chooseSubscriptionPlanLoading = false;
      state.chooseSubscriptionPlanError = null;
      state.cancelSubscriptionPlanResponse = null;
      state.cancelSubscriptionPlanLoading = false;
      state.cancelSubscriptionPlanError = null;
    },
  },
});

export default subscriptionSlice.reducer;
