import storageService from "../services/storageService";
import { routesPath } from "../constants/routesPath";
import { ProfileContainer } from "../containers/profileContainer/AccountInfoContainer";
import { ProductsContainer } from "../containers/productsContainer/ProductsContainer";
import { SubscriptionContainer } from "../containers/subscriptionContainer/SubscriptionContainer";
import { SubQuotesContainer } from "../containers/subQuotesContainer/SubQuotesContainer";
import { EditQuotesContainer } from "../containers/editQuotesContainer/EditQuotesContainer";
import { SubOrdersContainer } from "../containers/subOrdersContainer/SubOrdersContainer";
import { PaymentContainer } from "../containers/paymentContainer/PaymentContainer";
import { QuotesContainer } from "../containers/quotesContainer/QuotesContainer";
import { TenantQuotesContainer } from "../containers/tenantQuotesContainer/TenantQuotesContainer";
import { TenantOrdersContainer } from "../containers/tenantOrdersContainer/TenantOrdersContainer";
import { TenantProductsContainer } from "../containers/tenantProductsContainer/TenantProductsContainer";
import { OrdersContainer } from "../containers/ordersContainer/OrdersContainer";
import { AddProductsContainer } from "../containers/addProductsContainer/AddProductsContainer";
import { AddQuotesContainer } from "../containers/addQuotesContainer/addQuotesContainer";
import { InventoryContainer } from "../containers/inventoryContainer/InventoryContainer";
import { Error404Container } from "../containers/error404Container/Error404Container";

const getAllowedRoutes = () => {
  let commonRoutes = [
    {
      exact: true,
      path: routesPath.profile,
      component: ProfileContainer,
    },
    {
      exact: true,
      path: routesPath.products,
      component: ProductsContainer,
    },
    {
      exact: true,
      path: routesPath.addProducts,
      component: AddProductsContainer,
    },
    {
      exact: true,
      path: routesPath.addProducts,
      component: AddProductsContainer,
    },
    {
      exact: true,
      path: routesPath.addQuotes,
      component: AddQuotesContainer,
    },

    {
      exact: true,
      path: routesPath.inventory,
      component: InventoryContainer,
    },

    {
      exact: true,
      path: routesPath.editProduct(":id"),
      component: AddProductsContainer,
    },
  ];

  if (storageService.getUserRole() === "VENDOR") {
    let vendorRoutes = [
      {
        exact: true,
        path: routesPath.subQuotes,
        component: SubQuotesContainer,
      },
      // {
      //   exact: true,
      //   path: routesPath.editQuote,
      //   component: EditQuotesContainer,
      // },
      {
        exact: true,
        path: routesPath.subOrders,
        component: SubOrdersContainer,
      },
      { exact: true, path: routesPath.payments, component: PaymentContainer },
      {
        exact: true,
        path: routesPath.subscription,
        component: SubscriptionContainer,
      },
    ];

    commonRoutes = [...commonRoutes, ...vendorRoutes];
  }
  if (storageService.getUserRole() === "ADMIN") {
    let adminRoutes = [
      {
        exact: true,
        path: routesPath.quotes,
        component: QuotesContainer,
      },
      {
        exact: true,
        path: routesPath.orders,
        component: OrdersContainer,
      },
      {
        exact: true,
        path: routesPath.adminSubQuotes(":id"),
        component: SubQuotesContainer,
      },
      {
        exact: true,
        path: routesPath.adminEditQuote(":id"),
        component: EditQuotesContainer,
      },
      {
        exact: true,
        path: routesPath.adminSubOrders(":id"),
        component: SubOrdersContainer,
      },
    ];

    commonRoutes = [...commonRoutes, ...adminRoutes];
  }
  if (storageService.getTenantId()) {
    let tenantRoutes = [
      {
        exact: true,
        path: routesPath.tenantQuotes,
        component: TenantQuotesContainer,
      },
      {
        exact: true,
        path: routesPath.tenantOrders,
        component: TenantOrdersContainer,
      },
      {
        exact: true,
        path: routesPath.tenantProduct,
        component: TenantProductsContainer,
      },
    ];
    commonRoutes = [...commonRoutes, ...tenantRoutes];
  }
  commonRoutes.push({
    exact: true,
    path: routesPath.notFound,
    component: Error404Container,
  });

  return commonRoutes;
};

export default {
  getAllowedRoutes,
};
