import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionsCreator from "../../redux/actionsCreator/authActionsCreator";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";

export const VerifyEmailContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const dispatch = useDispatch();

  const handleLogin = () => {
    history.push(routesPath.login);
  };

  const emailVerifyResponse = useSelector(
    (state) => state.authReducer.emailVerifyResponse
  );

  const emailVerifyError = useSelector(
    (state) => state.authReducer.emailVerifyError
  );

  useEffect(() => {
    console.log(token);
    dispatch(actionsCreator.emailVerify(token));
  }, []);

  useEffect(() => {
    if (emailVerifyResponse) {
      toast.success(emailVerifyResponse?.email);
    }
    if (emailVerifyError) {
      toast.error(emailVerifyError?.response?.data?.error);
      history.push(routesPath.emailVerification);
    }
  }, [emailVerifyResponse, emailVerifyError]);

  return (
    <>
      <main>
        <section className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <form>
                  <div className="needs-validation">
                    <div className="heading_txt_login">
                      <h1>Account Activated!</h1>
                    </div>
                    {emailVerifyResponse && (
                      <button className="btn btn-primary" onClick={handleLogin}>
                        <span className="d-inline">Login</span>
                      </button>
                    )}
                    <hr />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
