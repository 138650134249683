export const routesPath = {
  dashboard: "/dashboard",
  login: "/login",
  subscription: "/subscription",
  payments: "/payments",
  products: "/products",
  register: "/register",
  subQuotes: "/subquotes",
  adminSubQuotes: (id) => `/quote/${id}/subquotes`,
  quotes: "/quotes",
  inventory: "/inventory",
  orders: "/orders",
  subOrders: "/suborders",
  adminSubOrders: (id) => `/order/${id}/suborders`,
  accountInfo: "/accountInfo",
  profile: `/`,
  addProducts: `/add-product`,
  addQuotes: `/add-quote`,
  editProduct: (id) => `/products/${id}`,
  tenantProduct: `/tenant-products`,
  tenantQuotes: `/tenent-quotes`,
  tenantOrders: `/tenant-orders`,
  notFound: "*",
  noFound: "/not-found",
  emailVerification: "/emailVerification",
  verifyEmail: "/verifyEmail",
  reset: "/resetPassword",
  editQuote: "/editQuotes",
  adminEditQuote: (id) => `/quote/${id}/editQuote`,
};
