import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import storageService from "../services/storageService";

export const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const authentication = useSelector((state) => state.authReducer.authed);
  const authed = authentication || Boolean(storageService.getJWTToken());
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authed) {
          // not logged in so redirect to login page with the return url
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />;
        }

        return Component ? <Component {...props} /> : render(props);

        // authorised so return component
      }}
    />
  );
};
