import { createSlice } from "@reduxjs/toolkit";
import * as paymentActionsCreator from "../actionsCreator/paymentActionsCreator";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    response: null,
    loading: false,
    error: null,
    setupIntentResponse: null,
    setupIntentError: null,
    setupIntentLoading: false,
    deletePaymentMethodResponse: null,
    deletePaymentMethodError: null,
    deletePaymentMethodLoading: null,
    updateCardResponse: null,
    updateCardLoading: null,
    updateCardError: null,
  },
  reducers: {},
  extraReducers: {
    //get paymethods list
    [paymentActionsCreator.getPaymentMethods.pending]: (state) => {
      state.loading = true;
      state.response = null;
      state.error = null;
    },
    [paymentActionsCreator.getPaymentMethods.fulfilled]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.error = null;
    },
    [paymentActionsCreator.getPaymentMethods.rejected]: (state, action) => {
      state.loading = false;
      state.response = null;
      state.error = action.payload;
    },

    //create setup intent
    [paymentActionsCreator.createSetupIntent.pending]: (state) => {
      state.setupIntentLoading = false;
      state.setupIntentResponse = null;
      state.setupIntentError = null;
    },
    [paymentActionsCreator.createSetupIntent.fulfilled]: (state, action) => {
      state.setupIntentLoading = false;
      state.setupIntentResponse = action.payload;
      state.setupIntentError = null;
    },
    [paymentActionsCreator.createSetupIntent.rejected]: (state, action) => {
      state.setupIntentLoading = false;
      state.setupIntentResponse = null;
      state.setupIntentError = action.payload;
    },

    //create setup intent

    [paymentActionsCreator.deletePaymentMethod.pending]: (state) => {
      state.deletePaymentMethodResponse = null;
      state.deletePaymentMethodLoading = false;
      state.deletePaymentMethodError = null;
    },
    [paymentActionsCreator.deletePaymentMethod.fulfilled]: (state, action) => {
      state.deletePaymentMethodResponse = action.payload.card;
      state.deletePaymentMethodLoading = false;
      state.deletePaymentMethodError = null;
      state.response = action.payload.card;
    },
    [paymentActionsCreator.deletePaymentMethod.rejected]: (state, action) => {
      state.deletePaymentMethodResponse = null;
      state.deletePaymentMethodLoading = false;
      state.deletePaymentMethodError = action.payload;
    },

    //change default card

    [paymentActionsCreator.updatePaymentCard.pending]: (state) => {
      state.updateCardResponse = null;
      state.updateCardLoading = true;
      state.updateCardError = null;
    },
    [paymentActionsCreator.updatePaymentCard.fulfilled]: (state, action) => {
      state.updateCardResponse = action.payload;
      state.updateCardLoading = false;
      state.updateCardError = null;
    },
    [paymentActionsCreator.updatePaymentCard.rejected]: (state, action) => {
      state.updateCardResponse = null;
      state.updateCardLoading = false;
      state.updateCardError = action.payload;
    },

    // reset payment data

    [paymentActionsCreator.resetPayments]: (state) => {
      state.loading = false;
      state.response = null;
      state.error = null;
      state.deletePaymentMethodResponse = null;
      state.deletePaymentMethodLoading = false;
      state.deletePaymentMethodError = null;
      state.setupIntentLoading = false;
      state.setupIntentResponse = null;
      state.setupIntentError = null;
      state.updateCardResponse = null;
      state.updateCardLoading = null;
      state.updateCardError = null;
    },
  },
});

export default paymentSlice.reducer;
