import React from "react";
import { useSelector } from "react-redux";
import { SideDrawer } from "../components/sideDrawer/sideDrawer";
import storageService from "../services/storageService";
export const CoreLayout = (props) => {
  const drawerOpen = useSelector(
    (state) => state.productsReducer.drawerOpening
  );

  const authed =
    useSelector((state) => state.authReducer.authed) ||
    storageService.getJWTToken();

  return (
    <>
      <main
        className={
          drawerOpen && storageService.getJWTToken()
            ? "drawer-close-handler drawer-open-handler"
            : "drawer-close-handler"
        }
      >
        {props.children}
      </main>
      {authed && <SideDrawer />}
    </>
  );
};
