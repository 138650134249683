import React, { useEffect, useState } from "react";
import {
  componentValidationSchema,
  modelValidationSchema,
} from "../../validationSchemas/addProductsValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import dataService from "../../services/dataService";
import * as productsActionsCreator from "../../redux/actionsCreator/productActionsCreator";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Spinner } from "../../components/ui/loader";
import { AddProductForm } from "../../components/form/addProductForm";
import { toast } from "react-toastify";
import { routesPath } from "../../constants/routesPath";
import storageService from "../../services/storageService";

export const AddProductsContainer = (props) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const history = useHistory();

  const [productType, setProductType] = useState("MODEL");

  const applications = useSelector(
    (state) => state.productsReducer.application
  );
  const models = useSelector((state) => state.productsReducer.models);
  const subCategories = useSelector(
    (state) => state.productsReducer.subCategories
  );
  const addProductResponse = useSelector(
    (state) => state.productsReducer.addProductResponse
  );
  const addProductSuccess = useSelector(
    (state) => state.productsReducer.addProductSuccess
  );

  const equipments = useSelector((state) => state.productsReducer.equipments);
  const toEditProduct = useSelector(
    (state) => state.productsReducer.toEditProduct
  );
  const toEditProductLoading = useSelector(
    (state) => state.productsReducer.toEditProductLoading
  );
  const addProductloading = useSelector(
    (state) => state.productsReducer.loading
  );
  const toEditProductError = useSelector(
    (state) => state.productsReducer.toEditProductError
  );
  const addProductError = useSelector(
    (state) => state.productsReducer.addProductError
  );
  const [parsedApplications, setParsedApplications] = useState(null);
  const [parsedsubCategory, setParsedSubCategory] = useState(null);
  const [parsedEquipment, setParsedEquipment] = useState(null);
  const [parsedModels, setParsedModels] = useState(null);

  useEffect(() => {
    if (params?.id) {
      dispatch(productsActionsCreator.getProduct(params.id));
    }
    dispatch(productsActionsCreator.getApplication());
    dispatch(productsActionsCreator.getModels());
    dispatch(productsActionsCreator.getSubCategories());
    dispatch(productsActionsCreator.getEquipment());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(productsActionsCreator.removeProduct());
      dispatch(productsActionsCreator.resetInventoryProductPatch());
    };
  }, []);

  useEffect(() => {
    let error = toEditProductError || addProductError;
    if (error) {
      toast.error(error);
    }
  }, [addProductError, toEditProductError]);

  useEffect(() => {
    if (addProductResponse) {
      if (addProductSuccess) {
        toast.success("Product Added!");
      } else {
        toast.success("Product Modified!");
      }
      if (addProductResponse?.tenant_status) {
        history.push(routesPath.tenantProduct);
      } else {
        history.push(routesPath.products);
      }
    }
  }, [addProductResponse, addProductSuccess]);

  useEffect(() => {
    if (toEditProduct) {
      setProductType(toEditProduct.type);
    }
  }, [toEditProduct]);

  useEffect(() => {
    if (applications) {
      let result = dataService.parseaddProductsDataForReactSelect(applications);
      setParsedApplications(result);
    }
    if (subCategories) {
      let result =
        dataService.parseaddProductsDataForReactSelect(subCategories);
      setParsedSubCategory(result);
    }
    if (models) {
      let result = dataService.parseaddProductsDataForReactSelect(models);
      setParsedModels(result);
    }
    if (equipments) {
      let result = dataService.parseaddProductsDataForReactSelect(equipments);
      setParsedEquipment(result);
    }
  }, [applications, subCategories, equipments, models]);

  const submitForm = (data) => {
    data.type = productType;
    if (params?.id) {
      if (data.type === "MODEL") {
        dispatch(
          productsActionsCreator.patchModel({
            model: data,
            id: params.id,
          })
        );
      } else {
        dispatch(
          productsActionsCreator.patchComponent({
            component: data,
            id: params.id,
          })
        );
      }
    } else {
      if (data.type === "MODEL") {
        dispatch(productsActionsCreator.addModels(data));
      } else {
        dispatch(productsActionsCreator.addComponents(data));
      }
    }
  };

  if (toEditProductLoading) {
    return (
      <div className="mt-5 pt-5">
        <Spinner height="100" width="100" />
      </div>
    );
  }

  return (
    <>
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1>Add Product</h1>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          {(toEditProduct || !params?.id) && (
            <div className="add-product-form-container-parent">
              <ul
                className={`nav nav-tabs my-3 pt-3 m-auto mx-3 ${
                  !params?.id ? "" : "disable-types"
                }`}
              >
                <li
                  className="nav-item product-form-li"
                  onClick={() => setProductType("MODEL")}
                >
                  <span
                    className={
                      productType === "MODEL" ? "nav-link active" : "nav-link"
                    }
                  >
                    MODEL
                  </span>
                </li>
                <li
                  className="nav-item product-form-li"
                  onClick={() => setProductType("COMPONENT")}
                >
                  <span
                    className={
                      productType === "COMPONENT"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    COMPONENT
                  </span>
                </li>
              </ul>
              <AddProductForm
                parsedsubCategory={parsedsubCategory}
                parsedModels={parsedModels}
                parsedApplications={parsedApplications}
                parsedEquipment={parsedEquipment}
                data={toEditProduct}
                yupSchema={
                  productType === "MODEL"
                    ? modelValidationSchema
                    : componentValidationSchema
                }
                productType={productType}
                submitForm={submitForm}
                loading={addProductloading}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
