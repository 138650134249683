import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import * as paymentActionsCreator from "../../redux/actionsCreator/paymentActionsCreator";
import { Spinner } from "../ui/loader";
import storageService from "../../services/storageService";
import { toast } from "react-toastify";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      marginBottom: "3px",
      marginTop: "3px",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const AddCardModal = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [confirmCardLoader, setConfirmCardLoader] = useState(false);
  const [confirmCardSuccess, setConfirmCardSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  const setupIntentResponse = useSelector(
    (state) => state.paymentReducer.setupIntentResponse
  );
  const setupIntentLoading = useSelector(
    (state) => state.paymentReducer.setupIntentLoading
  );
  const setupIntentError = useSelector(
    (state) => state.paymentReducer.setupIntentError
  );

  useEffect(() => {
    dispatch(paymentActionsCreator.createSetupIntent());
  }, []);

  useEffect(() => {
    if (setupIntentResponse) {
      setClientSecret(setupIntentResponse.client_secret);
    }
  }, [setupIntentResponse]);

  useEffect(() => {
    if (setupIntentError) {
      setClientSecret(null);
      toast.error(setupIntentError);
    }
  }, [setupIntentError]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setConfirmCardLoader(true);
    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: storageService.getUserEmail(),
        },
      },
    });

    if (result.error) {
      setConfirmCardLoader(false);
      setConfirmCardSuccess(false);
      toast.error(result.error);
    } else {
      setConfirmCardLoader(false);
      setConfirmCardSuccess(true);

      toast.success("Card Attached Successfully!");
      dispatch(paymentActionsCreator.getPaymentMethods());
      props.setInitCard(false);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.initCard}
        onClose={() => props.setInitCard(null)}
        aria-labelledby="responsive-dialog-title"
        className="charge-payment-modal"
      >
        <DialogTitle
          className="charge-payment-modal-title"
          id="responsive-dialog-title"
        >
          Add Card
        </DialogTitle>
        <hr />

        <DialogContent className="charge-payment-modal-content">
          <DialogContentText className="charge-payment-modal-text pt-3">
            {setupIntentLoading && (
              <div className="text-center pt-3">
                <Spinner height={100} width={100} />
              </div>
            )}
            {setupIntentResponse ? (
              <>
                <div className="row">
                  <div className="col">
                    {confirmCardLoader && (
                      <div className="text-center my-3 pt-5">
                        <Spinner height={50} width={50} />
                      </div>
                    )}
                    {!confirmCardSuccess ? (
                      <form
                        onSubmit={handleSubmit}
                        className={
                          confirmCardLoader ? "d-none" : " d-block mt-2"
                        }
                      >
                        <div className="col d-flex flex-column ">
                          <label>
                            <div className="text-muted">
                              <h3 className="mb-3">
                                <strong>CARD DETAILS</strong>
                              </h3>
                            </div>
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                          </label>
                          <button
                            type="submit"
                            className="btn green-btn mt-4"
                            disabled={!stripe}
                            style={{ width: "100px" }}
                          >
                            Attach
                          </button>
                        </div>
                      </form>
                    ) : (
                      confirmCardSuccess &&
                      !confirmCardLoader && (
                        <div className="success-payment text-center mt-3">
                          <h2 style={{ color: "#222F8E" }}>
                            Card Attached Successfully!
                          </h2>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            ) : (
              setupIntentError && (
                <div className="text-center">
                  <h6>Failed to Attach Card!</h6>
                </div>
              )
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
