import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const ConfirmationDailoge = (props) => {
  const { open, setOpen, setConfirm, primaryText, secondaryText } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="lg"
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{primaryText}</DialogTitle>
        <DialogContent>
          <DialogContentText>{secondaryText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpen(false);
              setConfirm(false);
            }}
          >
            Disagree
          </Button>
          <Button
            onClick={() => {
              setConfirm(open);
              setOpen(false);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
