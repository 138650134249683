import React from "react";
export const AddProductIcon = () => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512.017 512.017"
    height="20"
    viewBox="0 0 512.017 512.017"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m244.072 126.978v-126.978h-66.498v126.978l33.249-14.716z" />
      <path d="m.008 0h147.565v49.332h-147.565z" />
      <path d="m287.008 399.517c0 62.033 50.468 112.5 112.5 112.5s112.5-50.467 112.5-112.5-50.468-112.5-112.5-112.5-112.5 50.467-112.5 112.5zm182.601-15v30h-55.101v55.101h-30v-55.101h-55.101v-30h55.101v-55.101h30v55.101z" />
      <path d="m257.008 399.517c0-78.575 63.925-142.5 142.5-142.5 6.924 0 13.731.507 20.394 1.465v-179.15h-145.83v93.73l-63.249-27.994-63.249 27.994v-93.73h-147.566v338.826h258.22c-.801-6.102-1.22-12.324-1.22-18.641zm-142.75-30.691h-64.199v-30h64.199zm32.166-64.332h-96.365v-30h96.365z" />
      <path d="m274.072 0h145.83v49.332h-145.83z" />
    </g>
  </svg>
);
