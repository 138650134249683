import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import APIs from "../../services/httpService";
import { apiUrls } from "../../constants/apiUrls";
import { authHeader } from "../../constants/authenticationHeader";
import errorhandler from "../../services/errorhandler";

//get Application

export const getApplication = createAsyncThunk(
  "products/getApplication",
  async (data = {}) => {
    data.path = apiUrls.getApplication();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

//get SubCategories

export const getSubCategories = createAsyncThunk(
  "products/getSubCategories",
  async (data = {}) => {
    data.path = apiUrls.getSubCategories();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

//get Models

export const getModels = createAsyncThunk(
  "products/getModels",
  async (data = {}) => {
    data.path = apiUrls.getModels();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

//get Products

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (data = {}) => {
    let getProductsData = {};
    getProductsData.path = apiUrls.getProducts(data);
    const response = await APIs.fetch(getProductsData);
    return response.data;
  }
);

//Add components

export const addComponents = createAsyncThunk(
  "products/addComponents",
  async (data = {}, { rejectWithValue }) => {
    let addComponentData = {};
    addComponentData.data = data;
    addComponentData.path = apiUrls.addComponents();
    addComponentData.csrf = authHeader();

    try {
      const response = await APIs.post(addComponentData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

//get Equipment

export const getEquipment = createAsyncThunk(
  "products/getEquipment",
  async (data = {}) => {
    data.path = apiUrls.getEquipment();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

//add models

export const addModels = createAsyncThunk(
  "products/addModels",
  async (data, { rejectWithValue }) => {
    let addModelData = {};
    addModelData.data = data;
    addModelData.csrf = authHeader();
    addModelData.path = apiUrls.addModels();

    try {
      const response = await APIs.post(addModelData);

      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

//delete models

export const deleteModel = createAsyncThunk(
  "products/deletModel",
  async (data, { rejectWithValue }) => {
    let deleteModelData = {};
    deleteModelData.data = data.data;
    deleteModelData.csrf = authHeader();
    deleteModelData.path = apiUrls.deleteModel(data.data);

    try {
      const response = await APIs.dell(deleteModelData);
      return {
        response: response.data,
        products: data.products,
        productIndex: data.productIndex,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

//add models

export const deleteComponent = createAsyncThunk(
  "products/deleteComponent",
  async (data) => {
    let deleteComponentData = {};
    deleteComponentData.data = data.data;
    deleteComponentData.csrf = authHeader();
    deleteComponentData.path = apiUrls.deleteComponent(data.data);
    const response = await APIs.dell(deleteComponentData);
    return {
      response: response.data,
      products: data.products,
      productIndex: data.productIndex,
    };
  }
);

//patch Model

export const patchModel = createAsyncThunk(
  "products/patchModel",
  async (data, { rejectWithValue }) => {
    let patchModelData = {};
    patchModelData.data = data.model;
    patchModelData.csrf = authHeader();
    patchModelData.path = apiUrls.patchModel(data.id);

    try {
      const response = await APIs.patch(patchModelData);
      return {
        response: response.data,
        productList: data.productList,
        index: data.index,
        model: data.model,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

//patch Component

export const patchComponent = createAsyncThunk(
  "products/patchComponent",
  async (data, { rejectWithValue }) => {
    let patchComponentData = {};
    patchComponentData.data = data.component;
    patchComponentData.csrf = authHeader();
    patchComponentData.path = apiUrls.patchComponent(data.id);

    try {
      const response = await APIs.patch(patchComponentData);

      return {
        response: response.data,
        productList: data.productList,
        index: data.index,
        component: data.component,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

//get Product

export const getProduct = createAsyncThunk(
  "products/getProduct",
  async (data) => {
    let getProductData = {};
    getProductData.data = data;
    getProductData.csrf = authHeader();
    getProductData.path = apiUrls.getProduct(data);
    const response = await APIs.fetch(getProductData);
    return response;
  }
);

export const removeProduct = createAction("products/removeProduct");

//add Images localy

export const addImages = createAction(
  "products/addImgaes",
  function prepare(data) {
    return {
      payload: data,
    };
  }
);

// control dashboard drawer opening

export const drawerHandler = createAction("products/drawer");
//Reset addproductQuanitty in inventory response

export const resetInventoryProductPatch = createAction(
  "products/editProductReset"
);
//Reset product data

export const resetProductData = createAction("products/productReset");
