import React, { forwardRef, useEffect } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import BuildIcon from "@mui/icons-material/Build";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Spinner } from "../loader";
import MaterialTable, { MTableToolbar } from "material-table";
import { routesPath } from "../../../constants/routesPath";
import { useHistory } from "react-router-dom";

import "./table.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  BuildIcon: forwardRef((props, ref) => <BuildIcon {...props} ref={ref} />),
};

export const DataTable = (props) => {
  const { tableHeader, tableData } = props?.data;
  const {
    isActions,
    isEditProduct,
    isProductDetail,
    loading,
    isDetailPanel,
    navigateToEditQuoes,
  } = props;
  const history = useHistory();

  return (
    <>
      <div className="materialTable-wrap">
        {tableData && !loading ? (
          <MaterialTable
            style={{ border: "none" }}
            detailPanel={[
              {
                icon: () => (isDetailPanel ? <ChevronRight /> : null),
                render: (rowData) =>
                  isDetailPanel && props.getDetailPanel(rowData),
              },
            ]}
            editable={{
              onRowUpdate: async (newData) => {
                await props.setFields(
                  newData
                  // history.push(routesPath.profile)
                );
              },

              isEditHidden: (rowData) =>
                !window.location.pathname.includes("/inventory") &&
                !window.location.pathname.includes("/quotes"),
            }}
            actions={[
              (rowData) => ({
                icon: tableIcons.Delete,
                tooltip: "Delete product",
                onClick: (event, rowData) =>
                  props.deleteProduct(event, rowData),
                hidden:
                  !window.location.pathname.includes("/products") &&
                  !window.location.pathname.includes("/tenant-products") &&
                  !window.location.pathname.includes("/quotes"),
                // &&
                // !window.location.pathname.includes(`/editQuote`),
              }),
              (rowData) => ({
                icon: tableIcons.BuildIcon,
                tooltip: "Edit Quote",
                onClick: (event, rowData) => {
                  props.editQuotes && props.editQuotes(rowData);
                },

                hidden: !window.location.pathname.includes("/quotes"),
              }),
              (rowData) => ({
                icon: tableIcons.Edit,
                tooltip: "Edit product",
                onClick: (event, rowData) => props.setFields(event, rowData),
                hidden:
                  !window.location.pathname.includes("/products") &&
                  !window.location.pathname.includes("/tenant-products"),
              }),
              // (rowData) => ({
              //   icon: tableIcons.Edit,
              //   tooltip: "Edit product",
              //   onClick: (event, rowData) => props.setFields(event, rowData),
              //   hidden: !window.location.pathname.includes("/inventory"),
              // }),
            ]}
            localization={{
              header: !isActions
                ? {
                    actions: "",
                  }
                : "",
            }}
            options={{
              showTitle: false,
              actionsColumnIndex: -1,
              toolbarButtonAlignment: "left",
              columnResizable: true,
              // paging: false,
              maxBodyHeight: "500px",
              pageSize: 10,

              detailPanelColumnStyle: !isDetailPanel
                ? {
                    display: "none",
                  }
                : {
                    border: "none",
                  },
            }}
            components={{
              Toolbar: (tooolbarProps) => {
                return (
                  <div className="mb-3">
                    <label
                      onClick={props.toolbarAction}
                      className={
                        isEditProduct
                          ? "btn btn-outline-secondary px-4 mt-3 d-inline"
                          : "d-none"
                      }
                      style={{
                        marginLeft: "20px",
                        display: "table",
                        cursor: "pointer",
                        float: "left",
                      }}
                    >
                      <i className="fas fa-plus" />
                      &nbsp; {props.name}
                    </label>
                    <div className="d-inline mb-5 pb-5">
                      <MTableToolbar {...tooolbarProps} />
                    </div>
                  </div>
                );
              },
            }}
            onRowClick={(e, rowData) =>
              isProductDetail ? props.clicked(rowData) : null
            }
            icons={tableIcons}
            columns={tableHeader.map((headData) => {
              return {
                title: headData,
                field: headData,
                type: headData === "DOWN_PAYMENT" ? "numeric" : "",
                validate: (rowData) =>
                  +rowData[headData] < 1
                    ? "please enter a valid down payment"
                    : "",
                render: (rowData) => <>{rowData[headData]}</>,

                editable:
                  headData === "DOWN_PAYMENT" || headData === "QUANTITY"
                    ? "onUpdate"
                    : "never",
              };
            })}
            data={tableData}
          />
        ) : (
          loading && (
            <div className="text-center " style={{ padding: "20%" }}>
              <Spinner height={100} width={100} />
            </div>
          )
        )}
      </div>
    </>
  );
};
