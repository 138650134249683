import _ from "lodash";
import quoteStatus from "../constants/quoteStatus";
import storageService from "./storageService";
import { decryptedPrice } from "../utils/decode";
import noImg from "../assets/img/noImg.jpg";

const parseQuotesTable = (
  tableData,
  getTransitionsDropdown,
  getQuoteItems,
  GetQuoteActions,
  getStatus
) => {
  let parsedTable = {};

  const calculatePrice = (subQuotes) => {
    let price = 0;
    subQuotes.map((subQuote) => {
      price += decryptedPrice(subQuote.total_price);
    });
    return price;
  };

  parsedTable.tableHeader = [
    "#",
    "PRICE",
    "STATUS",
    "ITEMS",
    "ACTIONS",
    "DOWN_PAYMENT",
    "CREATED_AT",
    "EXPIRES_AT",
    "TRANSITIONS",
  ];
  parsedTable.tableData = [];
  tableData.map((quote, quoteIndex) => {
    if (!quoteStatus[quote.status][`${storageService.getUserRole()}_hide`]) {
      parsedTable.tableData.push({
        "#": quote.id,
        PRICE: "$" + calculatePrice(quote.subquotes).toFixed(2),
        STATUS: getStatus(quote),
        ITEMS: getQuoteItems(quote),
        DOWN_PAYMENT: quote.down_payment + "%",
        CREATED_AT: new Date(quote.created).toUTCString(),
        EXPIRES_AT: new Date(quote.expires_at).toUTCString(),
        ACTIONS: GetQuoteActions(quote),
        TRANSITIONS: getTransitionsDropdown(quote, quoteIndex),
      });
    }
  });

  return parsedTable;
};

//sub Quotes parser

const parseSubQuoteTable = (
  tableData,
  getSubQuoteItems,
  getChat,
  getStatus,
  getTransitionsDropdown
) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "PRICE",
    "STATUS",
    "ITEMS",
    "ACTIONS",
    "TRANSITIONS",
  ];
  parsedTable.tableData = [];
  tableData.map((subQuote, subQuoteIndex) => {
    if (!quoteStatus[subQuote.status][`${storageService.getUserRole()}_hide`]) {
      if (
        !(
          (subQuote.status === "ADMIN_APPROVED" || subQuote.status === "NEW") &&
          storageService.getUserRole() === "VENDOR"
        )
      ) {
        parsedTable.tableData.push({
          "#": subQuote.id,
          STATUS: getStatus(subQuote),
          PRICE: "$" + decryptedPrice(subQuote.total_price).toFixed(2),
          ITEMS: getSubQuoteItems(subQuote),
          ACTIONS: getChat(subQuote),
          TRANSITIONS: getTransitionsDropdown(subQuote, subQuoteIndex),
        });
      }
    }
  });

  return parsedTable;
};

//parse orders table

const parseOrdersTable = (tableData, getOrderItems, getChat) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "TOTAL_PRICE",
    "PAID_AMOUNT",
    "SHIPMENT_AMOUNT",
    "PENDING_AMOUNT",
    "ITEMS",
    "ACTIONS",
  ];
  parsedTable.tableData = [];
  tableData.map((order) => {
    parsedTable.tableData.push({
      "#": order.id,
      TOTAL_PRICE: "$" + parseFloat(order.total_amount).toFixed(2),
      PENDING_AMOUNT: "$" + order.pending_amount.toFixed(2),
      SHIPMENT_AMOUNT: "$" + order.shipment_amount.toFixed(2),
      PAID_AMOUNT: "$" + order.paid_amount.toFixed(2),
      ITEMS: getOrderItems(order),
      ACTIONS: getChat(order),
    });
  });

  return parsedTable;
};

//parse  sub orders table

const parseSubOrdersTable = (
  tableData,
  getSubOrderItems,
  getSubOrdersActions
) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "PRICE",
    "ITEMS",
    "TRACK",
    "TRANSFER_STATUS",
    "TRANSFER_ID",
    "ACTIONS",
  ];
  parsedTable.tableData = [];
  tableData.map((subOrder) => {
    parsedTable.tableData.push({
      "#": subOrder.id,
      PRICE: "$" + subOrder.amount.toFixed(2),
      ITEMS: getSubOrderItems(subOrder),
      TRACK: (
        <a href={subOrder.tracking_url} className="text-dark" target="_blank">
          {subOrder.tracking_url.slice(0, 30).concat("....")}
        </a>
      ),
      TRANSFER_STATUS: subOrder.transfer_status,
      TRANSFER_ID: subOrder.transfer_id,
      ACTIONS: getSubOrdersActions(subOrder),
    });
  });

  return parsedTable;
};

const parseInventoryTable = (tableData) => {
  let parsedTable = {};

  parsedTable.tableHeader = ["#", "SKU", "TYPE", "QUANTITY"];
  parsedTable.tableData = [];
  tableData.map((product) => {
    parsedTable.tableData.push({
      "#": product.id,
      SKU: product.sku,
      TYPE: product.type,
      QUANTITY: product.quantity,
    });
  });

  return parsedTable;
};

//parse products table

const parseProductsTable = (tableData) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "NAME",
    "SKU",
    "TYPE",
    "LENGTH",
    "WIDTH",
    "HEIGHT",
    "WEIGHT",
    "QUANTITY",
  ];
  parsedTable.tableData = [];
  tableData.map((product) => {
    if (product.tenant != storageService.getTenantId()) {
      parsedTable.tableData.push({
        "#": product.id,
        SKU: product.sku,
        NAME: product.data?.name,
        TYPE: product.type,
        LENGTH: product.length,
        WIDTH: product.width,
        HEIGHT: product.height,
        WEIGHT: product.weight,
        QUANTITY: product.quantity,
      });
    }
  });

  return parsedTable;
};

// parse tenant products table

const parseTenantProductsTable = (tableData) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "NAME",
    "SKU",
    "TYPE",
    "LENGTH",
    "WIDTH",
    "HEIGHT",
    "WEIGHT",
    "QUANTITY",
  ];
  parsedTable.tableData = [];
  tableData.map((product) => {
    parsedTable.tableData.push({
      "#": product.id,
      SKU: product.sku,
      NAME: product.data?.name,
      TYPE: product.type,
      LENGTH: product.length,
      WIDTH: product.width,
      HEIGHT: product.height,
      WEIGHT: product.weight,
      QUANTITY: product.quantity,
    });
  });

  return parsedTable;
};

const parseaddProductsDataForReactSelect = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.name || dat.data.name });
  });

  return dataOptions;
};

const parseAllUserData = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.username });
  });

  return dataOptions;
};

const parseFormDataForProductEdit = (data) => {
  if (!data) {
    return {};
  }
  let dataApplication = [];
  let parsedData = {};
  const dataEquipment = data.data.equipment?.id;
  const dataSubcategory = data.data.component_subcategory?.id;
  let dataModel = [];

  dataApplication = data.data.application.map((application) => application.id);

  if (data.type === "COMPONENT") {
    dataModel = data.data.model.map((mod) => mod.id);
  }

  let images = data.data?.images || [];
  images = images.map((image) => {
    return { ...image, image: image?.image?.replace("image/upload/", "") };
  });

  if (data.type === "MODEL") {
    parsedData = {
      name: data.data?.name,
      sku: data.sku,
      quantity: data.quantity,
      height: data.height,
      width: data.width,
      length: data.length,
      weight: data.weight,
      application: dataApplication,
      equipment: dataEquipment,
      images: images,
      tenant_status: data.tenant == storageService.getTenantId(),
    };
  } else {
    parsedData = {
      name: data.data?.name,
      sku: data.sku,
      quantity: data.quantity,
      height: data.height,
      width: data.width,
      length: data.length,
      weight: data.weight,
      application: dataApplication,
      component_subcategory: dataSubcategory,
      model: dataModel,
      images: images,
      tenant_status: data.tenant == storageService.getTenantId(),
    };
  }

  return parsedData;
};

//parse subquote items

const parseSubQuoteItems = (subQuote) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "SKU",
    "NAME",
    "TYPE",
    "QUANTITY",
    "UNIT_PRICE",
    "TOTAL_PRICE",
  ];
  parsedTable.tableData = [];

  subQuote?.quote_items?.map((product) => {
    parsedTable.tableData.push({
      "#": product.id,
      SKU: product.product?.sku,
      NAME: product.product?.data?.name,
      TYPE: product.product?.type,
      QUANTITY: product.quantity,
      UNIT_PRICE: "$" + decryptedPrice(product.price),
      TOTAL_PRICE: "$" + decryptedPrice(product.price) * product.quantity,
    });
  });

  return parsedTable;
};

//parse suborders items

const parseSubOrderItems = (subOrder) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "SKU",
    "NAME",
    "TYPE",
    "QUANTITY",
    "UNIT_PRICE",
    "TOTAL_PRICE",
  ];
  parsedTable.tableData = [];

  subOrder.quote_items.map((product, key) => {
    parsedTable.tableData.push({
      "#": product.id,
      SKU: product.product?.sku,
      NAME: product.product?.data?.name,
      TYPE: product.product?.type,
      QUANTITY: product.quantity,
      UNIT_PRICE: "$" + decryptedPrice(product.price).toFixed(2).toFixed(2),
      TOTAL_PRICE:
        "$" + (decryptedPrice(product.price) * product.quantity).toFixed(2),
    });
  });

  return parsedTable;
};

//parse product data for modal

const parseProductModalData = (productData) => {
  let productImages = [];

  if (productData.data?.images.length) {
    productImages = productData.data.images.map((image) => {
      return image.image;
    });
  } else {
    productImages.push(noImg);
  }

  let parsedProduct = {
    id: productData.id,
    sku: productData.sku,
    name: productData.data?.name,
    vendor: productData.data?.vendor?.username,
    price: productData.data?.price,
    quantity: productData.quantity,
    description: productData.data?.description,
    length: productData.length,
    width: productData.width,
    height: productData.height,
    weight: productData.weight,
    images: productImages,
  };

  return parsedProduct;
};

//parse quote product data for modal

const parseQuoteModalData = (productData) => {
  let productImages = [];

  if (productData.product?.data?.images.length) {
    productImages = productData.product.data.images.map((image) => {
      return image.image;
    });
  } else {
    productImages.push(noImg);
  }

  let parsedProduct = {
    subQuoteId: productData.subquote,
    id: productData.id,
    sku: productData.product?.sku,
    name: productData.product?.data?.name,
    vendor: productData.product?.data?.vendor?.username,
    price: decryptedPrice(productData.price),
    quantity: productData.quantity,
    description: productData.product?.description,
    length: productData.product?.length,
    width: productData.product?.width,
    height: productData.product?.height,
    weight: productData.product?.weight,
    images: productImages,
    productId: productData.product?.id,
  };

  return parsedProduct;
};

//parse tenat quotes

const parseTenantQuotes = (
  tableData,
  getTransitionsDropdown,
  getQuoteItems,
  GetQuoteActions,
  getStatus
) => {
  let parsedTable = {};

  const calculatePrice = (subQuotes) => {
    let price = 0;
    subQuotes.map((subQuote) => {
      price += decryptedPrice(subQuote.total_price);
    });
    return price;
  };

  parsedTable.tableHeader = [
    "#",
    "PRICE",
    "STATUS",
    "ITEMS",
    "ACTIONS",
    "CREATED_AT",
    "EXPIRES_AT",
    "TRANSITIONS",
  ];
  parsedTable.tableData = [];
  tableData.map((quote, quoteIndex) => {
    if (!quoteStatus[quote.status][`${storageService.getUserRole()}_hide`]) {
      parsedTable.tableData.push({
        "#": quote.id,
        PRICE: "$" + calculatePrice(quote.subquotes),
        STATUS: getStatus(quote),
        ITEMS: getQuoteItems(quote),
        CREATED_AT: new Date(quote.created).toUTCString(),
        EXPIRES_AT: new Date(quote.expires_at).toUTCString(),
        ACTIONS: GetQuoteActions(quote),
        TRANSITIONS: getTransitionsDropdown(quote, quoteIndex),
      });
    }
  });

  return parsedTable;
};

//parse quote items

const parseTenantQuoteItems = (quote) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    "#",
    "SKU",
    "NAME",
    "TYPE",
    "QUANTITY",
    "UNIT_PRICE",
    "TOTAL_PRICE",
  ];
  parsedTable.tableData = [];

  quote.subquotes.map((subQuote) => {
    subQuote.quote_items.map((item) => {
      parsedTable.tableData.push({
        "#": item.id,
        SKU: item.product?.sku,
        NAME: item.product?.data?.name,
        TYPE: item.product?.type,
        QUANTITY: item.quantity,
        UNIT_PRICE: "$" + decryptedPrice(item.price),
        TOTAL_PRICE: "$" + decryptedPrice(item.price) * item.quantity,
      });
    });
  });

  return parsedTable;
};

//parse quote items

const parseTenantOrdersItems = (order) => {
  let parsedTable = {};

  parsedTable.tableHeader = ["#", "SKU", "NAME", "TYPE", "QUANTITY", "PRICE"];
  parsedTable.tableData = [];

  order.suborders.map((subQuote) => {
    subQuote.sub_quote.quote_items.map((item) => {
      parsedTable.tableData.push({
        "#": item.id,
        SKU: item.product?.sku,
        NAME: item.product?.data?.name,
        TYPE: item.product?.type,
        QUANTITY: item.quantity,
        PRICE: "$" + decryptedPrice(item.price),
      });
    });
  });

  return parsedTable;
};

//parse tenant order modal data

const parseTenantQuoteProductModalData = (productData) => {
  let productImages = [];

  if (productData?.product?.data?.images.length) {
    productImages = productData.product.data.images.map((image) => {
      return image.image;
    });
  } else {
    productImages.push(noImg);
  }

  let parsedProduct = {
    id: productData.id,
    sku: productData.product.data.sku,
    name: productData.product.data.name,
    vendor: productData.product.data?.vendor?.username,
    price: decryptedPrice(productData.price),
    quantity: productData.quantity,
    description: productData.product.data?.description,
    length: productData.product.data.length,
    width: productData.product.data.width,
    height: productData.product.data.height,
    weight: productData.product.data.weight,
    images: productImages,
  };

  return parsedProduct;
};

//parse tenant order modal data

const parseTenantOrderProductModalData = (productData) => {
  let productImages = [];

  if (productData?.product?.data?.images.length) {
    productImages = productData.product.data.images.map((image) => {
      return image.image;
    });
  } else {
    productImages.push(noImg);
  }

  let parsedProduct = {
    id: productData.id,
    sku: productData.product.data.sku,
    name: productData.product.data.name,
    vendor: productData.product.data?.vendor?.username,
    price: decryptedPrice(productData.price),
    quantity: productData.quantity,
    description: productData.product.data?.description,
    length: productData.product.data.length,
    width: productData.product.data.width,
    height: productData.product.data.height,
    weight: productData.product.data.weight,
    images: productImages,
  };

  return parsedProduct;
};

// add Quote Parser

const parseAddQuote = (values) => {
  let parsedData = {};
  parsedData.user_id = values?.user_id;
  parsedData.products = [];

  values?.products?.map((val) => {
    parsedData.products.push({
      id: val?.id,
      quantity: val?.quantity,
    });
  });

  return parsedData;
};

export default {
  parseQuotesTable,
  parseSubQuoteTable,
  parseOrdersTable,
  parseSubOrdersTable,
  parseInventoryTable,
  parseProductsTable,
  parseaddProductsDataForReactSelect,
  parseFormDataForProductEdit,
  parseSubQuoteItems,
  parseSubOrderItems,
  parseProductModalData,
  parseQuoteModalData,
  parseTenantQuotes,
  parseTenantQuoteItems,
  parseTenantOrdersItems,
  parseTenantQuoteProductModalData,
  parseTenantOrderProductModalData,
  parseTenantProductsTable,
  parseAddQuote,
  parseAllUserData,
};
