import React, { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { fileUpload } from "../../utils/fileUpload";
import { Spinner } from "../ui/loader";

export const FileUploader = (props) => {
  const { name, label, type, sku, labelText, required } = props;

  const {
    register,
    formState: { errors },
    unregister,
    setValue,
    trigger,
  } = useFormContext();

  const originalValue = useWatch({ name: name }) || "";
  const [loading, setLoading] = useState(false);
  const fileInput = useRef();

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  const handleChange = async (event) => {
    const files = event.target.files;

    let result = trigger();

    if (!result) {
      return;
    }

    if (!files || !files.length) {
      return;
    }

    setLoading(true);
    const file = await fileUpload(files[0], sku);

    if (file?.secure_url) {
      const url = file.secure_url;
      setValue(
        name,

        url,

        { shouldValidate: true }
      );
    } else {
      alert(file);
    }
    fileInput.current.value = "";
    setLoading(false);
  };

  return (
    <>
      <div className="my-2" style={{ clear: "both", textAlign: "left" }}>
        <label className="col-form-label  is-invalid d-block" htmlFor={name}>
          {required && <small className="text-danger">* </small>}
          {labelText}
        </label>
        <label
          className={
            loading
              ? "btn btn-outline-secondary px-4 mb-2 disable-uploader"
              : "btn btn-outline-secondary px-4 mb-2"
          }
        >
          {loading ? (
            <Spinner height={20} width={20} />
          ) : (
            <i className="fas fa-plus" />
          )}
          &nbsp; Upload
          <input
            className="d-none"
            disabled={loading}
            accept={`.${name}`}
            type="file"
            onChange={handleChange}
            ref={fileInput}
          />
        </label>
        {originalValue && (
          <div className="my-2 bold">
            <span> Attachment : </span>
            <a target="_blank" href={originalValue}>
              {originalValue.slice(0, 36).concat("....")}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

{
  /* <div className="img-buttons rounded-bottom">
                 <Button
                   type="button"
                   className="btn-link"
                   onClick={() => doPreviewImage(item)}
                 >
                   <ButtonIcon className="fas fa-search" />
                 </Button>
                 {!readOnly && (
                   <Button
                     type="button"
                     className="btn-link ml-2"
                     onClick={() => handleRemove(item.id)}
                   >
                     <ButtonIcon className="fas fa-times" />
                   </Button>
                 )}
               </div> */
}
