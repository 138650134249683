import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Badge,
} from "reactstrap";
import { DataTable } from "../../components/ui/tables/dataTable";
import _ from "lodash";
import { PubnubChat } from "../../components/chat/chat";
import * as quoteAcionsCreator from "../../redux/actionsCreator/quotesActionsCreator";
import { useHistory } from "react-router";
import InitializePubnub from "../../components/initializePubnub/initializePubnub";
import { toast } from "react-toastify";
import dataService from "../../services/dataService";
import { Spinner } from "../../components/ui/loader";
import { routesPath } from "../../constants/routesPath";
import quoteStatus from "../../constants/quoteStatus";
import storageService from "../../services/storageService";
import { SubDataTable } from "../../components/ui/tables/subDataTable";
import { ProductDetailModal } from "../../components/productDetailModal/productDetailModal";
import { decryptedPrice } from "../../utils/decode";

export const TenantQuotesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let quotes = useSelector((state) => state.quotesReducer.quotes);
  const loading = useSelector((state) => state.quotesReducer.loading);
  const [currentChannel, setCurrentChannel] = useState(``);
  const [openChat, setOpenChat] = useState(false);
  const [parsedQuotes, setParsedQuotes] = useState([]);
  const [quoteItemDownPayment, setQuoteItemDownPayment] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const possibleTransitionsLoader = useSelector(
    (state) => state.quotesReducer.possibleTransitionsLoader
  );
  const possibleTransitions = useSelector(
    (state) => state.quotesReducer.possibleTransitions
  );
  const patchQuoteItemsSuccess = useSelector(
    (state) => state.quotesReducer.patchQuoteItemsSuccess
  );
  const possibleTransitionsError = useSelector(
    (state) => state.quotesReducer.possibleTransitionsError
  );
  const patchDownPaymentResponse = useSelector(
    (state) => state.quotesReducer.patchDownPaymentResponse
  );
  const patchDownPaymentError = useSelector(
    (state) => state.quotesReducer.patchDownPaymentError
  );
  const patchDownPaymentLoading = useSelector(
    (state) => state.quotesReducer.patchDownPaymentLoading
  );

  useEffect(() => {
    const query = createQueryString();
    dispatch(quoteAcionsCreator.getAllQuotes(query));
  }, [patchQuoteItemsSuccess]);

  useEffect(() => {
    return () => {
      dispatch(quoteAcionsCreator.clearTransitions());
    };
  }, []);

  useEffect(() => {
    if (quotes) {
      let result = dataService.parseTenantQuotes(
        quotes,
        getTransitionsDropdown,
        getQuoteItems,
        getChat,
        getStatus
      );

      setParsedQuotes(result);
    }
  }, [
    quotes,
    possibleTransitions,
    quoteItemDownPayment,
    possibleTransitionsLoader,
  ]);

  useEffect(() => {
    if (possibleTransitions) {
      if (possibleTransitions?.status) {
        toast.success(possibleTransitions.status.replaceAll("_", " "));
      } else if (
        !possibleTransitions?.transitions?.length &&
        !possibleTransitions?.status
      ) {
        toast.error("No Available Transitions!");
      }
    }
  }, [possibleTransitions]);

  useEffect(() => {
    patchDownPaymentResponse && toast.success("Down Payment Changed!");
  }, [patchDownPaymentResponse]);

  useEffect(() => {
    patchDownPaymentError && toast.error("Failed To Change Down Payment!");
  }, [patchDownPaymentError]);

  useEffect(() => {
    return () => {
      dispatch(quoteAcionsCreator.clearDownPayment());
      dispatch(quoteAcionsCreator.clearTransitions());
    };
  }, []);

  const createQueryString = () => {
    var query = new URLSearchParams();
    query.append("tenant__id", storageService.getTenantId());

    return query.toString();
  };

  const checkForPrice = (quote) => {
    let isProductPriceNull = false;

    quote.subquotes.map((subQuote) => {
      subQuote.quote_items.find((item) => {
        if (decryptedPrice(item.price) <= 0) {
          isProductPriceNull = true;
        }
      });
    });
    return isProductPriceNull;
  };
  const quoteStatusHandler = (transition, quote, quoteIndex) => {
    if (checkForPrice(quote)) {
      toast.error(`Please Set Price For Product!`);
      return;
    }
    let errorQuotes = [];
    quotes.map((quote, index) => {
      console.log("quote", quote);
      quote.subquotes.map((quote, index) =>
        quote.quote_items.map((item) => {
          if (item.price <= 0 && index === quoteIndex) {
            errorQuotes.push(index);
          }
        })
      );
    });

    if (!errorQuotes.includes(quoteIndex)) {
      const subQuote = {
        quote: quote.quote,
        vendor: quote.vendor,
        subQuoteId: quote.id,
        transition: transition,
        type: "VENDOR",
        productType: "quotes",
      };
      dispatch(quoteAcionsCreator.postpossibleTransitions(subQuote));
    }
  };

  const getQuoteItems = (quote) => {
    return quote.subquotes.map((subQuotes) => {
      return subQuotes.quote_items.map((quoteItem, index) => {
        return (
          <Badge color="badge-dot bg-dark m-1 text-light" key={index}>
            {quoteItem.product.data.name}
          </Badge>
        );
      });
    });
  };

  const getStatus = (quote) => {
    return (
      <Badge className="badge-dot mr-4 bg-dark">
        <span className="status">{quoteStatus[quote.status]?.label}</span>
      </Badge>
    );
  };

  const getChat = (quote) => {
    return (
      <div>
        <div
          className="quotes-action"
          onClick={(e) => {
            e.stopPropagation();
            handleChat(quote.id);
          }}
        >
          <button>Chat</button>
        </div>
      </div>
    );
  };

  const quoteDownPaymentHandler = (quote) => {
    dispatch(
      quoteAcionsCreator.patchDownPaymentForQuote({
        id: quote["#"],
        down_payment: quote.DOWN_PAYMENT,
        quotes: quotes,
      })
    );
  };

  // const checkForPrice = (quote) => {
  //   quote.subquotes.map((subQuote) => {
  //     subQuote.quote_items.find((item) => {
  //       return decryptedPrice(item.price) <= 0;
  //     });
  //   });
  // };
  const getTransitionsDropdown = useCallback(
    (quote, index) => {
      // checkForPrice() && return;
      return (
        <UncontrolledDropdown
          onClick={(e) => {
            e.stopPropagation();
            getpossibleTransitions(quote.id);
          }}
        >
          <DropdownToggle
            caret
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
              fontSize: "10px",
            }}
            className="text-dark"
          >
            Show
          </DropdownToggle>
          <DropdownMenu
            onClick={(e) => {
              e.stopPropagation();
              quoteStatusHandler(e.target.value, quote, index);
            }}
          >
            {possibleTransitionsLoader && (
              <DropdownItem value="Loading" className="text-dark">
                <Spinner height={40} width={40} />
              </DropdownItem>
            )}
            {possibleTransitions?.transitions?.length > 0 &&
              possibleTransitions?.transitions.map((transition, index) => {
                return (
                  <DropdownItem
                    value={transition}
                    key={index}
                    className="text-dark"
                  >
                    {transition.replaceAll("-", " ")}
                  </DropdownItem>
                );
              })}

            {possibleTransitionsError && (
              <DropdownItem disabled value="error" className="text-dark">
                No Transition Available
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
    [possibleTransitions, possibleTransitionsLoader]
  );

  const handleChat = (id) => {
    setCurrentChannel(`Quote-${id}`);
    setOpenChat(true);
  };

  const getpossibleTransitions = (id) => {
    dispatch(
      quoteAcionsCreator.getpossibleTransitions({ type: "USER", id: id })
    );
  };

  const handleProductModalOpening = (product, quoteId) => {
    let selectedQuote = _.find(quotes, { id: quoteId });

    console.log("selectedQuote", selectedQuote);

    selectedQuote.subquotes.map((subQuote) => {
      subQuote.quote_items.find((item) => {
        if (item.id === product["#"]) {
          let result = dataService.parseTenantQuoteProductModalData(item);
          setSelectedProduct(result);
        }
      });
    });
  };

  const getItemsDetail = (quoteData) => {
    let selectedQuote = _.cloneDeep(_.find(quotes, { id: quoteData["#"] }));
    let data = dataService.parseTenantQuoteItems(selectedQuote);

    return (
      <SubDataTable
        isEditProduct={true}
        setFields={quotePriceHandler}
        data={data}
        subQuoteId={selectedQuote["id"]}
        isEditActionHidden={false}
        isActions={true}
        clicked={handleProductModalOpening}
        isProductDetail={true}
      />
    );
  };

  const quotePriceHandler = (product, quoteId) => {
    const patchQuoteItemsBody = {
      price: product["UNIT_PRICE"],
      itemId: product["#"],
    };

    dispatch(quoteAcionsCreator.patchQuoteItems(patchQuoteItemsBody));
  };

  return (
    <>
      <div>
        {Boolean(selectedProduct) && (
          <ProductDetailModal
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Tenant Quotes</h1>
          </div>
        </div>
        <div className="dashboard-content-wrap">
          <div className="main-content-inner">
            <Row>
              <Col className="ml-auto" md="12">
                <DataTable
                  data={parsedQuotes}
                  loading={loading}
                  setFields={quoteDownPaymentHandler}
                  isProductDetail={false}
                  getDetailPanel={getItemsDetail}
                  isDetailPanel={true}
                />
              </Col>
            </Row>
            {localStorage.getItem("userEmail") && openChat && currentChannel && (
              <InitializePubnub>
                <PubnubChat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  currentChannel={currentChannel}
                  isActions={false}
                />
              </InitializePubnub>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
