import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { routesPath } from "../../constants/routesPath";

export const Error404Container = () => {
  const history = useHistory();
  const { match, url } = useRouteMatch();
  return (
    <div
      className={
        Object.values(routesPath).indexOf(url) <= -1 ? " container" : "d-none"
      }
    >
      <div class="error_section mt-5 pt-5">
        <h1 class="error my-5">404</h1>
        <div class="page">
          Ooops!!! The page you are looking for is not found
        </div>
        <Link class="back-home my-5" onClick={() => history.goBack()}>
          Go back
        </Link>
      </div>
    </div>
  );
};
