import React from "react";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownItem from "reactstrap/lib/DropdownItem";

import visaCard from "../../assets/images/visa.svg";
import dropdownCard from "../../assets/images/drop-down_cards.svg";
import americanExpress from "../../assets/images/american-express.svg";

export const PaymentCard = (props) => {
  const { cardData, deletePaymentMethod, makeDefault } = props;
  return (
    <div className="mt-4">
      <div className="card-bdy opt">
        <div className="visa">
          <img
            src={cardData.card.brand === "visa" ? visaCard : americanExpress}
            className="img-fluid"
          />
          <UncontrolledDropdown>
            <DropdownToggle className="card-toggle-menu-btn">
              <div className="card-btn-wrap">
                <a className="express" href="javascript:void(0);">
                  <img src={dropdownCard} alt="icon" />
                </a>
              </div>
            </DropdownToggle>

            <DropdownMenu className="card-toggle-menu">
              <DropdownItem onClick={() => deletePaymentMethod(cardData.id)}>
                <div className="card-dropdown-wrap">
                  <div className="card-dropdown">
                    <button
                      className="text-light"
                      style={{ border: "0", background: "transparent" }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem onClick={() => makeDefault(cardData.id)}>
                <div className="card-dropdown-wrap">
                  <div className="card-dropdown">
                    <button
                      className="text-light"
                      style={{ border: "0", background: "transparent" }}
                    >
                      Make Default
                    </button>
                  </div>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <hr />
        {cardData.default && (
          <div className="tag mt-3">
            <p>DEAFAULT</p>
          </div>
        )}
        <div className="card-info payment_methods">
          <p>
            <span>.... .... ....</span> {cardData.card.last4}
          </p>
          <p className="date">
            {cardData.card.exp_month}/{cardData.card.exp_year}
          </p>
        </div>
      </div>
    </div>
  );
};
