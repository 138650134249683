import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as productActionsCreator from "../../redux/actionsCreator/productActionsCreator";
import { useHistory } from "react-router";
import _ from "lodash";
import { DataTable } from "../../components/ui/tables/dataTable";
import dataService from "../../services/dataService";
import { ProductDetailModal } from "../../components/productDetailModal/productDetailModal";
import { routesPath } from "../../constants/routesPath";
import storageService from "../../services/storageService";
import { ConfirmationDailoge } from "../../components/confirmationDailoge/confirmationDailoge";
import { toast } from "react-toastify";

export const ProductsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const models = useSelector((state) => state.productsReducer.searchProduct);
  const loading = useSelector((state) => state.productsReducer.loading);
  const deleteProductError = useSelector(
    (state) => state.productsReducer.deleteProductError
  );
  const [parsedProducts, setParsedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [openConfirmationDailoge, setOpenConfirmationDailoge] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    const query = createQueryString();
    dispatch(productActionsCreator.getProducts(query));
  }, []);

  useEffect(() => {
    if (models) {
      let result = dataService.parseProductsTable(models);
      setParsedProducts(result);
    }
  }, [models]);

  useEffect(() => {
    return () => {
      dispatch(productActionsCreator.resetProductData());
    };
  }, []);

  useEffect(() => {
    if (deleteProductError) {
      toast.error("Faild To Delete Product!");
    }
  }, [deleteProductError]);

  useEffect(() => {
    if (confirmDelete) {
      let product = confirmDelete;

      let totalProducts = _.cloneDeep(models);
      let deleteProductIndex = _.findIndex(totalProducts, { id: product["#"] });
      setTimeout(() => {
        product.TYPE === "MODEL"
          ? dispatch(
              productActionsCreator.deleteModel({
                data: product["#"],
                products: totalProducts,
                productIndex: deleteProductIndex,
              })
            )
          : dispatch(
              productActionsCreator.deleteComponent({
                data: product["#"],
                products: totalProducts,
                productIndex: deleteProductIndex,
              })
            );
      });
    }
  }, [confirmDelete]);

  const createQueryString = () => {
    var query = new URLSearchParams();
    query.append("vendor__user_ptr__in", storageService.getUserId());

    return query.toString();
  };

  const deleteProductHandler = (e, product) => {
    setOpenConfirmationDailoge(product);
  };

  const handleProductModalOpening = (product) => {
    let changedProduct = null;
    changedProduct = _.cloneDeep(_.find(models, { id: product["#"] }));
    let result = dataService.parseProductModalData(changedProduct);

    setSelectedProduct(result);
  };

  const naviagteToAddNewProduct = () => {
    history.push(routesPath.addProducts);
  };

  const editProduct = (e, product) => {
    history.push(routesPath.editProduct(product["#"]));
  };

  return (
    <div>
      <ConfirmationDailoge
        secondaryText={`Are you sure, you want to delete Product # ${openConfirmationDailoge["#"]}`}
        open={openConfirmationDailoge}
        setOpen={setOpenConfirmationDailoge}
        setConfirm={setConfirmDelete}
      />
      {Boolean(selectedProduct) && (
        <ProductDetailModal
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          deleteProductHandler={deleteProductHandler}
        />
      )}
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1>Products</h1>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          <DataTable
            data={parsedProducts}
            loading={loading}
            clicked={handleProductModalOpening}
            deleteProduct={deleteProductHandler}
            toolbarAction={naviagteToAddNewProduct}
            setFields={editProduct}
            isActions={true}
            isEditProduct={true}
            isProductDetail={true}
            name="New Product"
          />
        </div>
      </div>
    </div>
  );
};
