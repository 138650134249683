import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import dataService from "../../services/dataService";
import { Spinner } from "../ui/loader";
import * as quotesActionsCreator from "../../redux/actionsCreator/quotesActionsCreator";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";

export const AddQuoteForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { parsedProducts, yupSchema, user } = props;

  const addQuoteResponse = useSelector(
    (state) => state.quotesReducer.addQuoteResponse
  );

  const addQuoteError = useSelector(
    (state) => state.quotesReducer.addQuoteError
  );

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: "all",
    defaultValues: { products: [{ id: null, quantity: null }] },
  });

  const products = form.watch("products");

  const removeProduct = (index) => {
    if (products.length > 1) {
      const newProducts = [...products];
      newProducts.splice(index, 1);
      form.setValue("products", newProducts);
    }
  };

  const appendProduct = () => {
    form.setValue("products", [...products, { id: null, quantity: null }]);
  };

  const onSubmit = (values) => {
    dispatch(
      quotesActionsCreator.addQuote({
        data: dataService.parseAddQuote(values),
      })
    );

    toast.success("Successfully add Quote");
    setTimeout(() => {
      history.push(routesPath.quotes);
    }, 1000);
  };

  useEffect(() => {
    if (addQuoteError) {
      toast.error("Failed to add Quote");
    }
  }, [addQuoteError]);

  return (
    <>
      <FormProvider {...form}>
        <form
          className="add-product-form-container"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <SelectInput
            name="user_id"
            label="User"
            options={user || []}
            required={true}
          />
          {products.map((_, index) => (
            <div key={index}>
              <SelectInput
                name={`products.${index}.id`}
                label="Product Id"
                options={parsedProducts || []}
                required={true}
              />
              <TextInput
                name={`products.${index}.quantity`}
                label="Quantity"
                type="number"
                required={true}
                min={1}
              />
              <button
                className="btn btn-primary mt-2"
                type="button"
                onClick={() => {
                  removeProduct(index);
                }}
              >
                -
              </button>
            </div>
          ))}

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <button
              className="btn btn-primary mb-3"
              type="button"
              onClick={() => {
                appendProduct();
              }}
            >
              +
            </button>
          </div>
          <div className="add-form-button m-auto mb-3 pt-4">
            <div className="cart-btn">
              <div className="links-btn">
                <div className="add-to-cart">
                  <button type="submit">
                    {/* {loading && <Spinner height={20} width={20} />} */}
                    Create Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
