import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Spinner } from "../../components/ui/loader";
import * as paymentActionsCreator from "../../redux/actionsCreator/paymentActionsCreator";
import { PaymentCard } from "../../components/paymentCard/paymentCard";
import { AddCardModal } from "../../components/addCardModal/addCardModal";
import _ from "lodash";

export const PaymentContainer = () => {
  const dispatch = useDispatch();

  const [initCard, setInitCard] = useState(false);

  const paymentMethodsList = useSelector(
    (state) => state.paymentReducer.response
  );
  const paymentMethodsListLoading = useSelector(
    (state) => state.paymentReducer.loading
  );
  const paymentMethodsListError = useSelector(
    (state) => state.paymentReducer.error
  );

  const deletePaymentMethodResponse = useSelector(
    (state) => state.paymentReducer.deletePaymentMethodResponse
  );
  const deletePaymentMethodLoading = useSelector(
    (state) => state.paymentReducer.deletePaymentMethodLoading
  );
  const deletePaymentMethodError = useSelector(
    (state) => state.paymentReducer.deletePaymentMethodError
  );

  const updateCardResponse = useSelector(
    (state) => state.paymentReducer.updateCardResponse
  );
  const updateCardLoading = useSelector(
    (state) => state.paymentReducer.updateCardLoading
  );
  const updateCardError = useSelector(
    (state) => state.paymentReducer.updateCardError
  );

  useEffect(() => {
    dispatch(paymentActionsCreator.getPaymentMethods());
  }, []);

  useEffect(() => {
    if (deletePaymentMethodResponse) {
      toast.success("Card successfully Removed!");
    } else if (deletePaymentMethodError) {
      toast.error(deletePaymentMethodError);
    }
  }, [deletePaymentMethodResponse, deletePaymentMethodError]);

  useEffect(() => {
    if (paymentMethodsListError) {
      toast.error(paymentMethodsListError);
    }
  }, [paymentMethodsListError]);

  useEffect(() => {
    return () => {
      dispatch(paymentActionsCreator.resetPayments());
    };
  }, []);

  const deletePaymentMethod = (id) => {
    let cards = _.cloneDeep(paymentMethodsList);
    let filteredCards = cards.filter((card) => card.id !== id);
    dispatch(
      paymentActionsCreator.deletePaymentMethod({
        payment_method_id: id,
        card: filteredCards,
      })
    );
  };

  const makeDefault = useCallback(
    (paymentId) =>
      dispatch(
        paymentActionsCreator.updatePaymentCard({
          payment_method_id: paymentId,
        })
      ),
    []
  );

  useEffect(() => {
    if (updateCardError) {
      toast.error("Failed to Change Default Payment Method!");
    } else if (updateCardResponse) {
      dispatch(paymentActionsCreator.getPaymentMethods());
      toast.success("Default Payment Method Updated!");
    }
  }, [updateCardResponse, updateCardError]);

  return (
    <>
      <div className="dashboard-content-wrap">
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Payment Methods</h1>
          </div>
        </div>
        {initCard && (
          <AddCardModal setInitCard={setInitCard} initCard={initCard} />
        )}
        <div className="main-content-inner">
          <label
            onClick={() => setInitCard(true)}
            style={{ float: "left", clear: "both" }}
            className="btn btn-outline-secondary px-4"
          >
            <i className="fas fa-plus" />
            &nbsp;Add Card
          </label>
          {paymentMethodsListLoading || updateCardLoading ? (
            <div className="pt-5 mt-5" style={{ clear: "both" }}>
              <Spinner height={100} width={100} />
            </div>
          ) : paymentMethodsList?.length > 0 ? (
            <div className="row" style={{ clear: "both" }}>
              {paymentMethodsList.map((cardData, key) => {
                return (
                  <div className="col-lg-4 col-md-12" key={key}>
                    <PaymentCard
                      cardData={cardData}
                      deletePaymentMethod={deletePaymentMethod}
                      makeDefault={makeDefault}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <h5 className="text-center mt-5 pt-5">
              No Payment Method attached
            </h5>
          )}
        </div>
      </div>
    </>
  );
};
