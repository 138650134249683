import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "../../components/ui/loader";

import * as accountActionsCreator from "../../redux/actionsCreator/accountActionsCreator";
import storageService from "../../services/storageService";

export const ProfileContainer = (props) => {
  const dispatch = useDispatch();
  const userLoading = useSelector((state) => state.accountReducer.loading);
  const user = useSelector((state) => state.accountReducer.userResponse);
  const userError = useSelector((state) => state.accountReducer.userError);
  const stripeLoading = useSelector(
    (state) => state.accountReducer.stripeLoading
  );
  const stripeResponse = useSelector(
    (state) => state.accountReducer.stripeResponse
  );
  const [stripeLink, setStripeLink] = useState(null);

  useEffect(() => {
    if (userError) {
      toast.error("Something went wrong!");
    }
  }, [userError]);

  useEffect(() => {
    if (stripeResponse) {
      setStripeLink(stripeResponse.url);
    }
  }, [stripeResponse]);

  useEffect(() => {
    return () => {
      dispatch(accountActionsCreator.clearProfile());
    };
  }, []);

  useEffect(() => {
    //me api
    dispatch(accountActionsCreator.read());
  }, []);

  useEffect(() => {
    dispatch(accountActionsCreator.stripeLink());
  }, []);

  return (
    <>
      <div className="dashboard-head-wrap">
        <div className="dashboard-head-title">
          <h1 className="pl-5 ml-5">Profile</h1>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <div className="main-content-inner">
          <section className="contact-form">
            <div className="pt-0 pb-5">
              <div className="row">
                <div className="col-12 col-md-8">
                  {user ? (
                    <div className="profile-needs-validation">
                      <div className="needs-validation">
                        <div className="heading_txt_login">
                          <h1>User Information</h1>
                        </div>
                        <div className="form-outline">
                          <label
                            className="float-start mb-2"
                            style={{ clear: "both" }}
                          >
                            First Name
                          </label>
                          <input
                            className="form-control"
                            id="validationCustom01"
                            type="text"
                            value={user.first_name}
                            name="first_name"
                            readOnly
                          />
                        </div>
                        <div className="form-outline">
                          <label className="float-start mb-2">Last Name</label>
                          <input
                            className="form-control"
                            id="validationCustom01"
                            type="text"
                            value={user.last_name}
                            name="last_name"
                            readOnly
                          />
                        </div>
                        <div className={"form-outline"}>
                          <label className="float-start mb-2">Username</label>
                          <input
                            className="form-control"
                            id="validationCustom01"
                            type="text"
                            value={user.username}
                            name="username"
                            readOnly
                          />
                        </div>

                        <div className="form-outline">
                          <label className="float-start mb-2">Email</label>
                          <input
                            className="form-control"
                            id="validationCustom01"
                            type="email"
                            value={user.email}
                            name="email"
                            readOnly
                          />
                        </div>
                        {storageService.getTenantId() && (
                          <>
                            <div className="form-outline">
                              <label className="float-start mb-2">
                                Tenant Domain
                              </label>
                              <input
                                className="form-control"
                                id="validationCustom01"
                                type="email"
                                value={storageService.getTenantDomain()}
                                name="email"
                                readOnly
                              />
                            </div>
                            <div className="form-outline">
                              <label className="float-start mb-2">
                                Tenant Subscription Status
                              </label>
                              <input
                                className="form-control"
                                id="validationCustom01"
                                type="text"
                                value={
                                  storageService.getTenantSubscriptionStatus() ==
                                  "true"
                                    ? "Subscribed"
                                    : "No Subscription"
                                }
                                name="email"
                                readOnly
                              />
                            </div>
                          </>
                        )}
                        <div className="text-start">
                          {stripeLoading ? (
                            <Spinner height="30" width="30" />
                          ) : (
                            <>
                              <span className="py-3 d-block">
                                Stripe Account Link:
                                <b>
                                  {stripeLink ? (
                                    <a href={stripeLink} target="_blank">
                                      {" "}
                                      Stripe Account
                                    </a>
                                  ) : (
                                    " Not Available!"
                                  )}
                                </b>
                              </span>
                              <span className="py-3 d-block">
                                Stripe Account Status:
                                <b>
                                  {user?.status
                                    ? user?.status === "PENDING"
                                      ? " You need to verify your Stripe Account!"
                                      : " Approved"
                                    : " Not Available!"}
                                </b>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : userLoading ? (
                    <div className="py-5 my-5 text-center">
                      <Spinner height={100} width={100} />
                    </div>
                  ) : (
                    userError && (
                      <div className="text-center py-5 my-5">
                        Failed to Get User Information!
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
