import React from "react";
export const OrdersIcon = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.521 5.74833C14.6435 6.82176 12.7661 7.89562 10.8887 8.96905C10.8439 8.99463 10.7987 9.01978 10.7343 9.05602C10.2356 8.43063 9.73765 7.8061 9.22949 7.16835C11.257 6.05612 13.2743 4.94987 15.3047 3.83594C15.338 3.88496 15.3704 3.9293 15.3998 3.97576C15.74 4.5112 16.0793 5.04749 16.42 5.58293C16.4498 5.63025 16.4869 5.67245 16.521 5.71721C16.521 5.72744 16.521 5.73768 16.521 5.74833Z" />
    <path d="M0.521484 5.71585C0.557294 5.67364 0.597793 5.63442 0.628486 5.58838C0.959297 5.09558 1.28798 4.60192 1.61794 4.10869C1.64948 4.06179 1.68316 4.01618 1.72067 3.96289C3.74519 5.03206 5.76245 6.09739 7.79805 7.1721C7.29288 7.80601 6.79794 8.42713 6.29746 9.0555C4.36546 7.94882 2.44326 6.84811 0.521484 5.74697C0.521484 5.73631 0.521484 5.72608 0.521484 5.71585Z" />
    <path d="M2.60107 3.3634C3.46348 2.9388 4.30245 2.52572 5.14184 2.11263C6.22848 1.57805 7.31428 1.04176 8.40348 0.51229C8.46742 0.48117 8.56931 0.473496 8.63112 0.502485C10.5418 1.39815 12.4491 2.29978 14.3572 3.20098C14.3756 3.20951 14.3922 3.2223 14.425 3.24276C14.3734 3.27431 14.3321 3.30244 14.2882 3.32631C12.4099 4.35626 10.5312 5.3845 8.65457 6.41744C8.55141 6.47413 8.47723 6.47584 8.37236 6.41999C6.50302 5.42841 4.63113 4.44195 2.76009 3.45421C2.7149 3.43076 2.67141 3.4039 2.60107 3.3634Z" />
    <path d="M2.03392 7.70508C2.09872 7.7379 2.14859 7.7605 2.19634 7.78735C3.49571 8.53168 4.79466 9.27643 6.0936 10.0208C6.40736 10.2007 6.61326 10.1619 6.84006 9.87795C7.23268 9.38642 7.62403 8.89446 8.03669 8.37651C8.03669 10.2565 8.03669 12.1041 8.03669 13.9734C7.98809 13.95 7.94333 13.93 7.89985 13.9069C5.98276 12.8957 4.06568 11.8841 2.14817 10.8729C2.07825 10.8359 2.02113 10.8064 2.02198 10.7037C2.0271 9.74068 2.02539 8.77766 2.02582 7.81506C2.02582 7.78522 2.03008 7.75581 2.03392 7.70508Z" />
    <path d="M8.99512 13.9602C8.99512 12.1028 8.99512 10.2633 8.99512 8.38716C9.04713 8.4477 9.08294 8.48649 9.11576 8.52784C9.4794 8.98356 9.84261 9.4397 10.2067 9.89542C10.4139 10.155 10.6283 10.1972 10.9169 10.0323C12.2218 9.28623 13.5267 8.53893 14.8312 7.79247C14.8789 7.76519 14.928 7.73961 14.9906 7.70508C14.9949 7.7686 15.0004 7.80867 15.0004 7.84917C15.0009 8.74483 14.9996 9.64049 15.0026 10.5362C15.003 10.6304 14.9766 10.6854 14.8909 10.7323C12.961 11.7882 11.0324 12.8476 9.10382 13.9061C9.0727 13.9227 9.04073 13.9372 8.99512 13.9602Z" />
  </svg>
);
