import * as Yup from "yup";

export const quoteValidationSchema = Yup.object({
  user_id: Yup.number().typeError("Required").min(1, "Min value 1"),
  products: Yup.array().of(
    Yup.object({
      id: Yup.mixed().required("Required"),
      quantity: Yup.mixed().required("Required"),
    })
  ),
});
