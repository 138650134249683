import React from "react";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";

const InitializePubnub = (props) => {
  const pubnub = new PubNub({
    publishKey: process.env.REACT_APP_PUBNUB_PUB_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUB_KEY,
    uuid: localStorage.getItem("userEmail"),
    restore: true,
  });

  return <PubNubProvider client={pubnub}>{props.children}</PubNubProvider>;
};

export default InitializePubnub;
