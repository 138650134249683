import React from "react";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownItem from "reactstrap/lib/DropdownItem";
import dropdownCard from "../../assets/images/drop-down_cards.svg";

export const SubscriptionCard = (props) => {
  const {
    subscriptionData,
    setSelectedPlan,
    selection,
    isDelete,
    cancelSubscriptionPlan,
    setSubscriptionSelectionError,
    setSubscriptionPrice,
  } = props;
  return (
    <div
      style={{ position: "relative" }}
      onClick={() => {
        selection && setSelectedPlan(subscriptionData.id);
        setSubscriptionSelectionError(null);
      }}
    >
      <div className="form-check form-check-inline px-3">
        {selection && (
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id={subscriptionData.id}
            value={subscriptionData.id}
          />
        )}
        <label className="form-check-label" for={subscriptionData.id}>
          <span className="price">
            $
            {(setSubscriptionPrice
              ? subscriptionData.amount / 100
              : subscriptionData.amount) +
              "/" +
              subscriptionData.interval}
          </span>
          <span className="plan-des">
            (One bill, every {subscriptionData.interval})
          </span>
          <hr className="my-2 ps-0 ms-0" />
          <small className="py-1 ps-0 text-success">
            Upto{" "}
            {subscriptionData.products_allowed ||
              subscriptionData.metadata?.products_allowed}{" "}
            Products
          </small>
        </label>
      </div>
      {isDelete && (
        <div className="plans-dropdown">
          <UncontrolledDropdown>
            <DropdownToggle className="card-toggle-menu-btn">
              <div className="card-btn-wrap">
                <a className="express" href="javascript:void(0);">
                  <img src={dropdownCard} alt="icon" />
                </a>
              </div>
            </DropdownToggle>

            <DropdownMenu className="card-toggle-menu">
              <DropdownItem
              //  onClick={() => deletePaymentMethod(cardData.id)}
              >
                <div className="card-dropdown-wrap">
                  <div
                    className="card-dropdown"
                    onClick={() => cancelSubscriptionPlan(subscriptionData.id)}
                  >
                    <button
                      className="text-light"
                      style={{ border: "0", background: "transparent" }}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )}
    </div>
  );
};
