import React, { useEffect, useState } from "react";
// import { ProductCanvas } from "../productCanvas/productCanvas";
import noImg from "../../assets/img/noImg.jpg";
import { Modal } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { asset } from "../../constants/baseUrl";
import { ProductCanvas } from "../productCanvas/productCanvas";
import { decryptedPrice } from "../../utils/decode";

export const ProductDetailModal = React.memo((props) => {
  const { selectedProduct, setSelectedProduct } = props;

  const [selectedItem, setSelectedItem] = useState(0);
  const [modalActiveTab, setModalActiveTab] = useState(0);
  const [view, setView] = useState("2D");

  const handleCarouselChange = (e) => {
    setSelectedItem(e);
  };

  return (
    <div className="pop-model">
      <Modal
        open={true}
        tabIndex="-1"
        className="pop-model"
        style={{ overflowY: "scroll", outline: "1px solid transparent" }}
        onClose={() => setSelectedProduct(null)}
        id="exampleModal"
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => setSelectedProduct(null)}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="product-detail-images-wrap">
                    {view === "2D" ? (
                      <div className="product-detail-images">
                        <div id="sync1" className="slider owl-carousel">
                          <Carousel
                            dynamicHeight
                            autoPlay
                            infiniteLoop
                            showArrows={false}
                            showStatus={false}
                            showThumbs={true}
                            thumbWidth={100}
                            showIndicators={false}
                            onChange={handleCarouselChange}
                            selectedItem={selectedItem}
                          >
                            {selectedProduct.images.map((image, key) => {
                              return (
                                <div key={key}>
                                  <div className="product-img-single">
                                    <img
                                      alt="..."
                                      className="d-block"
                                      height="500px"
                                      src={image.replace("image/upload/", "")}
                                      style={{ objectFit: "cover" }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </Carousel>
                        </div>
                      </div>
                    ) : (
                      view === "3D" && <ProductCanvas />
                    )}
                  </div>
                  <div className="pt-3">
                    <div className="product-thumb-images">
                      <div
                        id="sync2"
                        className="navigation-thumbs owl-carousel owl-loaded"
                      >
                        {view === "2D"
                          ? selectedProduct.images.map((image, key) => {
                              return (
                                <div
                                  onClick={() => setSelectedItem(key)}
                                  key={key}
                                  className={
                                    selectedItem === key
                                      ? "product-thumb-single  active-carousel-thumb"
                                      : "product-thumb-single "
                                  }
                                  style={{
                                    backgroundImage: `url(${image.replace(
                                      "image/upload/",
                                      ""
                                    )})`,
                                  }}
                                ></div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="product-details pd">
                    <div className="product-detail">
                      <h1>{selectedProduct.name}</h1>
                      <p>
                        Product by: <a>{selectedProduct.vendor}</a>
                      </p>
                      <h3>$ {decryptedPrice(selectedProduct.price)}</h3>
                      <label for="" className="form-label">
                        Quantity : <span>{selectedProduct.quantity}</span>
                      </label>
                      <span className="border-sec"></span>
                    </div>
                    <div className="row">
                      <div className="customization">
                        <div className="col-12  text-center col-md-6 mb-3">
                          <div
                            className={
                              view === "2D" ? "activeViews" : "customize"
                            }
                          >
                            <a onClick={() => setView("2D")}>View in 2D</a>
                          </div>
                        </div>
                        <div className="col-12 px-3 text-center col-md-6 ">
                          <div
                            className={
                              view === "3D" ? "activeViews" : "get-help"
                            }
                          >
                            <a onClick={() => setView("3D")}>View in 3D</a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- tabs --> */}
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                          className={
                            modalActiveTab === 0
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                          onClick={() => setModalActiveTab(0)}
                        >
                          Description
                        </button>

                        <button
                          className={
                            modalActiveTab === 1
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                          onClick={() => setModalActiveTab(1)}
                        >
                          Specifications
                        </button>
                      </div>
                    </nav>

                    <div className="tab-content" id="nav-tabContent">
                      {modalActiveTab === 0 ? (
                        <div
                          className="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <div className="product_description">
                            <p>
                              {selectedProduct.description ||
                                "No description given"}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="tab-pane fade show active"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                        >
                          <div className="product_description">
                            <p>
                              Length : {selectedProduct.length} <br />
                              Width : {selectedProduct.width} <br />
                              Height : {selectedProduct.height} <br />
                              Weight : {selectedProduct.weight} <br />
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <!-- End --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});
