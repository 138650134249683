import * as Yup from "yup";

export const editQuotesValidationSchema = Yup.object({
  quote_id: Yup.number().typeError("Required").min(1, "Min value 1"),
  remove_products: Yup.array(),
  add_product: Yup.array().of(
    Yup.object({
      id: Yup.mixed().required("Required"),
      quantity: Yup.mixed().required("Required"),
    })
  ),
});
