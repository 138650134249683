import fernet from "fernet";

const decryptedPrice = (value) => {
  try {
    var secret = new fernet.Secret(process.env.REACT_APP_PRICE_DECRYPTION_KEY);
    var token = new fernet.Token({
      secret: secret,
      token: value,
      ttl: 0,
    });
    return parseFloat(token.decode());
  } catch {
    return value;
  }
};

export { decryptedPrice };
