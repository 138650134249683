import React, { useState } from "react";
import { Spinner } from "../ui/loader";

export const ResetEmailPage = (props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const verifyEmailPattern = () => {
    const emailValidationRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailValidationRegex.test(String(email).toLowerCase());
  };

  const submitEmail = () => {
    if (verifyEmailPattern()) {
      props.submitForgotEmail(email);
      setEmail("");
    } else {
      setEmailError("Invalid email");
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="needs-validation">
        <div className="heading_txt_login">
          <h1>Forgot Password</h1>
        </div>
        <div
          className={
            emailError ? "form-outline form-outline-danger" : "form-outline"
          }
        >
          <input
            className="form-control"
            id="validationCustom01"
            placeholder="Email"
            type="text"
            value={email}
            readOnly={props.loading}
            onChange={(e) => {
              setEmailError("");
              setEmail(e.target.value);
            }}
          />
          {emailError && <span className="text-danger">{emailError}</span>}
        </div>

        <button
          disabled={!email.trim("").length}
          onClick={submitEmail}
          className="btn btn-primary"
          type="submit"
        >
          {props.loading && (
            <div className="d-inline px-3">
              <Spinner height={20} width={20} />
            </div>
          )}
          <span className="d-inline">Reset</span>
        </button>
        <hr />
      </div>
    </form>
  );
};
