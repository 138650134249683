import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import _ from "lodash";
import { routesPath } from "../../constants/routesPath";

import * as subscriptionsActionsCreator from "../../redux/actionsCreator/subscriptionActionsCreator";
import { Spinner } from "../../components/ui/loader";
import { SubscriptionCard } from "../../components/subscriptionCard/subscriptionCard";
import storageService from "../../services/storageService";

export const SubscriptionContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [domainName, setDomainName] = useState("");
  const [domainError, setDomainError] = useState("");
  const [subscriptionSelectionError, setSubscriptionSelectionError] =
    useState(null);

  const [subscriptionType, setSubscriptionType] = useState("SUBSCRIPTIONS");

  const subscriptionPlansList = useSelector(
    (state) => state.subscriptionReducer.response
  );
  const subscriptionPlansListLoading = useSelector(
    (state) => state.subscriptionReducer.loading
  );
  const subscriptionPlansListError = useSelector(
    (state) => state.subscriptionReducer.error
  );
  const subscribedPlansList = useSelector(
    (state) => state.subscriptionReducer.userSubscriptionsResponse
  );
  const subscribedPlansListError = useSelector(
    (state) => state.subscriptionReducer.userSubscriptionsError
  );
  const postSubscriptionPlanResponse = useSelector(
    (state) => state.subscriptionReducer.chooseSubscriptionPlanResponse
  );
  const postSubscriptionPlanError = useSelector(
    (state) => state.subscriptionReducer.chooseSubscriptionPlanError
  );
  const postSubscriptionPlanLoading = useSelector(
    (state) => state.subscriptionReducer.chooseSubscriptionPlanLoading
  );
  const cancelSubscriptionPlanResponse = useSelector(
    (state) => state.subscriptionReducer.cancelSubscriptionPlanResponse
  );
  const cancelSubscriptionPlanError = useSelector(
    (state) => state.subscriptionReducer.cancelSubscriptionPlanError
  );
  const cancelSubscriptionPlanLoading = useSelector(
    (state) => state.subscriptionReducer.cancelSubscriptionPlanLoading
  );

  const updateSubscriptionResponse = useSelector(
    (state) => state.subscriptionReducer.updateSubscriptionResponse
  );
  const updateSubscriptionLoading = useSelector(
    (state) => state.subscriptionReducer.updateSubscriptionLoading
  );
  const updateSubscriptionError = useSelector(
    (state) => state.subscriptionReducer.updateSubscriptionError
  );

  useEffect(() => {
    if (cancelSubscriptionPlanResponse) {
      toast.success("Subscription Canceled!");
      history.push(routesPath.profile);
    } else if (cancelSubscriptionPlanError) {
      toast.error("An Unexpected Error Occured!");
    }
  }, [cancelSubscriptionPlanResponse, cancelSubscriptionPlanError]);

  useEffect(() => {
    if (updateSubscriptionError) {
      toast.error("Failed to Update Subscription!");
    } else if (updateSubscriptionResponse) {
      history.push(routesPath.profile);
      toast.success("Subscription Updated Successfully!");
    }
  }, [updateSubscriptionResponse, updateSubscriptionError]);

  useEffect(() => {
    if (subscriptionType === "PLANS") {
      dispatch(subscriptionsActionsCreator.getSubscription());
    } else {
      dispatch(subscriptionsActionsCreator.getSubscribedPlans());
      setDomainName("");
      setDomainError("");
    }
  }, [subscriptionType]);

  useEffect(() => {
    if (postSubscriptionPlanResponse) {
      toast.success("Plan Subscribed Successfully!");

      setDomainError("");
      setDomainName("");
      setSubscriptionSelectionError(null);
      setTimeout(() => {
        history.push(routesPath.profile);
      }, 1000);
    } else if (postSubscriptionPlanError) {
      toast.error(postSubscriptionPlanError);
    }
  }, [postSubscriptionPlanError, postSubscriptionPlanResponse]);

  useEffect(() => {
    return () => {
      dispatch(subscriptionsActionsCreator.resetSubscriptions());
    };
  }, []);

  const setSubscription = () => {
    if (subscriptionType === "SUBSCRIPTIONS") {
      setSubscriptionType("PLANS");
    }
    if (!domainName?.trim("").length && !storageService.getTenantDomain()) {
      setDomainError("Invalid Domain Name!");
      return;
    } else if (!selectedPlan) {
      setSubscriptionSelectionError("Please Select a Plan!");
      return;
    }

    if (storageService.getTenantId() && subscribedPlansList?.data?.length) {
      let subscriptionData = {
        subscription_id: subscribedPlansList?.data[0].id,
        plan: selectedPlan,
      };

      dispatch(
        subscriptionsActionsCreator.updateSubscriptionPlan(subscriptionData)
      );
    } else {
      let subscriptionData = {
        plan: selectedPlan,
        tenant_domain: {
          domain: storageService.getTenantDomain() || domainName,
          name: storageService?.getTenantDomain() || domainName,
          vendor: storageService.getUserId(),
        },
      };
      dispatch(
        subscriptionsActionsCreator.chooseSubscriptionPlan(subscriptionData)
      );
    }
  };

  const cancelSubscriptionPlan = useCallback(
    (priceId) => {
      let plan = subscribedPlansList.data?.find(
        (item) => item.plan?.id === priceId
      );
      dispatch(
        subscriptionsActionsCreator.cancelSubscriptionPlan({
          subscription_id: plan?.id,
          plan: plan,
        })
      );
    },
    [subscribedPlansList]
  );

  return (
    <>
      <div className="dashboard-content-wrap">
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Subscriptions</h1>
          </div>
        </div>
        <div className="main-content-inner">
          <div className="subscription-plan">
            <ul className="nav nav-tabs   m-auto mx-3">
              <li
                className="nav-item product-form-li"
                onClick={() => setSubscriptionType("SUBSCRIPTIONS")}
              >
                <span
                  className={
                    subscriptionType === "SUBSCRIPTIONS"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Subscriptions
                </span>
              </li>
              <li
                className="nav-item product-form-li"
                onClick={() => setSubscriptionType("PLANS")}
              >
                <span
                  className={
                    subscriptionType === "PLANS"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Plans
                </span>
              </li>
            </ul>
            {subscriptionPlansListLoading && (
              <div className="text-center pt-5 mt-5">
                <Spinner height={100} width={100} />
              </div>
            )}
            {!subscriptionPlansListLoading && (
              <>
                <div className="subscription-txt">
                  <h1 className="mt-4">
                    {subscriptionType === "PLANS"
                      ? "Choose Your Plan"
                      : "Manage your subscriptions"}
                  </h1>

                  {subscriptionType === "PLANS" && (
                    <p>
                      We have several plans for small and large scale
                      businesses.
                    </p>
                  )}
                </div>
                <div className="plan-option">
                  {subscriptionPlansList?.length > 0
                    ? subscriptionType === "PLANS" &&
                      subscriptionPlansList.map((plan, key) => {
                        if (
                          plan.price_id !==
                          (subscribedPlansList?.data &&
                            subscribedPlansList?.data[0]?.plan?.id)
                        ) {
                          return (
                            <div key={key}>
                              <SubscriptionCard
                                subscriptionData={plan}
                                setSelectedPlan={setSelectedPlan}
                                selection={true}
                                isDelete={false}
                                setSubscriptionSelectionError={
                                  setSubscriptionSelectionError
                                }
                              />
                            </div>
                          );
                        }
                      })
                    : null}
                  {subscriptionType === "PLANS" &&
                    !storageService.getTenantId() && (
                      <>
                        <div style={{ flexBasis: "100%" }}></div>
                        <small className="text-danger mt-2">
                          {subscriptionSelectionError}
                        </small>
                        <div
                          style={{
                            flexBasis: "100%",
                          }}
                        ></div>
                        <div className="form-group domain-from-group my-3 pt-3">
                          <label
                            className="col-form-label float-start is-invalid"
                            htmlFor="domain_name"
                          >
                            Domain
                          </label>
                          <div
                            className="input-group"
                            style={{ clear: "both", width: "auto" }}
                          >
                            <input
                              type="text"
                              value={domainName}
                              className="form-control w-auto"
                              placeholder="Domain Name"
                              name="domain_name"
                              onChange={(e) => {
                                setDomainName(e.target.value);
                                setDomainError(null);
                              }}
                            />
                          </div>
                          {domainError && (
                            <small className="text-danger">{domainError}</small>
                          )}
                        </div>
                      </>
                    )}

                  {subscribedPlansList?.data?.length
                    ? subscriptionType === "SUBSCRIPTIONS" &&
                      subscribedPlansList?.data.map((plan, key) => (
                        <div key={key} className="my-4">
                          <SubscriptionCard
                            selection={false}
                            subscriptionData={plan.plan}
                            isDelete={true}
                            setSubscriptionPrice={true}
                            cancelSubscriptionPlan={cancelSubscriptionPlan}
                            setSubscriptionSelectionError={
                              setSubscriptionSelectionError
                            }
                          />
                        </div>
                      ))
                    : null}
                </div>
                {(subscriptionType !== "SUBSCRIPTIONS" ||
                  !subscribedPlansList?.data?.length) && (
                  <div className="upgrade-btn">
                    <button
                      onClick={setSubscription}
                      disabled={postSubscriptionPlanLoading}
                    >
                      {(postSubscriptionPlanLoading ||
                        updateSubscriptionLoading) && (
                        <Spinner height={20} width={20} />
                      )}
                      {subscriptionType === "PLANS"
                        ? subscribedPlansList?.data?.length
                          ? "Update"
                          : "Subscribe"
                        : "Get Subscription"}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
