import React, { useEffect, useState } from "react";
import { Spinner } from "../../components/ui/loader";
import * as authActionsCreator from "../../redux/actionsCreator/authActionsCreator";
import { Formik } from "formik";
import validationSchema from "./validationSchema";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routesPath } from "../../constants/routesPath";
import { SignUpCompanyInfo } from "../../components/signUpCompanyInfo/signUpCompanyInfo";
import { SignUpUserInfo } from "../../components/signUpUserInfo/signUpUserInfo";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { toast } from "react-toastify";

export const SignupContainer = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const steps = ["User Information", "Company Information"];
  const isLastStep = activeStep === steps.length - 1;
  const loading = useSelector((state) => state.authReducer.loading);
  const signUpResponse = useSelector((state) => state.authReducer.response);
  const currentValidationSchema = validationSchema[activeStep];

  const signUpError = useSelector((state) => state.authReducer.error);

  const initialValues = {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    business_name: "",
    location: "",
    industry: "",
    address: "",
    city: "",
    country: "",
    state: "",
    postal_code: "",
    phone_number: "",
  };
  const registerData = (data) => {
    data.role = "VENDOR";
    data.status = "PENDING";
    let { confirm_password, ...newData } = data;
    dispatch(authActionsCreator.register(newData));
  };

  function _renderStepContent(step, formikProps) {
    switch (step) {
      case 0:
        return <SignUpUserInfo {...formikProps} />;
      case 1:
        return <SignUpCompanyInfo {...formikProps} />;
      default:
        return <div>Not Found</div>;
    }
  }

  const _handleSubmit = (values, actions) => {
    actions.setTouched({});
    // actions.setSubmitting(false);
    actions.setErrors({});
    if (isLastStep) {
      registerData(values, actions);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const _handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (signUpError) {
      toast.error(signUpError);
    }
  }, [signUpError]);

  useEffect(() => {
    if (signUpResponse) {
      toast.success("Account Created Successfully!");
      history.push(routesPath.login);
    }
  }, [signUpResponse]);

  useEffect(() => {
    return () => {
      dispatch(authActionsCreator.resetSignUp());
    };
  }, []);

  return (
    <>
      <main>
        <section className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Formik
                  // validateOnChange={true}
                  initialValues={initialValues}
                  validationSchema={currentValidationSchema}
                  enableReinitialize
                  onSubmit={_handleSubmit}
                >
                  {(formikProps) => (
                    <form onSubmit={formikProps.handleSubmit}>
                      <div className="needs-validation signup">
                        <div className="heading_txt_login">
                          {isLastStep && (
                            <KeyboardBackspaceRoundedIcon
                              className="signUp-back"
                              onClick={_handleBack}
                            />
                          )}
                          <h1>Sign Up</h1>
                        </div>
                        <h5 className="text-center pb-2">
                          {isLastStep
                            ? "Company Information"
                            : "User Information"}
                        </h5>
                        {_renderStepContent(activeStep, formikProps)}

                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={!formikProps.isValid}
                        >
                          {loading && (
                            <div className="d-inline px-3">
                              <Spinner height={20} width={20} />
                            </div>
                          )}
                          {isLastStep ? "Signup" : "Next"}
                        </button>
                        <hr />
                        <Link
                          style={{ textDecoration: "none" }}
                          to={routesPath.login}
                        >
                          <div className="create-acc">
                            <p>
                              Already have an account?
                              <a href="login.html"> Login</a>
                            </p>
                          </div>
                        </Link>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
