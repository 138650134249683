import React, { forwardRef, useEffect, useState } from "react";
import { Divider, Table } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import {
  Row,
  Col,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
// import { QuotationDetails } from "../../components/quoteDetail/quoteDetail";
import { DataTable } from "../../components/ui/tables/dataTable";
import _ from "lodash";
import { PubnubChat } from "../../components/chat/chat";
import InitializePubnub from "../../components/initializePubnub/initializePubnub";
import storage from "../../services/storageService";
import { toast } from "react-toastify";
import dataService from "../../services/dataService";
import { Spinner } from "../../components/ui/loader";
import { useParams, useRouteMatch } from "react-router";
import quoteStatus from "../../constants/quoteStatus";
import { SubDataTable } from "../../components/ui/tables/subDataTable";
import { ProductDetailModal } from "../../components/productDetailModal/productDetailModal";
import storageService from "../../services/storageService";
import { decryptedPrice } from "../../utils/decode";
import { clearTransitions } from "../../redux/reducers/quotesReducer";
import { EditQuoteForm } from "../../components/form/editQuoteForm";
import { editQuotesValidationSchema } from "../../validationSchemas/editQuotesValidationSchema";
import * as quoteAcionsCreator from "../../redux/actionsCreator/quotesActionsCreator";
import { routesPath } from "../../constants/routesPath";

export const EditQuotesContainer = (props) => {
  const history = useHistory();
  const QuoteId = useParams().id;
  const [quote, setQuote] = useState(null);

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const subQuotes = useSelector((state) => state.quotesReducer.subQuotes);
  const loading = useSelector((state) => state.quotesReducer.loading);
  const params = useParams();

  const patchQuoteItemsSuccess = useSelector(
    (state) => state.quotesReducer.patchQuoteItemsSuccess
  );
  const patchQuoteItemsError = useSelector(
    (state) => state.quotesReducer.patchQuoteItemsError
  );

  const getQuoteByIdResponse = useSelector(
    (state) => state.quotesReducer.getQuoteByIdResponse
  );
  const getQuoteByIdError = useSelector(
    (state) => state.quotesReducer.getQuoteByIdError
  );

  const getUserByIdResponse = useSelector(
    (state) => state.quotesReducer.getUserByIdResponse
  );
  const getUserByIdError = useSelector(
    (state) => state.quotesReducer.getUserByIdError
  );

  const [deleteSelectedProduct, setDeleteSelectedProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(``);

  const [subQuoteDetails, setSubQuoteDetails] = useState(false);
  const [selectedSubQuote, setSelectedSubQuote] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [parsedSubQuotes, setParsedSubQuotes] = useState([]);
  const [image, setImage] = useState(null);

  const possibleTransitionsLoader = useSelector(
    (state) => state.quotesReducer.possibleTransitionsLoader
  );
  const possibleTransitions = useSelector(
    (state) => state.quotesReducer.possibleTransitions
  );
  const possibleTransitionsError = useSelector(
    (state) => state.quotesReducer.possibleTransitionsError
  );

  const quoteUpdateResponse = useSelector(
    (state) => state.quotesReducer.quoteUpdateResponse
  );

  const quoteUpdateError = useSelector(
    (state) => state.quotesReducer.quoteUpdateError
  );

  useEffect(() => {
    dispatch(quoteAcionsCreator.getQuoteById(QuoteId));
  }, [dispatch]);

  useEffect(() => {
    console.log("getQuoteByIdResponse", getQuoteByIdResponse);
  }, [getQuoteByIdResponse]);

  let userId = getQuoteByIdResponse?.user;

  useEffect(() => {
    if (userId != null) {
      dispatch(quoteAcionsCreator.getUserById(userId));
    }
  }, [dispatch, getQuoteByIdResponse]);

  useEffect(() => {
    if (getUserByIdResponse) {
      console.log("getUserByIdResponse", getUserByIdResponse);
    }
  }, [getQuoteByIdResponse, getUserByIdResponse]);

  useEffect(() => {
    if (storage.getUserRole() === "ADMIN") {
      let queryString = createQueryString(match.params.id);
      dispatch(quoteAcionsCreator.getQuotes(queryString));
    } else {
      dispatch(quoteAcionsCreator.getQuotes());
    }
  }, [patchQuoteItemsSuccess, quoteUpdateResponse]);

  useEffect(() => {
    return () => {
      dispatch(clearTransitions());
    };
  }, [dispatch]);

  useEffect(() => {
    if (getQuoteByIdResponse) setQuote(getQuoteByIdResponse);
  }, [getQuoteByIdResponse]);

  useEffect(() => {
    if (subQuotes) {
      let result = dataService.parseSubQuoteTable(
        subQuotes,
        getSubQuoteItems,
        getChat,
        getStatus,
        getTransitionsDropdown
      );
      setParsedSubQuotes(result);
    }
  }, [subQuotes, possibleTransitions]);

  useEffect(() => {
    if (possibleTransitions) {
      if (possibleTransitions?.status) {
        toast.success(possibleTransitions.status.replaceAll("_", " "));
      } else if (
        !possibleTransitions?.transitions?.length &&
        !possibleTransitions?.status
      ) {
        toast.error("No Available Transitions!");
      }
    }
  }, [possibleTransitions]);

  useEffect(() => {
    if (possibleTransitionsError) {
      toast.error(possibleTransitionsError);
    }
  }, [possibleTransitionsError]);

  useEffect(() => {
    if (patchQuoteItemsError) {
      toast.error(patchQuoteItemsError);
    }
  }, [patchQuoteItemsError]);

  const quoteStatusHandler = (transition, quote, subQuoteIndex) => {
    let errorQuotes = [];
    subQuotes.map((quote, index) => {
      quote.quote_items.map((item) => {
        if (decryptedPrice(item.price) <= 0 && index === subQuoteIndex) {
          errorQuotes.push(index);
        }
      });
    });

    if (errorQuotes.includes(subQuoteIndex)) {
      toast.error("Please enter price for quote items!");
      return;
    }

    if (!errorQuotes.includes(subQuoteIndex)) {
      const subQuote = {
        quote: quote.quote,
        vendor: quote.vendor,
        subQuoteId: quote.id,
        transition: transition,
        type: "USER",
        productType: "subQuotes",
      };

      dispatch(quoteAcionsCreator.postpossibleTransitions(subQuote));
    }
  };

  const getQuoteDetail = (quote) => {
    subQuotes.map((subQuote) => {
      if (subQuote.id === quote["#"]) {
        setSelectedSubQuote({ index: quote.tableData.id, quote: subQuote });
        setSubQuoteDetails(true);
      }
    });
  };

  useEffect(() => {
    return () => {
      dispatch(quoteAcionsCreator.clearQuoteUpdateData());
    };
  }, []);

  const toggle = () => setSubQuoteDetails(!subQuoteDetails);

  const handleChat = (id) => {
    setCurrentChannel(`subQuote-${id}`);
    setOpenChat(true);
  };

  const createQueryString = (vendorId) => {
    var query = new URLSearchParams();
    query.append("quote__id", vendorId);
    return query.toString();
  };

  const quotePriceHandler = (product, subQuoteId) => {
    let tempSubQuote = _.find(subQuotes, { id: subQuoteId });

    if (
      tempSubQuote.status === "SENT_TO_VENDOR" &&
      storageService.getUserRole() === "ADMIN"
    ) {
      toast.error("You are not allowed for this action!");
      return;
    }
    const patchQuoteItemsBody = {
      price: product["UNIT_PRICE"],
      itemId: product["#"],
    };

    dispatch(quoteAcionsCreator.patchQuoteItems(patchQuoteItemsBody));
  };

  const getSubQuoteItems = (subQuote) => {
    return subQuote.quote_items.map((subQuoteItem, index) => {
      return (
        <Badge color="badge-dot bg-dark m-1" key={index}>
          {subQuoteItem.product.data.name}
        </Badge>
      );
    });
  };

  const getStatus = (quote) => {
    return (
      <Badge className="badge-dot mr-4 bg-dark">
        <span className="status">{quoteStatus[quote.status].label}</span>
      </Badge>
    );
  };

  const getChat = (subQuote) => {
    return (
      <div
        className="quotes-action"
        onClick={(e) => {
          e.stopPropagation();
          handleChat(subQuote.id);
        }}
      >
        <button>Chat</button>
      </div>
    );
  };

  const getTransitionsDropdown = (subQuote, index) => {
    return (
      <UncontrolledDropdown
        onClick={(e) => {
          e.stopPropagation();
          getpossibleTransitions(subQuote.id);
        }}
      >
        <DropdownToggle
          caret
          style={{
            background: "transparent",
            border: "none",
            boxShadow: "none",
            fontSize: "10px",
          }}
          className="text-dark"
        >
          Show
        </DropdownToggle>
        <DropdownMenu
          onClick={(e) => {
            e.stopPropagation();
            quoteStatusHandler(e.target.value, subQuote, index);
          }}
        >
          {possibleTransitionsLoader && (
            <DropdownItem value="Loading" className="text-dark">
              <Spinner height={40} width={40} />
            </DropdownItem>
          )}
          {possibleTransitions?.transitions?.length > 0 &&
            possibleTransitions.transitions.map((transition, index) => (
              <DropdownItem
                value={transition}
                key={index}
                className="text-dark"
              >
                {transition.replaceAll("-", " ")}
              </DropdownItem>
            ))}

          {possibleTransitionsError && (
            <DropdownItem disabled value="error" className="text-dark">
              No Transition Available
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const getpossibleTransitions = (id) => {
    dispatch(
      quoteAcionsCreator.getpossibleTransitions({ type: "VENDOR", id: id })
    );
  };

  const getItemsDetail = (subQuoteData) => {
    let selectedSubQuote = _.cloneDeep(
      _.find(subQuotes, { id: subQuoteData["#"] })
    );

    let data = dataService.parseSubQuoteItems(selectedSubQuote);

    return (
      <SubDataTable
        isEditProduct={true}
        setFields={quotePriceHandler}
        // isActions={true}
        data={data}
        subQuoteId={selectedSubQuote["id"]}
        isProductDetail={true}
        clicked={handleProductModalOpening}
        onDeleteProduct={handleDeleteProduct}
        isEditActionHidden={false}
        deleteAble={true}
      />
    );
  };

  const handleProductModalOpening = (product, subQuoteId) => {
    let subQuote = _.cloneDeep(_.find(subQuotes, { id: subQuoteId }));

    let previewedItem = _.find(subQuote.quote_items, { id: product["#"] });
    let result = dataService.parseQuoteModalData(previewedItem);

    setSelectedProduct(result);
  };

  // const handleDeleteProduct = (product, subQuoteId) => {
  //   let subQuote = _.cloneDeep(_.find(subQuotes, { id: subQuoteId }));
  //   let previewedItem = _.find(subQuote.quote_items, { id: product["#"] });

  //   dispatch(
  //     quoteAcionsCreator.updateQuote({
  //       quote_id: params.id,
  //       remove_products: [previewedItem.product.id],
  //       add_product: [],
  //     })
  //   );
  // };

  // useEffect(() => {
  //   if (quoteUpdateResponse) {
  //     toast.success(`Successfully updated quote ${QuoteId}`);
  //   }
  // }, [quoteUpdateResponse]);

  const handleDeleteProduct = (quoteItemId) => {
    if (quoteItemId) {
      dispatch(
        quoteAcionsCreator.updateQuote({
          quote_id: params.id,
          remove_products: [quoteItemId],
          add_product: [],
        })
      );

      setTimeout(() => {
        history.push(routesPath.quotes);
      }, 2000);
    }
  };

  const getTableDataSource = () => {
    const quotes = [];
    quote?.subquotes.map((subQuote, index) => {
      subQuote?.quote_items.map((item, index) => {
        quotes.push({
          item: (
            <img
              src={item.product.data.images[0].image.replace(
                "image/upload/",
                ""
              )}
              style={{
                height: "70px",
                width: "60px",
              }}
            />
          ),
          name: `${item.product.data.name}`,
          sku: `${item.product.data.sku}`,
          vendor: `${item.product.data.vendor.business_name}`,
          price: ` $${decryptedPrice(item.price) * parseInt(item.quantity)}`,
          quantity: `${item.quantity}`,
          delete: (
            <div onClick={() => handleDeleteProduct(item?.product?.data?.id)}>
              <DeleteOutline />
            </div>
          ),
        });
      });
    });
    return quotes;
  };

  return (
    <>
      <div style={{ background: "white" }}>
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Edit Quote</h1>
          </div>
        </div>

        {Boolean(selectedProduct) && (
          <ProductDetailModal
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            // productEditHandler={productEditHandler}
            // deleteProductHandler={deleteProductHandler}
          />
        )}
        <div
          style={{ background: "white", paddingBottom: 30 }}
          className="dashboard-content-wrap"
        >
          <div
          //   className="main-content-inner"
          >
            <div>
              <Divider
                className="px-2 justify-content-center"
                style={{
                  fontWeight: "bold",
                  fontSize: 36,
                  //   paddingTop: 48,
                  //   background: "black",
                }}
              >
                Edit Quote
              </Divider>

              <div className="row mx-auto">
                <div className="col-12 col-lg-6">
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Quote Id:&nbsp;
                    <span
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <b>{getQuoteByIdResponse?.id}</b>
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Name:&nbsp;
                    <span
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {getUserByIdResponse?.username}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Email:&nbsp;
                    <span
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {getUserByIdResponse?.email}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Status:&nbsp;
                    <span
                      className="badge bg-success"
                      style={{ fontSize: "12px" }}
                    >
                      {getQuoteByIdResponse?.status}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Notes:&nbsp;
                    <span
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {getQuoteByIdResponse?.notes || "-"}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-6 text-end pt-3 pt-lg-0">
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Created at:&nbsp;
                    <span
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {new Date(getQuoteByIdResponse?.created).toUTCString()}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Expires at:&nbsp;
                    <span
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {new Date(getQuoteByIdResponse?.expires_at).toUTCString()}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      paddingBottom: "4px",
                      color: "#4f4e4e",
                    }}
                  >
                    Down payment:&nbsp;
                    <span
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {getQuoteByIdResponse?.down_payment}%
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <Row>
              <Col className="ml-auto" md="12">
                <DataTable
                  data={parsedSubQuotes}
                  loading={loading}
                  clicked={getQuoteDetail}
                  isActions={false}
                  isEditProduct={false}
                  isProductDetail={false}
                  getDetailPanel={getItemsDetail}
                  isDetailPanel={true}
                  tableType={"subQuotes"}

                  // quoteStatusHandler={quoteStatusHandler}
                  // statusError={statusError}
                />
              </Col>
            </Row> */}

            <div style={{ paddingTop: 40 }}>
              <Table
                dataSource={getTableDataSource()}
                style={{
                  width: "100%",
                  maxHeight: "600px",
                  overflow: "scroll",
                }}
                pagination={false}
              >
                <div style={{ paddingLeft: 100 }}>
                  <Table.Column title="ITEM" dataIndex="item" />
                  <Table.Column title="NAME" dataIndex="name" />
                  <Table.Column title="SKU" dataIndex="sku" />
                  <Table.Column title="VENDOR" dataIndex="vendor" />
                  <Table.Column title="QUANTITY" dataIndex="quantity" />
                  <Table.Column title="PRICE" dataIndex="price" />
                  <Table.Column title="DELETE" dataIndex="delete" />
                </div>
              </Table>
            </div>

            {localStorage.getItem("userEmail") && openChat && currentChannel && (
              <InitializePubnub>
                <PubnubChat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  currentChannel={currentChannel}
                />
              </InitializePubnub>
            )}
          </div>
        </div>
        {/* <Divider
          className="px-2 justify-content-center"
          style={{
            fontWeight: "bold",
            fontSize: 36,
          }}
        >
          Update Quote
        </Divider> */}
        <div className="main-content-inner">
          <div className="add-product-form-container-parent">
            <EditQuoteForm
              yupSchema={editQuotesValidationSchema}
              getQuoteByIdResponse={getQuoteByIdResponse}
              // parsedProducts={parsedProducts}
              // user={user}
            />
          </div>
        </div>
      </div>
    </>
  );
};
