import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Badge,
} from "reactstrap";
import { ConfirmationDailoge } from "../../components/confirmationDailoge/confirmationDailoge";
import { DataTable } from "../../components/ui/tables/dataTable";
import _ from "lodash";
import { PubnubChat } from "../../components/chat/chat";
import * as quoteAcionsCreator from "../../redux/actionsCreator/quotesActionsCreator";
import { useHistory } from "react-router";
import InitializePubnub from "../../components/initializePubnub/initializePubnub";
import { toast } from "react-toastify";
import dataService from "../../services/dataService";
import { Spinner } from "../../components/ui/loader";
import { routesPath } from "../../constants/routesPath";
import { ProductDetailModal } from "../../components/productDetailModal/productDetailModal";
import quoteStatus from "../../constants/quoteStatus";
import * as quotesActionsCreator from "../../redux/actionsCreator/quotesActionsCreator";

export const QuotesContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let quotes = useSelector((state) => state.quotesReducer.quotes);
  const loading = useSelector((state) => state.quotesReducer.loading);
  const deleteQuoteResponse = useSelector(
    (state) => state.quotesReducer.deleteQuoteResponse
  );
  const deleteQuoteError = useSelector(
    (state) => state.quotesReducer.deleteQuoteError
  );
  const models = useSelector((state) => state.productsReducer.searchProduct);
  const [currentChannel, setCurrentChannel] = useState(``);
  const [openChat, setOpenChat] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [openConfirmationDailoge, setOpenConfirmationDailoge] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [parsedQuotes, setParsedQuotes] = useState([]);
  const [quoteItemDownPayment, setQuoteItemDownPayment] = useState([]);
  const [parsedProducts, setParsedProducts] = useState([]);

  const possibleTransitionsLoader = useSelector(
    (state) => state.quotesReducer.possibleTransitionsLoader
  );
  const possibleTransitions = useSelector(
    (state) => state.quotesReducer.possibleTransitions
  );
  const possibleTransitionsError = useSelector(
    (state) => state.quotesReducer.possibleTransitionsError
  );
  const patchDownPaymentResponse = useSelector(
    (state) => state.quotesReducer.patchDownPaymentResponse
  );
  const patchDownPaymentError = useSelector(
    (state) => state.quotesReducer.patchDownPaymentError
  );
  const patchDownPaymentLoading = useSelector(
    (state) => state.quotesReducer.patchDownPaymentLoading
  );
  const deleteProductHandler = (e, product) => {
    setOpenConfirmationDailoge(product);
  };

  useEffect(() => {
    if (models) {
      let result = dataService.parseProductsTable(models);
      setParsedProducts(result);
    }
  }, [models]);

  useEffect(() => {
    dispatch(quoteAcionsCreator.getAllQuotes());

    return () => {
      dispatch(quoteAcionsCreator.clearTransitions());
    };
  }, [deleteQuoteResponse]);

  useEffect(() => {
    if (quotes) {
      let result = dataService.parseQuotesTable(
        quotes,
        getTransitionsDropdown,
        getQuoteItems,
        getChat,
        getStatus
      );

      setParsedQuotes(result);
    }
  }, [
    quotes,
    possibleTransitions,
    quoteItemDownPayment,
    possibleTransitionsLoader,
  ]);

  useEffect(() => {
    if (possibleTransitions) {
      if (possibleTransitions?.status) {
        toast.success(possibleTransitions.status.replaceAll("_", " "));
      } else if (
        !possibleTransitions?.transitions?.length &&
        !possibleTransitions?.status
      ) {
        toast.error("No Available Transitions!");
      }
    }
  }, [possibleTransitions]);

  useEffect(() => {
    patchDownPaymentResponse && toast.success("Down payment set");
  }, [patchDownPaymentResponse]);

  useEffect(() => {
    patchDownPaymentError && toast.error("Failed to set down payment");
  }, [patchDownPaymentError]);

  useEffect(() => {
    return () => {
      dispatch(quoteAcionsCreator.clearDownPayment());
      dispatch(quoteAcionsCreator.clearTransitions());
    };
  }, []);

  useEffect(() => {
    if (confirmDelete) {
      let product = confirmDelete;
      const id = product["#"];
      dispatch(quoteAcionsCreator.deleteQuote({ id: id }));
      toast.success(`Successfully deleted Quote ${id}`);
    }
  }, [confirmDelete]);

  useEffect(() => {
    if (deleteQuoteError) {
      toast.error("Failed to delete Quote");
    }
  }, [deleteQuoteError]);

  const quoteStatusHandler = (transition, quote, quoteIndex) => {
    if (quote.down_payment <= 0) {
      toast.error("Please set the down payment first!");
    } else {
      let errorQuotes = [];
      quotes.map((quote, index) => {
        quote.subquotes.map((quote, index) =>
          quote.quote_items.map((item) => {
            if (item.price <= 0 && index === quoteIndex) {
              errorQuotes.push(index);
            }
          })
        );
      });

      if (!errorQuotes.includes(quoteIndex)) {
        const subQuote = {
          quote: quote.quote,
          vendor: quote.vendor,
          subQuoteId: quote.id,
          transition: transition,
          type: "VENDOR",
          productType: "quotes",
        };
        dispatch(quoteAcionsCreator.postpossibleTransitions(subQuote));
      }
    }
  };

  const getQuoteItems = (quote) => {
    return quote.subquotes.map((subQuotes) => {
      return subQuotes.quote_items.map((quoteItem, index) => {
        return (
          <Badge color="badge-dot bg-dark m-1 text-light" key={index}>
            {quoteItem.product.data.name}
          </Badge>
        );
      });
    });
  };

  const getStatus = (quote) => {
    return (
      <Badge className="badge-dot mr-4 bg-dark">
        <span className="status">{quoteStatus[quote.status].label}</span>
      </Badge>
    );
  };

  const getChat = (quote) => {
    return (
      <div>
        <div
          className="quotes-action"
          onClick={(e) => {
            e.stopPropagation();
            handleChat(quote.id);
          }}
        >
          <button>Chat</button>
        </div>
      </div>
    );
  };

  const quoteDownPaymentHandler = (quote) => {
    dispatch(
      quoteAcionsCreator.patchDownPaymentForQuote({
        id: quote["#"],
        down_payment: quote.DOWN_PAYMENT,
        quotes: quotes,
      })
    );
  };

  const getTransitionsDropdown = (quote, index) => {
    return (
      <UncontrolledDropdown
        onClick={(e) => {
          e.stopPropagation();
          getpossibleTransitions(quote.id);
        }}
      >
        <DropdownToggle
          caret
          style={{
            background: "transparent",
            border: "none",
            boxShadow: "none",
            fontSize: "10px",
          }}
          className="text-dark"
        >
          Show
        </DropdownToggle>

        <DropdownMenu
          onClick={(e) => {
            e.stopPropagation();
            quoteStatusHandler(e.target.value, quote, index);
          }}
        >
          {possibleTransitionsLoader && (
            <DropdownItem value="Loading" className="text-dark">
              <Spinner height={40} width={40} />
            </DropdownItem>
          )}
          {possibleTransitions?.transitions?.length > 0 &&
            possibleTransitions.transitions.map((transition, index) => (
              <DropdownItem
                value={transition}
                key={index}
                className="text-dark"
              >
                {transition.replaceAll("-", " ")}
              </DropdownItem>
            ))}

          {possibleTransitionsError && (
            <DropdownItem disabled value="error" className="text-dark">
              No Transition Available
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const handleChat = (id) => {
    setCurrentChannel(`Quote-${id}`);
    setOpenChat(true);
  };

  const getpossibleTransitions = (id) => {
    dispatch(
      quoteAcionsCreator.getpossibleTransitions({ type: "USER", id: id })
    );
  };

  const navigateToSubQuoes = (quote) => {
    dispatch(quoteAcionsCreator.clearTransitions());
    history.push(routesPath.adminSubQuotes(quote["#"]));
  };

  const naviagteToAddNewQuote = () => {
    history.push(routesPath.addQuotes);
  };

  const navigateToEditQuoes = (quote) => {
    history.push(routesPath?.adminEditQuote(quote["#"]));
  };

  return (
    <>
      <div>
        <ConfirmationDailoge
          secondaryText={`Are you sure, you want to delete Quote #${openConfirmationDailoge["#"]}`}
          open={openConfirmationDailoge}
          setOpen={setOpenConfirmationDailoge}
          setConfirm={setConfirmDelete}
        />
        {Boolean(selectedProduct) && (
          <ProductDetailModal
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            deleteProductHandler={deleteProductHandler}
          />
        )}
        <div className="dashboard-head-wrap">
          <div className="dashboard-head-title">
            <h1>Quotes</h1>
          </div>
        </div>
        <div className="dashboard-content-wrap">
          <div className="main-content-inner">
            <Row>
              <Col className="ml-auto" md="12">
                <DataTable
                  data={parsedQuotes}
                  loading={loading}
                  clicked={navigateToSubQuoes}
                  // clicked={navigateToEditQuoes}
                  setFields={quoteDownPaymentHandler}
                  toolbarAction={naviagteToAddNewQuote}
                  editQuote={navigateToEditQuoes}
                  deleteProduct={deleteProductHandler}
                  isEditProduct={true}
                  isProductDetail={true}
                  name="Add Quote"
                  editQuotes={navigateToEditQuoes}
                  // navigateToEditQuoes={navigateToEditQuoes}
                />
              </Col>
            </Row>
            {localStorage.getItem("userEmail") && openChat && currentChannel && (
              <InitializePubnub>
                <PubnubChat
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  currentChannel={currentChannel}
                  isActions={false}
                />
              </InitializePubnub>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
