import React from "react";
import Loader from "react-loader-spinner";

export const Spinner = (props) => (
  <Loader
    type="TailSpin"
    color="green"
    height={props.height}
    width={props.width}
    className="text-center mt-5 d-inline px-2"
  />
);
