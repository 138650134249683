import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import SelectInput from "./SelectInput";
import ImageUploader from "./ImageUploader";
import TextArea from "./textArea";
import TextInput from "./TextInput";
import { FileUploader } from "./fileUploader";
import dataService from "../../services/dataService";
import { Spinner } from "../ui/loader";
import { CheckBox } from "./CheckBox";
import storageService from "../../services/storageService";
import { useRouteMatch } from "react-router-dom";

export const AddProductForm = (props) => {
  const {
    parsedApplications,
    parsedEquipment,
    parsedModels,
    parsedsubCategory,
    data,
    yupSchema,
    productType,
    submitForm,
    loading,
  } = props;

  const { params } = useRouteMatch();

  const [initialValues] = useState(() => {
    return dataService.parseFormDataForProductEdit(data);
  });

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: "all",
    defaultValues: initialValues,
  });

  const onSubmit = (values) => {
    submitForm(values);
  };

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="add-product-form-container"
        >
          <TextInput name="name" label="Name" type="text" required={true} />
          <TextInput name="sku" label="Sku" type="text" required={true} />
          <TextInput
            name="quantity"
            label="Quantity"
            type="number"
            required={true}
            min={1}
          />
          <TextInput
            name="height"
            label="Height"
            type="number"
            required={true}
            placeHolder={"Height in inches"}
            min={1}
          />
          <TextInput
            name="width"
            label="Width"
            type="number"
            required={true}
            placeHolder={"Width in inches"}
            min={1}
          />
          <TextInput
            name="length"
            label="Length"
            type="number"
            required={true}
            placeHolder={"Length in inches"}
            min={1}
          />
          <TextInput
            name="weight"
            label="Weight"
            type="number"
            min={1}
            required={true}
            placeHolder={"Weight in pounds"}
          />
          {parsedApplications && (
            <SelectInput
              name="application"
              label="Applications"
              options={parsedApplications}
              mode="multiple"
              required={true}
            />
          )}
          {parsedsubCategory && productType === "COMPONENT" && (
            <SelectInput
              name="component_subcategory"
              label="Component sub category"
              options={parsedsubCategory}
              mode="single"
              required={true}
            />
          )}
          {parsedEquipment && productType === "MODEL" && (
            <SelectInput
              name="equipment"
              label="Equipment"
              options={parsedEquipment}
              mode="single"
              required={true}
            />
          )}
          {parsedModels && productType === "COMPONENT" && (
            <SelectInput
              name="model"
              label="Model"
              options={parsedModels}
              mode="multiple"
              required={true}
            />
          )}
          <TextArea name="description" label="Description" type="text" />
          {storageService.getTenantId() && (
            <CheckBox
              name="tenant_status"
              label="Tenant Product"
              disabled={Boolean(params?.id)}
            />
          )}
          <ImageUploader name="images" />
          <FileUploader name="cad" labelText={"CAD"} />
          <FileUploader name="fbx" labelText={"FBX"} />
          <div className="add-form-button m-auto mb-3 pt-4">
            <div className="cart-btn">
              <div className="links-btn">
                <div className="add-to-cart">
                  <button
                    disabled={loading}
                    type="submit"
                    onClick={form.handleSubmit(onSubmit)}
                  >
                    {loading && <Spinner height={20} width={20} />}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
